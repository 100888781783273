import { Logger } from "aws-amplify";
import moment from "moment";
import { SET_VACANCY_BY_ID, ADD_VACANCY, EDIT_VACANCY, FETCH_VACANCIES, FETCH_VACANCIES_CANDIDATES, FIND_VACANCIES_STATUSES, SET_CANDIDATE_DETAILS, UPDATE_CANDIDATE_INFO, ADD_SEARCH, FETCH_SEARCHES, REMOVE_SEARCH, EDIT_SEARCH, FETCH_SEARCH_CANDIDATES, FETCH_SEARCH_SHORTLIST, UPDATE_SEARCH_SHORTLIST, FILTER_SEARCH_CANDIDATES, FETCH_BOT_TEMPLATE, ADD_TEMPLATE, EDIT_TEMPLATE, REMOVE_TEMPLATE, UPDATE_VIEWED_CANDIDATES, FETCH_HIDDEN_TALENT, REMOVE_VACANCY, UPDATE_SNACKBAR, FETCH_VACANCIES_CANDIDATES_MONSTER, REMOVE_VACANCIES_CANDIDATES, GET_VIEW_PREFS} from "../actions/types";
import _ from "lodash";
const logger = new Logger("PORTAL DETAILS.REDUCER", "DEBUG");

//for search score stacked progress bars
const occColour = "#ff6505"
const skillColours =["#53D28E","#ffc107","#537dd2"]

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_VACANCIES: {
      const returnProps = {
        ...state,
        vacancies: action.data
          .map(vacancy => ({
            ...vacancy,
            salary_min: vacancy.hourly_salary_min || vacancy.annual_salary_min,
            salary_max: vacancy.hourly_salary_max || vacancy.annual_salary_max,
            salary_config: vacancy.hasOwnProperty("hourly_salary_min")
              ? "hourly"
              : "annually",
            status: vacancy.status || "open"
          }))
          .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case ADD_VACANCY: {
      const returnProps = {
        ...state,
        vacancies: state.vacancies.concat(action.data)
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REMOVE_VACANCY: {
      const returnProps = {
        ...state,
        vacancies: state.vacancies.filter((item) => item.sort !== action.data.sort)
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    

    case EDIT_VACANCY: {
      const { sort: vacancy_id } = action.data;

      const returnProps = {
        ...state,
        vacancies: state.vacancies.map(vacancy => {
          if (vacancy.sort === vacancy_id) {
            return action.data;
          }
          return vacancy;
        })
      };

      return returnProps;
    }

    case FIND_VACANCIES_STATUSES: {
      let candidatesStatuses = action.shortList ? action.shortList.map(user => {
        return {
          name: user.firstName + " " + user.lastName,
          initals: user.initals.replace(" ", ""),
          userID: user.searchCandidate ? user.sort : user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.telephone,
          invited: user.invited || false,
          hired: user.hired || false,
          removed: user.removed || false,
          favourited: user.favourited || false,
          applied: user.applied || false,
          isUnlocked: user.isUnlocked || false,
          data: user.data || false,
          script_status: user.script_status || false,
          script_results: user.script_results || false,
          contactedAt: user.contactedAt || false,
        }
      }) : [];
      let candidatesToRemove = action.removedUsers ? action.removedUsers : []

      if (candidatesToRemove && candidatesToRemove.length > 0){
        candidatesStatuses = candidatesStatuses.filter(user => !candidatesToRemove.includes(user.userID))
      }

      return {
        ...state,
        candidatesStatus: candidatesStatuses,
        globalUnlocked: action.globalUnlocked ? action.globalUnlocked : [] //reset it every time it runs to catch updates
      }
    }
    case FETCH_VACANCIES_CANDIDATES: {
      if(!action.data){
        return {
          ...state,
          vacancyCandidates: [],
          allOccs: [],
          allSkills: []
        }
      }
      let organisation = action.organisation;
      let newVacancyCandidates = action.data;
      let candidatesToRemove = action.removedUsers ? action.removedUsers : []
      let jobID = action.jobID
      let newVacancies = state.vacancies;
      let candidatesStatus = state.candidatesStatus;
      let current_vac = newVacancies.find(item => jobID === item.job_id || jobID === item.sort)
      let allOccs = []
      let allSkills = []
      
      if (newVacancyCandidates){
        newVacancyCandidates = newVacancyCandidates.map(user => {
          try{
            let lastAccessedMoment = undefined
            let lastAccessedDays = "Unknown"
            if(user.free_resume){
              // console.log("is free")
              const salInt = 0
              if (user.identity && user.identity.resumeModifiedDate){
                lastAccessedMoment = moment(user.identity.resumeModifiedDate);
                lastAccessedDays = Math.round((moment().startOf('day') - lastAccessedMoment) / 86400000);
              }
              let occName = "No occupations listed"
              if(user.relevance && user.relevance.experience && user.relevance.experience.title && (user.relevance.experience.title.matched || user.relevance.experience.title.name)){
                occName = user.relevance.experience.title.matched ? user.relevance.experience.title.matched : user.relevance.experience.title.name
              }
              allOccs.push("Free Candidate")
              const getPhonenumber = () => {
                let numObj
                if (user.phoneNumbers){
                  numObj = user.phoneNumbers.find((el) => el.priority === "Primary")
                  if (!numObj){
                    numObj =  user.phoneNumbers.find((el) => el.priority === "Secondary")
                  }
                }
                return numObj && numObj.phoneNumberValue ? numObj.phoneNumberValue : "No phone number provided"
              }
              const skillsMatched = user.relevance.skills ? user.relevance.skills.map((skill) => skill.name) : [];
              allSkills.push(...skillsMatched)
              let titleName = toTitleCase(user.identity.name);
              let splitName = titleName.split(" ", 2);          
              return {
                userID: user.identity.id,
                free_resume: true,
                sort: user.identity.md5EmailAddress, 
                name: titleName,
                email: user.identity.emailAddress,
                phoneNumber: getPhonenumber(),
                firstName: splitName[0],
                lastName: splitName[1],
                score: 0,
                location: user.location.city,
                range: user.location.distance,
                distance: user.location.distance,
                salary: "Unknown",
                salaryAsInt: isNaN(salInt) ? 0 : salInt,
                lastAccessed: lastAccessedMoment ? lastAccessedMoment.format('DD-MM-YYYY'): "Unknown", //Is better?
                lastAccessedDays: lastAccessedDays,
                qualification: user.degree ? user.degree : "No qualification mentioned", //TODO
                qualificationLevel: 1, //TODO mapping
                rightToWork: user.location.workAuthorizations && user.location.workAuthorizations.find((item) => ["UK", "United Kingdom", "uk"].includes(item.country) && item.authorization) ? "Yes" : "Unknown",
                willRelocate: user.location.willRelocate == null ? "Unknown" : user.location.willRelocate ? "Yes" : "No",
                yearsOfExperience: user.yearsOfExperience ? user.yearsOfExperience : "Unknown",
                occupationMatched: occName,
                skillsMatched: skillsMatched,
                relevanceObject: user.relevance,
                locationObject: user.location,
                additionalReqMatch: false,
                requiredSkillMatch : false,
                rawUser: user,
                viewed: false,
                viewedCount: 0,
                unlockedCount: 0,
                chartList: [],
                queryKey: "Free Candidate",
                travel_distance_flag: "Unknown",
                contract: "Unknown",
                status: "Unknown",
                jobTypes: [],
                skills: 0, 
                styles: 0,
                interests: 0,
                lookingForWork: "Looking for work",
                isUnlocked: true,
                talentType: "Community CV's"
              }
            }
            if (user.lastAccessedAt){
              lastAccessedMoment = moment(user.lastAccessedAt);
              lastAccessedDays = Math.round((moment().startOf('day') - lastAccessedMoment) / 86400000);
            }
            let salInt = parseInt(user.latestAssessmentResult.interests.salary[0].substring(1).split(",").join(""))
            if (user.latestAssessmentResult.interests.salary[0].includes("hour")){
              salInt = salInt * 2080
            }
            let missing_skills = []
            missing_skills = current_vac.skills_required.filter(x => !user.score_bp_skills_matched.includes(x.toLowerCase()));
            let status = "Unknown"
            if (user.candidate_status_history && user.candidate_status_history.length > 0){
              const setStatus = user.candidate_status_history[user.candidate_status_history.length - 1].status
              if (setStatus !== "Not entered"){
                status = setStatus
              }
            }
            let talentType = "Skillsminer Profiles"
            if(user.internalMobilityProvider){
              talentType = "Employee Profiles"
            }
            else if(user.provider === organisation){
              talentType = "Community Profiles"
            }

            let occupationList = []
            user.desiredOccupations.forEach((value, index) => {
              if(value){
                occupationList.push(user.desiredOccupations[index])
              }
            })
            if(user.occupations && user.occupations.length > 0){
              occupationList.push(...user.occupations)
            }
            allOccs.push(...occupationList)
            allSkills.push(...user.user_all_skills)
            return {
              name: user.fullName,
              skills: user.score_bp,
              styles: user.score_styles,
              interests: user.score_interest,
              location: user.latestAssessmentResult.interests.locationOriginal[0],
              contract: user.latestAssessmentResult.interests.contract[0],
              jobTypes: user.latestAssessmentResult.interests.jobTypes ? user.latestAssessmentResult.interests.jobTypes : [],
              radius: user.latestAssessmentResult.interests.locationRadius[0],
              salary: user.latestAssessmentResult.interests.salary[0],
              salaryAsInt: isNaN(salInt) ? 0 : salInt,
              occupations: user.desiredOccupations,
              occupationExtended: user.desiredOccupationsExtended[0],
              previousOccupations: user.occupations,
              previousOccupationsExtended: user.occupationsExtended,
              desiredExp: user.desiredExp,
              qualifications: user.qualifications ? user.qualifications.name ? user.qualifications : {"name": user.qualifications, "category":0} : {"name": "No qualifications disclosed", "category":0},
              userID: user.userID,
              email: user.email,
              phoneNumber: user.phoneNumber,
              similar_skills: user.score_bp_skills_matched,
              missing_skills: missing_skills,
              skills_list: user.user_all_skills,
              travel_distance_flag: user.travel_distance_flag ? "Yes" : "No",
              distance: user.distance,
              status: status,
              lookingForWork: user.allow_reverse_mine === undefined ? "Looking for work" : user.allow_reverse_mine ? "Looking for work" : "Not looking for work", 
              rightToWork: user.rightTooWork ? ((user.rightTooWork === "Yes" || user.rightTooWork === "No") ? user.rightTooWork : "Unknown") : "Unknown",
              lastAccessed: lastAccessedMoment ? lastAccessedMoment.format('DD-MM-YYYY'): "Unknown",
              lastAccessedDays: lastAccessedDays,
              organisation: user.provider,
              internalMobilityProvider: user.internalMobilityProvider,
              talentType: talentType,
              occupationList: occupationList
            }
          } catch (e) {
            // console.log(e)
            return false
          }
        }).filter(Boolean);
      }
      else{
        newVacancyCandidates = []
      }
      let shortlistedCans = candidatesStatus.map((item) => item.data).filter(Boolean);
      newVacancyCandidates = _.uniqBy([...shortlistedCans, ...newVacancyCandidates], 'userID');

      if (candidatesToRemove && candidatesToRemove.length > 0){
        newVacancyCandidates = newVacancyCandidates.filter((user) => !candidatesToRemove.includes(user.identity ? user.identity.id : user.userID));
      }

      newVacancies = newVacancies.map(item => {
        if(jobID === item.job_id){
          item.hasRemovedCandidates = candidatesToRemove.length > 0 ? true : false;
        }
        return item
      })

      const returnProps = {
        ...state,
        vacancyCandidates: newVacancyCandidates,
        vacancies: newVacancies,
        allOccs: [...new Set(allOccs)],
        allSkills: [...new Set(allSkills)]
      }
      return returnProps;
    }
    case FETCH_VACANCIES_CANDIDATES_MONSTER:{
      let organisation = action.organisation;
      let newSearchCandidates = state.vacancyCandidates;
      let candidatesToRemove = action.removedUsers ? action.removedUsers : []
      let globalUnlocked = state.globalUnlocked;
      let jobID = action.jobID
      let newVacancies = state.vacancies;
      let current_vac = newVacancies.find(item => jobID === item.job_id)
      let allOccs = state.allOccs ? state.allOccs : []
      let allSkills = state.allSkills ? state.allSkills : []
      
      for (const [key, value] of Object.entries(action.data)) {
        if(key !== "occupations_skills" && key !== "order_dict"){
          newSearchCandidates = newSearchCandidates.concat(value.map(user => {
            const unlockedVersion = globalUnlocked.find((item) => item.sort === user.identity.textResumeID)
            const salInt = 0
            let lastAccessedMoment = undefined
            let lastAccessedDays = "Unknown"
            if (user.identity && user.identity.resumeModifiedDate){
              lastAccessedMoment = moment(user.identity.resumeModifiedDate);
              lastAccessedDays = Math.round((moment().startOf('day') - lastAccessedMoment) / 86400000);
            }
            let occName = "No occupations listed"
            let hasOccMatch = false
            if(user.relevance && user.relevance.experience && user.relevance.experience.title && (user.relevance.experience.title.matched || user.relevance.experience.title.name)){
              if(user.relevance.experience.title.matched){
                occName = user.relevance.experience.title.matched
                hasOccMatch = true
              }
              else{
                occName = user.relevance.experience.title.name
              }
            }
            const getPhonenumber = () => {
              let numObj
              if (user.phoneNumbers){
                numObj = user.phoneNumbers.find((el) => el.priority === "Primary")
                if (!numObj){
                  numObj =  user.phoneNumbers.find((el) => el.priority === "Secondary")
                }
              }
              return numObj && numObj.phoneNumberValue ? numObj.phoneNumberValue : "No phone number provided"
            }
            let titleName = unlockedVersion ? unlockedVersion.data.name: toTitleCase(user.identity.name);
            let splitName = titleName.split(" ", 2);          
            let occScore = Math.round(user.relevance.score * 10) || 0;
            let userSkills = user.relevance.skills ? user.relevance.skills.map((skill) => skill.matched === "" ? false : skill.name).filter(Boolean) : []
            let matchesLen = hasOccMatch ? userSkills.length + 1 : userSkills.length
            let chartList = [hasOccMatch ? {colour: occColour, value: Math.round((user.relevance.score * 10) / matchesLen) || 0, key: occName.charAt(0).toUpperCase() + occName.slice(1)} : false]
            chartList = [...chartList, ...userSkills.map((key, index) => {
              return {colour: skillColours[index], value: Math.round((user.relevance.score * 10) / matchesLen) || 0, key: key}
            })]

            const calculatedScore = _.sum(chartList.map((item) => item.value)) || 0
            let queryKey = key;
            if(user.free_resume){
              queryKey = "Free Candidate" 
            }
            else if(key === "skills_based_search"){
              if(occName === "No occupations listed"){
                queryKey = "Skills Candidate" 
              }
              else{
                queryKey = occName
              }
            }
            else if(key === "monster_local_candidates"){
              queryKey = "Local Candidiate"
            }
            
            const skillsMatched = user.relevance.skills ? user.relevance.skills.map((skill) => skill.name) : [];
            allOccs.push(queryKey)
            allSkills.push(...skillsMatched)
            return {
              userID: user.identity.textResumeID, 
              name: titleName,
              email: unlockedVersion ? unlockedVersion.email : user.identity.emailAddress,
              phoneNumber: unlockedVersion ? unlockedVersion.telephone : getPhonenumber(),
              firstName: splitName[0],
              lastName: splitName[1],
              score: calculatedScore,
              location: user.location.city,
              range: user.location.distance,
              distance: user.location.distance,
              salary: "Unknown",
              salaryAsInt: isNaN(salInt) ? 0 : salInt,
              lastAccessed: lastAccessedMoment ? lastAccessedMoment.format('DD-MM-YYYY'): "Unknown", //Is better?
              lastAccessedDays: lastAccessedDays,
              qualification: user.degree ? user.degree : "No qualification mentioned", //TODO
              qualificationLevel: 1, //TODO mapping
              rightToWork: user.location.workAuthorizations && user.location.workAuthorizations.find((item) => ["UK", "United Kingdom", "uk"].includes(item.country) && item.authorization) ? "Yes" : "Unknown",
              willRelocate: user.location.willRelocate == null ? "Unknown" : user.location.willRelocate ? "Yes" : "No",
              yearsOfExperience: user.yearsOfExperience ? user.yearsOfExperience : "Unknown",
              occupationMatched: occName,
              skillsMatched: skillsMatched,
              relevanceObject: user.relevance,
              locationObject: user.location,
              additionalReqMatch: false,
              requiredSkillMatch : false,
              rawUser: user,
              viewed: false,
              viewedCount: 0,
              unlockedCount: 0,
              chartList: [],
              queryKey: queryKey,
              travel_distance_flag: "Unknown",
              contract: "Unknown",
              status: "Unknown",
              jobTypes: [],
              skills: occScore, 
              styles: 0,
              interests: 0,
              lookingForWork: "Looking for work",
              free_resume: user.free_resume ? true : false,
              isUnlocked: user.free_resume || unlockedVersion ? true : false,
              talentType: user.free_resume ? "Community CV's" : "Premium External Profiles",
              chartList: chartList,
            }
          }))
        }
      }
      newVacancies = newVacancies.map(item => {
        if(jobID === item.job_id){
          item.hasRemovedCandidates = candidatesToRemove.length > 0 ? true : false;
        }
        return item
      })
    
      newSearchCandidates = _.uniqBy(newSearchCandidates, 'userID');

      const returnProps = {
        ...state,
        vacancyCandidates: newSearchCandidates,
        vacancies: newVacancies,
        allOccs: [...new Set(allOccs)],
        allSkills: [...new Set(allSkills)]
      }
      return returnProps;
    }
    case REMOVE_VACANCIES_CANDIDATES:{
      let updatedList = []
      if (action.isPotential){
        updatedList = state.candidatesStatus
      }
      else{
        updatedList = state.vacancyCandidates
      }

      updatedList = updatedList.filter((item) => !action.candidatesToRemove.includes(item.userID));

      let returnProps = {}
      if (action.isPotential){
        returnProps = {
          ...state,
          candidatesStatus: updatedList}
      }
      else{
        returnProps = {
          ...state,
          vacancyCandidates: updatedList}
      }
      return returnProps;
    }
    case SET_CANDIDATE_DETAILS: {
      const isPotential = action.isPotential
      const selectedCandidate = action.data;
      const selectedVacancy = action.selectedVacancy;
      let globalUnlocked = state.globalUnlocked;

      let updatedList = []
      if (isPotential){
        updatedList = state.candidatesStatus
      }
      else{
        updatedList = state.vacancyCandidates
      }

      updatedList = updatedList.map(user => {
        if (user.userID === selectedCandidate.userID){
          let userStatus = state.candidatesStatus.find(element => element.userID === user.userID) || {}
          if(selectedCandidate.free_resume){
            return {
              ...user,
              ...selectedCandidate,
              invited: userStatus.invited || false,
              hired: userStatus.hired || false,
              removed: userStatus.removed || false,
              favourited: userStatus.favourited || false,
              isUnlocked: true,
              script_status: user.script_status || false,
              script_results: user.script_results || false,
              contactedAt: user.contactedAt || false,
            }
          }
          if(selectedCandidate.talentType === "Premium External Profiles"){
              let userStatus = state.candidatesStatus.find(element => element.userID === user.userID) || {}
              const unlockedVersion = globalUnlocked.find((item) => item.sort === user.userID)
              let cand = {
                ...user,
                ...userStatus.data,
                invited: userStatus.invited || false,
                hired: userStatus.hired || false,
                removed: userStatus.removed || false,
                favourited: userStatus.favourited || false,
                isUnlocked: userStatus.isUnlocked || false,
                script_status: userStatus.script_status || false,
                script_results: userStatus.script_results || false,
                contactedAt:  userStatus.contactedAt || false
              }
              if(unlockedVersion){
                cand = {
                  ...cand,
                  name: unlockedVersion.data.name,
                  email: unlockedVersion.email,
                  phoneNumber: unlockedVersion.telephone,
                  isUnlocked: true
                }
              }
              return cand
            // }
          }
          let initials = selectedCandidate.firstName.charAt(0).toUpperCase() + selectedCandidate.lastName.charAt(0).toUpperCase()
          let candidateInterestsData=[]
          let sum = 0;
          
          Object.keys(selectedCandidate.latestAssessmentResult.interests.interestsOriginal).forEach(function(key) {
              sum += selectedCandidate.latestAssessmentResult.interests.interestsOriginal[key];
          });
          
          Object.keys(selectedCandidate.latestAssessmentResult.interests.interestsOriginal).forEach(function(key) {
            let upperType = key.charAt(0).toUpperCase() + key.slice(1);
            candidateInterestsData.push({
              id: upperType,
              label: upperType,
              value: parseFloat(((selectedCandidate.latestAssessmentResult.interests.interestsOriginal[key] / sum) * 100).toFixed(2))
            })
          });
          let similar_skills = []
          let missing_skills = []
          let other_skills = []
          selectedCandidate.occupationSkills.forEach((occSkill) => {
            occSkill.skills.forEach((individual_skill) => {
              if (selectedVacancy.skills_required.includes(individual_skill.name)){
                similar_skills.push(individual_skill.name)
              }
              else{
                other_skills.push(individual_skill.name)
              }
            })
          })
          similar_skills = [...new Set(similar_skills)]
          missing_skills = selectedVacancy.skills_required.filter(x => !similar_skills.includes(x));
          other_skills = [...new Set(other_skills)]

          let status = "Unknown"
          if(selectedCandidate.candidate_status_history && selectedCandidate.candidate_status_history.length > 0){
            const setStatus = selectedCandidate.candidate_status_history[selectedCandidate.candidate_status_history.length -1].status
            if(setStatus !== "Not entered"){
              status = setStatus
            }
          }
          

          if (isPotential){
            let skills_score = 0
            skills_score = parseInt((similar_skills.length / selectedVacancy.skills_required.length) * 100)
            return {
              ...user,
              initials: initials,
              // name: selectedCandidate.fullName,
              skills: skills_score,
              styles: 0,
              interests: 0,
              location: selectedCandidate.latestAssessmentResult.interests.locationOriginal[0],
              radius: selectedCandidate.latestAssessmentResult.interests.locationRadius[0],
              contract: selectedCandidate.latestAssessmentResult.interests.contract[0],
              salary: selectedCandidate.latestAssessmentResult.interests.salary[0],
              occupations:selectedCandidate.desiredOccupations,
              desiredExp: selectedCandidate.desiredExp,
              previousOccupations :selectedCandidate.occupations,
              occupationSkills : selectedCandidate.occupationSkills,
              qualifications: selectedCandidate.qualifications.name ? selectedCandidate.qualifications : {"name": selectedCandidate.qualifications, "category":0},
              similar_skills: similar_skills,
              missing_skills: missing_skills,
              other_skills: other_skills,
              candidateInterestsData : candidateInterestsData,
              candidateStylesData : selectedCandidate.latestAssessmentResult.results,
              key_worker_name: selectedCandidate.key_worker_name,
              status: status,
              lookingForWork: selectedCandidate.allow_reverse_mine === undefined ? "Looking for work" : selectedCandidate.allow_reverse_mine ? "Looking for work" : "Not looking for work", 
              rightToWork: selectedCandidate.rightTooWork ? ((selectedCandidate.rightTooWork === "Yes" || selectedCandidate.rightTooWork === "No") ? selectedCandidate.rightTooWork : "Unknown") : "Unknown",
              lastAccessed: selectedCandidate.lastAccessedAt ?  moment(selectedCandidate.lastAccessedAt).format("DD/MM/YYYY") : "Unknown",
              organisation: selectedCandidate.provider,
              internalMobilityResponses: user.internalMobilityProvider ? selectedCandidate.internalMobilityResponses : false,        
            }
          }
          else{
            let userStatus = state.candidatesStatus.find(element => element.userID === user.userID) || {}
            return {
              ...user,
              initials: initials,
              location: selectedCandidate.latestAssessmentResult.interests.locationOriginal[0],
              radius: selectedCandidate.latestAssessmentResult.interests.locationRadius[0],
              contract: selectedCandidate.latestAssessmentResult.interests.contract[0],
              salary: selectedCandidate.latestAssessmentResult.interests.salary[0],
              occupations:selectedCandidate.desiredOccupations,
              desiredExp: selectedCandidate.desiredExp,
              previousOccupations :selectedCandidate.occupations,
              occupationSkills : selectedCandidate.occupationSkills,
              qualifications: selectedCandidate.qualifications.name ? selectedCandidate.qualifications : {"name": selectedCandidate.qualifications, "category":0},
              similar_skills: similar_skills,
              missing_skills: missing_skills,
              other_skills: other_skills,
              candidateInterestsData : candidateInterestsData,
              candidateStylesData : selectedCandidate.latestAssessmentResult.results,
              invited: userStatus.invited || false,
              hired: userStatus.hired || false,
              removed: userStatus.removed || false,
              favourited: userStatus.favourited || false,
              script_status: userStatus.script_status || false,
              script_results: userStatus.script_results || false,
              contactedAt:  userStatus.contactedAt || false,
              status: status,
              key_worker_name: selectedCandidate.key_worker_name,
              lookingForWork: selectedCandidate.allow_reverse_mine === undefined ? "Looking for work" : selectedCandidate.allow_reverse_mine ? "Looking for work" : "Not looking for work", 
              rightToWork: selectedCandidate.rightTooWork ? ((selectedCandidate.rightTooWork === "Yes" || selectedCandidate.rightTooWork === "No") ? selectedCandidate.rightTooWork : "Unknown") : "Unknown",
              lastAccessed: selectedCandidate.lastAccessedAt ?  moment(selectedCandidate.lastAccessedAt).format("DD/MM/YYYY") : "Unknown",
              organisation: selectedCandidate.provider,
              internalMobilityResponses: user.internalMobilityProvider ? selectedCandidate.internalMobilityResponses : false,        
            }
          }
        }
        else{
          return {...user}
        }
      })
      let returnProps = {}
      if (isPotential){
        returnProps = {
          ...state,
          candidatesStatus: updatedList}
      }
      else{
        returnProps = {
          ...state,
          vacancyCandidates: updatedList}
      }
      return returnProps;
    }
    case UPDATE_CANDIDATE_INFO: {
      const body = action.data;
      const status = action.status

      let newVacancyCandidates = state.vacancyCandidates
      newVacancyCandidates = newVacancyCandidates.map(user => {
        if (body.userID === user.userId){
          user[status] = body[status]
        }
        return user
      })
      const returnProps = {
        ...state,
        vacancyCandidates: newVacancyCandidates
      }
      return returnProps;
    }
    case SET_VACANCY_BY_ID: {
      if (action.data.pass){
        const returnProps = {
          ...state,
          selectedVacancy: action.data.data,
          vacancies: [action.data.data]
        }
        return returnProps;
      } else {
        return {
          ...state
        }
      }
    }
    case FETCH_SEARCHES: {
      const returnProps = {
        ...state,
        searches: action.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
      };
      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case ADD_SEARCH:{
      let newSearches = (state.searches ? state.searches : [])
      newSearches.unshift(action.data);
      return {
        ...state,
        searches: newSearches
      }
    }
    case REMOVE_SEARCH:{
      let newSearches = state.searches
      newSearches.splice(newSearches.indexOf(action.data), 1)
      return {
        ...state,
        searches: newSearches
      }
    }
    case EDIT_SEARCH:{
      const oldSearch = state.searches.find(search => search.sort === action.data.sort);
      let newSearches = (state.searches ? state.searches : [])
      newSearches.splice(newSearches.indexOf(oldSearch), 1);
      newSearches.unshift(action.data);
      return {
        ...state,
        searches: newSearches
      }
    }
    case FETCH_SEARCH_CANDIDATES:{
      const viewedCandidates = state.viewedCandidates ? state.viewedCandidates : []
      const globalViewedCandidates = state.globalViewedCandidates ? state.globalViewedCandidates : {}
      const globalUnlockedCandidates = state.globalUnlockedCandidates ? state.globalUnlockedCandidates : {}
      let newSearchCandidates = [...(action.searchShortlist ? action.searchShortlist : [])]

      if (action.data.hidden_talent){
        newSearchCandidates = newSearchCandidates.map((cand) => {
          if (action.data.hidden_talent.find((newCands) => cand.sort === newCands.identity.md5EmailAddress)){
            return cand
          }
        }).filter(Boolean)
      }

      let allOccs = []
      let allSkills = []
      let allQuals = []
      let allRadius = [] 
      let newallOccs = []
      let hasExtraRequirementsResults = false
      let hasRequiredSkillsResults = false

      for (const user of newSearchCandidates) {
        allOccs.push(user.occupationMatched)
        allQuals.push(user.qualification)
        if(user.queryKey === "skills_based_search"){
          allSkills = allSkills.concat(user.skillsMatched)
        }
        if(user.queryKey === "hidden_talent"){
          allSkills = allSkills.concat(user.skillsMatched)
          newallOccs.push(user.occupationMatched)
        }
      }
      const sourceRequirements = action.extraRequirements ? action.extraRequirements : []
      const today = moment().startOf('day');      
      if (action.data){
        for (const [key, value] of Object.entries(action.data)) {
          if (key === "order_dict"){
            for (const orderItem of value) {
              const dictValue = Object.values(orderItem)[0]
              if (dictValue instanceof Array){
                newallOccs.push(...dictValue)
              }
              else{
                newallOccs.push(dictValue)
              }
            }
          }
          else if (key !== "occupations_skills") {
            newSearchCandidates = newSearchCandidates.concat(value.map(user => {
              const salInt = 0 // user.desiredSalary ? parseInt(user.desiredSalary.min.substring(1).split(",").join("")) : 0
              let lastAccessedMoment = undefined
              let lastAccessedDays = "Unknown"
              if (user.identity && user.identity.resumeModifiedDate){
                lastAccessedMoment = moment(user.identity.resumeModifiedDate);
                lastAccessedDays = Math.round((today - lastAccessedMoment) / 86400000);
              }

              let isAdditionalMatch = false
              let isRequiredSkillMatch = false

              if (key === "additional_requirements"){
                isAdditionalMatch = true
                hasExtraRequirementsResults = true
              }
              else if(key === "required_skills"){
                isRequiredSkillMatch = true
                hasRequiredSkillsResults = true
              }

              let userSkills = []
              if (isAdditionalMatch || isRequiredSkillMatch){
                userSkills = user.searched_string.split("#").map((value) => {
                  if (value.length > 1){
                    return value
                  }
                }).filter(Boolean)
              }
              else{
                userSkills = user.relevance.skills ? user.relevance.skills.map((skill) => {
                  if (key === "skills_based_search" || key === "hidden_talent" || key === "monster_local_candidates"){
                    allSkills.push(skill.name)
                  }
                  return skill.name
                }) : []
              }              
              
              
              let occName = "No occupations listed"
              if(key !== "skills_based_search" && !isAdditionalMatch  && !isRequiredSkillMatch){
                if (key === "hidden_talent" || key === "monster_local_candidates"){
                  if(user.relevance && user.relevance.experience && user.relevance.experience.title && (user.relevance.experience.title.matched || user.relevance.experience.title.name)){
                    occName = user.relevance.experience.title.matched ? user.relevance.experience.title.matched : user.relevance.experience.title.name
                  }
                  newallOccs.push(occName)
                }
                else{
                  occName = key
                }
                allOccs.push(occName)  
              }

              const qualName = user.degree ? user.degree : "No qualification mentioned"
              allQuals.push(qualName)
              if (Number.isInteger(user.location.distance)){
                allRadius.push(user.location.distance)
              }   

              let chartList = []
              if(key !== "monster_local_candidates"){
                if (!user.relevance.chart_data || isRequiredSkillMatch || isAdditionalMatch ){ 
                  chartList = userSkills.map((key, index) => {
                    return {colour: skillColours[index], value: Math.round((user.relevance.score * 10) / userSkills.length) || 0, key: key}
                  })
                }
                else{
                  chartList = Object.keys(user.relevance.chart_data).filter((key) => key !== "occupation").map((key, index) => {              
                    if (user.relevance.chart_data[key] > 0){
                      return {colour: skillColours[index], value: user.relevance.chart_data[key] * 10 , key: key}
                    } 
                  }).filter(Boolean)
  
                  const occScore = (user.relevance.score * 10) - (chartList.reduce((a, b) => a + b.value, 0))
                  chartList.splice(0, 0, {colour: occColour, value: occScore, key: occName.charAt(0).toUpperCase() + occName.slice(1)});
                }
              }

              const calculatedScore = _.sum(chartList.map((item) => item.value)) || 0

              const viewedCount = globalViewedCandidates[user.identity.md5EmailAddress]
              const unlockedCount = globalUnlockedCandidates[user.identity.md5EmailAddress]

              const getPhonenumber = () => {
                let numObj
                if (user.phoneNumbers){
                  numObj = user.phoneNumbers.find((el) => el.priority === "Primary")
                  if (!numObj){
                    numObj =  user.phoneNumbers.find((el) => el.priority === "Secondary")
                  }
                }
                return numObj && numObj.phoneNumberValue ? numObj.phoneNumberValue : "No phone number provided"
              }
              return {
                sort: user.identity.md5EmailAddress, 
                email: user.free_resume ? user.identity.emailAddress : false,
                phoneNumber: user.free_resume ? getPhonenumber() : false,
                userID: user.identity.id,
                name: user.free_resume ? toTitleCase(user.identity.name) : user.identity.name,
                score: calculatedScore,
                location: user.location.city,
                range: user.location.distance,
                salary: "Salary not mentioned",
                salaryAsInt: isNaN(salInt) ? 0 : salInt,
                lastAccessed: lastAccessedMoment ? lastAccessedMoment.format('DD-MM-YYYY'): "Unknown", //Is better?
                lastAccessedDays: lastAccessedDays,
                qualification: qualName, //TODO
                qualificationLevel: 1, //TODO mapping
                rightToWork: user.location.workAuthorizations && user.location.workAuthorizations.find((item) => ["UK", "United Kingdom", "uk"].includes(item.country) && item.authorization) ? "Yes" : "Unknown",
                willRelocate: user.location.willRelocate == null ? "Unknown" : user.location.willRelocate ? "Yes" : "No",
                yearsOfExperience: user.yearsOfExperience ? user.yearsOfExperience : "Unknown",
                occupationMatched: occName,
                skillsMatched: userSkills,
                relevanceObject: user.relevance,
                locationObject: user.location,
                additionalReqMatch: isAdditionalMatch,
                requiredSkillMatch : isRequiredSkillMatch,
                rawUser: user,
                viewed: viewedCandidates.includes(user.identity.md5EmailAddress),
                viewedCount: viewedCount ? viewedCount : 0,
                unlockedCount: user.free_resume ? 1 : unlockedCount ? unlockedCount : 0,
                chartList: chartList,
                queryKey: key,
                free_resume: user.free_resume ? true : false,
                isUnlocked: user.free_resume ? true : false
              }
            }))
          }
        }
        let sotredSkills = [...new Set(allSkills)].sort((a,b) => a - b)
        if(hasExtraRequirementsResults){
          sotredSkills.unshift("Additional Requirements match")
        }
        if (hasRequiredSkillsResults){
          sotredSkills.unshift("Required Skills match")
        }
        return{
          ...state,
          searchCandidates: _.uniqBy(newSearchCandidates, "sort"),
          searchFilterOptions: {
            allSearchableOccs: [...new Set(newallOccs.filter((value) => allOccs.includes(value)))],
            allSearchableSkills:  sotredSkills,
            allSearchableQuals:  [...new Set(allQuals)].sort((a,b) => a - b),
            allAdditionalReqs: sourceRequirements,
            maxRadius: Math.max(...allRadius)
        }
      }
    }
    else {
      return {
        ...state,
        searchCandidates: false,
        searchFilterOptions:{},
        filteredSearchCandidates: [],
        searchFilter:{}
      }
    }
    }
    case FETCH_SEARCH_SHORTLIST:{
      const newSearchShortlist = action.data.map((cand) => {
        const viewedCount = action.globalViewedCandidates[cand.sort]
        const unlockedCount = action.globalUnlockedCandidates[cand.sort]
        return {
          ...cand,
          viewed: action.viewed.includes(cand.sort),
          viewedCount: viewedCount ? viewedCount : 0,
          unlockedCount: unlockedCount ? unlockedCount : 0,
        }
        }
      )
      
      return{
        ...state,
        searchShortlist: newSearchShortlist,
        viewedCandidates: action.viewed,
        globalViewedCandidates: action.globalViewedCandidates,
        globalUnlockedCandidates: action.globalUnlockedCandidates
      }
    }
    case UPDATE_VIEWED_CANDIDATES:{
      console.log("UPDATE_VIEWED_CANDIDATES")
      const newView = action.newView
      let newSearchCandidates = state.searchCandidates
      let newFilteredSearchCandidates = state.filteredSearchCandidates
      let newGlobalViewedCandidates = state.globalViewedCandidates || {}

      let newSearchShortlist = state.searchShortlist.map((cands) => {
        if (cands.sort === newView){
          cands.viewed = true
        }
        return cands 
      })     

      if (newGlobalViewedCandidates[newView]){
        newGlobalViewedCandidates[newView] += 1
      }
      else{
        newGlobalViewedCandidates[newView] = 1
      }
    
      if (newSearchCandidates && newSearchCandidates.length > 0){
        newSearchCandidates = newSearchCandidates.map((cands) => {
          if (cands.sort === newView){
            cands.viewed = true
            cands.viewedCount = newGlobalViewedCandidates[newView]
          }
          return cands 
        })
      }
      if (newFilteredSearchCandidates && newFilteredSearchCandidates.length > 0){
        newFilteredSearchCandidates = newFilteredSearchCandidates.map((cands) => {
          if (cands.sort === newView){
            cands.viewed = true
            cands.viewedCount = newGlobalViewedCandidates[newView]
          }
          return cands 
        })
      }

      return{
        ...state,
        searchShortlist: newSearchShortlist,
        searchCandidates: newSearchCandidates,
        globalViewedCandidates: newGlobalViewedCandidates,
        filteredSearchCandidates: newFilteredSearchCandidates
      }
    }
    case UPDATE_SEARCH_SHORTLIST:{
      console.log("UPDATE_SEARCH_SHORTLIST")
      let newGlobalViewedCandidates = state.globalViewedCandidates || {}
      let updatedCandidate = action.candidate

      if(action.unlocked){
        updatedCandidate.unlockedCount += 1
        if (newGlobalViewedCandidates[updatedCandidate.sort]){
          newGlobalViewedCandidates[updatedCandidate.sort] += 1
        }
        else{
          newGlobalViewedCandidates[updatedCandidate.sort] = 1
        }
      }

      updatedCandidate.viewed = state.viewedCandidates ? state.viewedCandidates.includes(updatedCandidate.sort) : false;

      const idx = action.shortlist.findIndex((cands) => cands.sort === updatedCandidate.sort)
      let newShortlist = action.shortlist
      if (idx === -1){
        newShortlist.push(updatedCandidate)
      }
      else if (action.remove){
        newShortlist.splice(idx, 1); 
      }
      else{
        newShortlist[idx] = updatedCandidate
      }
      if (action.searchCandidates && action.searchCandidates.length > 0){
        let newSearchCandidates = action.searchCandidates
        let idx = newSearchCandidates.findIndex((cands) => cands.sort === updatedCandidate.sort)
        if (action.remove === "absolute"){
          newSearchCandidates.splice(idx, 1);
        }
        else{
          newSearchCandidates[idx] = updatedCandidate
        }
        return{
          ...state,
          searchShortlist: newShortlist,
          searchCandidates: newSearchCandidates,
          globalViewedCandidates: newGlobalViewedCandidates
        }
      }
      else{
        return{
          ...state,
          searchShortlist: newShortlist,
          globalViewedCandidates: newGlobalViewedCandidates
        }
      }
    }
    case FILTER_SEARCH_CANDIDATES:{
      const searchFilterOptions = state.searchFilterOptions
      const candidates = state.searchCandidates || []
      const _isRemote = action.selectedSearch ? action.selectedSearch.original_location !== "Work from Home / Remote / Nationwide" ? false : true : false
      console.log("HERE")
      console.log(_isRemote)

      let currentFilters = {}

      if (action.newSearch) {
        currentFilters = {"occupations": [], "skills":[], "distance":50, "accessed":182, "qualifications":[], "rightToWork":false, "additionalReqs":[], "maxRadius":100, "freeResume":true}
        currentFilters = {
          ...currentFilters,
          occupations : searchFilterOptions.allSearchableOccs ? searchFilterOptions.allSearchableOccs.reduce((map, value) => {return {...map, [value]:true}}, {}) : [],
          skills: searchFilterOptions.allSearchableSkills ? searchFilterOptions.allSearchableSkills.reduce((map, value) => {return {...map, [value]:true}}, {}) : [],
          qualifications: searchFilterOptions.allSearchableQuals ? searchFilterOptions.allSearchableQuals.reduce((map, value) => {return {...map, [value]:true}}, {}) : [],
          additionalReqs: searchFilterOptions.allAdditionalReqs ? searchFilterOptions.allAdditionalReqs.reduce((map, value) => {return {...map, [value]:false}}, {}) : [],
          maxRadius: searchFilterOptions.maxRadius ? searchFilterOptions.maxRadius : 100 
        }
      }      
      else if (action.filterChange){
        currentFilters = action.newFilters
      }
      else{
        currentFilters = state.searchFilter.currentFilters
      }

      const activeOccs = _.keys(_.pickBy(currentFilters.occupations))
      const activeSkills = _.keys(_.pickBy(currentFilters.skills))
      const activeQuals = _.keys(_.pickBy(currentFilters.qualifications))
      const activeAdditionalReqs = activeSkills.includes("Additional Requirements match")
      const activeRequiredSkills = activeSkills.includes("Required Skills match")
      const filteredCandidates = candidates.map((candidate) => {
        //ANDs
        if(!_isRemote && candidate.range > currentFilters.distance){
          return false
        }
        if(currentFilters.accessed < candidate.lastAccessedDays){
          return false
        }
        if(currentFilters.length > 0 && !activeQuals.includes(candidate.qualification)){
          return false 
        }
        if(currentFilters.rightToWork && !candidate.rightToWork){
          return false 
        }
        if(!currentFilters.freeResume && candidate.free_resume){
          return false 
        }
        candidate.filterMatches = []
        //ORs
        if(activeAdditionalReqs && candidate.additionalReqMatch){
          candidate.filterMatches.push("Additional Requirements match")
        }
        if(activeRequiredSkills && candidate.requiredSkillMatch){
          candidate.filterMatches.push("Required Skills match")
        }
        if(activeOccs.length > 0 && activeOccs.includes(candidate.occupationMatched)){
          candidate.filterMatches.push(candidate.occupationMatched)
        }
        if(activeSkills.length > 0){
          const sharedSkills = _.intersection(activeSkills, candidate.skillsMatched)
          if (sharedSkills.length > 0){
            candidate.filterMatches = candidate.filterMatches.concat(sharedSkills)
          }
        }
        return candidate.filterMatches.length === 0 ? false : candidate
      }).filter(Boolean)

      return{
        ...state,
        filteredSearchCandidates: filteredCandidates,
        searchFilter:{
          currentFilters: currentFilters,
          activeOccs: activeOccs,
          activeSkills: activeSkills,
          activeQuals: activeQuals,
          activeAdditionalReqs: activeAdditionalReqs
        }
      }
    }
    case FETCH_BOT_TEMPLATE:{
      return{
        ...state,
        searchChatTemplates:{
          base: action.baseTemplate,
          toggle: action.toggleQuestions,
          start_text: action.start_text,
          end_text: action.end_text,
          end_text_escape: action.end_text_escape,
          emailMain: action.emailMain,
          emailPromotional: action.emailPromotional,
          userTemplates: action.userTemplates
        }
      }
    }
    case ADD_TEMPLATE:{
      let newSearchChatTemplates = state.searchChatTemplates ? state.searchChatTemplates : {}
      newSearchChatTemplates.userTemplates.unshift(action.data);
      return {
        ...state,
        searchChatTemplates: newSearchChatTemplates
      }
    }
    case REMOVE_TEMPLATE:{
      let newSearchChatTemplates = state.searchChatTemplates ? state.searchChatTemplates : {}
      newSearchChatTemplates.userTemplates.splice(newSearchChatTemplates.userTemplates.indexOf(action.data), 1)
      return {
        ...state,
        searchChatTemplates: newSearchChatTemplates
      }
    }
    case EDIT_TEMPLATE:{
      const oldTemplate = state.searchChatTemplates.userTemplates.find(search => search.sort === action.data.sort);
      let newSearchChatTemplates = state.searchChatTemplates ? state.searchChatTemplates : {}
      newSearchChatTemplates.userTemplates.splice(newSearchChatTemplates.userTemplates.indexOf(oldTemplate), 1)
      newSearchChatTemplates.userTemplates.unshift(action.data);
      return {
        ...state,
        searchChatTemplates: newSearchChatTemplates
      }
    }
    case UPDATE_SNACKBAR:{
      return {
        ...state,
        snackBarText: action.data
      }
    }
    case GET_VIEW_PREFS:{
      return {
        ...state,
        columnViewPrefs: action.data
      }
    }
    default:
      return state;
  }
}
