import { Grid, Typography, withStyles, Divider } from "@material-ui/core";
import React from "react";
import { AnalyticsPieChart } from "./AnalyticsPieChart";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import moment from "moment";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  subheading: {
    marginLeft: 20,
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#444",
  },
  heading: {
    fontWeight: 500,
    fontSize: 18,
  },
  subheading1: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#666",
    marginLeft: 10,
    display: "inline-block",
  },
  cardroot: {
    width: "100%",
  },
  title: {
    fontSize: 14,
  },
  titleNumber: {
    textAlign: "right",
    fontWeight: 600,
    color: theme.palette.primary.main,
    float: "right",
  },
  hr: {
    height: 1,
    width: "100%",
    backgroundColor: "#e4e1e1",
    marginBottom: 10
  },
  marginTop10: {
    marginTop: 10
  }
});

export const CandidateAnalytics = withStyles(styles)(
  ({ jobsApplied, jobsSaved, jobsViewed, classes, isQual = false }) => {

    const getBackGroundColor = (index) => {
      if (jobsApplied.lastItemsStatus[index].unsuccessful){
        return "#AC693B"
      } else {
        return "#87D190";
      }
    }

    const getTextForButton = (index) => {
      if (!isQual){
        if (jobsApplied.lastItemsStatus[index].started){
          return "Started";
        }
        if (jobsApplied.lastItemsStatus[index].interviewed){
          return "Interviewed";
        }
        if (jobsApplied.lastItemsStatus[index].unsuccessful){
          return "Interviewed";
        }
        return "Applied";
      } else {
        if (jobsApplied.lastItemsStatus[index].started){
          return "Enrolled"
        }
        if (jobsApplied.lastItemsStatus[index].interviewed){
          return "Accepted"
        }
        if (jobsApplied.lastItemsStatus[index].unsuccessful){
          return "Accepted";
        }
        return "Applied";
      }
    }

    let arr = [];
    _.each(jobsViewed.chart, (index, key) => {
      arr.push({name: key, value: index});
    });
    let orderBy = _.orderBy(arr, "value", "desc");
    let assoArrChart = {};
    _.each(orderBy.slice(0, 10), (index) => {
      assoArrChart[index.name] = index.value;
    });


    const momentDate = (unix) => {
      return moment.unix(unix / 1000).format("DD/MM/YYYY");
    }


    return (
      <>
        {jobsViewed.count > 0 && (
          <Grid
            container
            direction="row"
            spacing={0}
            justify="center"
            alignItems="flex-start"
            style={{ marginTop: 20 }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" style={{fontSize:14, textAlign: "center"}}>Top 10 Viewed Jobs By Occupation</Typography>
              <AnalyticsPieChart chartData={assoArrChart} />
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h5" style={{fontSize:14, textAlign: "center"}}>Top Jobs By Sector</Typography>
              <AnalyticsPieChart chartData={jobsViewed.tierChart} />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          spacing={2}
          justify="center"
          alignItems="flex-start"
          style={{ marginTop: 20 }}
        >
          <Grid item xs={4}>
            <Card className={classes.cardroot}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {isQual ? "Qualifications Viewed" : "Jobs Viewed"}
                </Typography>
                <Typography variant="h6" component="h2">
                  {isQual ? "Total Qualifications Viewed" : "Total Jobs Viewed"}
                  <span className={classes.titleNumber}>
                    {jobsViewed.count}
                  </span>
                </Typography>
              </CardContent>
              <Divider />

              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {isQual
                    ? "Last 5 Qualifications Viewed"
                    : "Last 5 Jobs Viewed"}
                </Typography>

                {isQual &&
                  <List dense={true}>
                  {jobsViewed.lastItems.slice(0, 5).map((item,index) => (
                    <ListItem key={"jv_" + item} style={{ paddingLeft: 0 }}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
                }


                {!isQual &&
                    <List dense={true}>
                    {jobsViewed.lastItems.slice(0, 5).map((item,index) => (
                      <ListItem key={"jv_" + item} style={{ paddingLeft: 0 }}>
                        <ListItemText primary={item+" - "+momentDate(jobsViewed.lastItemsCreatedAt[index])} />
                      </ListItem>
                    ))}
                  </List>
                }
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.cardroot}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {isQual ? "Qualifications Saved" : "Jobs Saved"}
                </Typography>
                <Typography variant="h5" component="h2">
                  {isQual ? "Total Qualifications Saved" : "Total Jobs Saved"}
                  <span className={classes.titleNumber}>{jobsSaved.count}</span>
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {isQual ? "Last 5 Qualifications Saved" : "Last 5 Jobs Saved"}
                </Typography>

                {isQual &&
                    <List dense={true}>
                    {jobsSaved.lastItems.slice(0, 5).map((item, index) => (
                      <ListItem key={"js_" + item} style={{ paddingLeft: 0 }}>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                }

                {!isQual &&
                  <List dense={true}>
                    {jobsSaved.lastItems.slice(0, 5).map((item, index) => (
                      <ListItem key={"js_" + item} style={{ paddingLeft: 0 }}>
                        <ListItemText primary={item+" - "+momentDate(jobsSaved.lastItemsCreatedAt[index])} />
                      </ListItem>
                    ))}
                  </List>
                }
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.cardroot}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  
                  {isQual ? "Qualifications Inquiries" : "Jobs Applied"}
                </Typography>
                <Typography variant="h5" component="h2">
                {isQual ? "Total Qualifications Inquiries" : "Total Jobs Applied"}
                  <span className={classes.titleNumber}>
                    {jobsApplied.count}
                  </span>
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {isQual ? "Last 5 Qualifications Inquiries" : "Last 5 Jobs Applied"}
                </Typography>

                {isQual && 
                    <List dense={true}>
                    {jobsApplied.lastItems.slice(0, 5).map((item, index) => (
                      <ListItem key={"ja_" + item} style={{ paddingLeft: 0 }}>
                        <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={12} md={8}>
                          <ListItemText className={classes.marginTop10} primary={item} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <Button variant="contained" style={{color: "#F9F9F9", backgroundColor: getBackGroundColor(index)}}> {getTextForButton(index)}</Button>
                        </Grid>
                        <div className={classes.hr}> </div>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                }

                {!isQual &&
                  <List dense={true}>
                  {jobsApplied.lastItems.slice(0, 5).map((item, index) => (
                    <ListItem key={"ja_" + item} style={{ paddingLeft: 0 }}>
                      <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={8}>
                        <ListItemText className={classes.marginTop10} primary={item+" - "+momentDate(jobsApplied.lastItemsCreatedAt[index])} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Button variant="contained" style={{color: "#F9F9F9", backgroundColor: getBackGroundColor(index)}}> {getTextForButton(index)}</Button>
                      </Grid>
                      <div className={classes.hr}> </div>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
                }
                
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
);
