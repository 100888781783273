import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = {

}
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export default function configureStore(){

  return createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
  ));
}