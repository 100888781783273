import {
    SA_FEEDBACK,
    SA_FEEDBACK_USER,
    SA_FEEDBACK_DELETE
  } from "../actions/types";

import _ from "lodash";

export const FeedbackReducer = (state = {}, action) => {
    switch (action.type) {
        case SA_FEEDBACK: {
            let feedback = action.feedback.Items || [];
            return {
                ...state,
                feedback
            }
        }
        case SA_FEEDBACK_USER: {

            let user = action.user.Item || {};
            return {
                ...state,
                feedback_user: user
            }
        }
        case SA_FEEDBACK_DELETE: {

            let feedback = state.feedback;
            let newArr = [];
            _.each((feedback), (item) => {
                if (item.sort !== action.id) newArr.push(item);
            });


            return {
                ...state,
                feedback: newArr
            }
        }
        default:
            return state;
    }
}