import {
  Grid,
  makeStyles,
  Typography,
  Slider
} from "@material-ui/core";
import React from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from "lodash";
import SkillLine from "./SkillLine";

const styles = makeStyles((theme) => ({
  left20: {
    marginLeft: 20
  },
  relativeContainer: {
    marginTop:0,
    marginBottom:35
  },
  expertContainer: {
    textAlign: "right",
  },
  skillsContainer: {
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    marginLeft: 5,
    paddingRight: 15
  },
  coloredText: {
    color: "#5A6375",
    fontSize: 10,
  },
  MatchSkillContainer: {
    marginTop: 15,
    marginBottom: 15
  },
  absoluteContainer: {
    position: "absolute",
    height: 20,
    borderRadius: 20,
    left: 25
  },
  absoluteContainerSlider: {
    position: "absolute"
  },
  relative: {
    position: "relative"
  },
  zIndex: {
    zIndex: 10
  },
  matchingKey: {
    backgroundColor: "#53D28E",
    width: 15,
    height: 15,
    borderRadius: 20,
    marginLeft: 35 
  },
  missingKey: {
    backgroundColor: "#E06923",
    width: 15,
    height: 15,
    borderRadius: 20,
    marginLeft: 30
  },
  hoverState: {
    backgroundColor: "#0000008c",
    color: "#ffffff",
    padding: 10,
    position: "absolute",
    borderRadius: 10
  },
  height50: {
    height: 75
  },
  center: {
    textAlign: "center"
  },
  marginTop30: {
    marginTop: 30,
    marginLeft: 25
  }
}));



export const SkillsList = (props) => {
  const classes = styles();
  let similar_skills = props.job.similar_skills;
  let missing_skills = props.job.missing_skills;
  let occupationSkills = props.candidate.occupationSkills;
  let matchedSkills = [];
  let matchedSkillsObj = {};
  _.each(similar_skills, (skill) => {
    _.each(occupationSkills, (occskill) => {
      _.each(occskill.skills, (_skill) => {
        // will stop duplicates being added.
        if (skill === _skill.name && !matchedSkillsObj[_skill.name]){
          let skillLevel;
          let color;
          switch(_skill.level){
            case 0:
              skillLevel = "Beginner";
              color = "#22c8f9";
              break;
            case 1:
              skillLevel = "Beginner";
              color = "#22c8f9";
              break;
            case 2:
              skillLevel = "Familiar";
              color = "#49cdda";
              break;
            case 3:
              skillLevel = "Proficient";
              color = "#66b19c";
              break;
            case 4:
              skillLevel = "Expert";
              color = "#4E847B";
              break;
            default:
              skillLevel = "Expert";
              color = "#4E847B";
            break
          }
          _skill.skillLevel = skillLevel;
          _skill.color = color;
          matchedSkills.push(_skill);
          matchedSkillsObj[_skill.name] = true;
        }
      });
    });
  });

  matchedSkills = _.orderBy(matchedSkills, "level", "asc");
  const Hover = ({ onHover, children}) => {
    let [onHovering, onHoveringSet] = React.useState(false);
    return <div className="hover">
          <div onMouseEnter={() => {
            onHoveringSet(true);
          }} onMouseLeave={() => {
            onHoveringSet(false);
          }} >{children}</div>
        {onHovering && (
        <div className="hover__hover">{onHover}</div>
        )}
    </div>
  };

  /*eslint-disable*/
  const [value, setValue] = React.useState(props.job.score_bp);
  let jobDifference = "";
  let jobQualification = "unknown";
  /*eslint-enable*/
      const skills = () => {
        return <> 
        {similar_skills.length > 0 && (
        <>
        <Grid container className={classes.MatchSkillContainer}>
          <Grid item xs={12}>
            <Typography variant="body1"> Matching Skills </Typography>
          </Grid>
        </Grid>
        <div> 
          {matchedSkills.map((skill,index) => (
            <div style={{display:"block", width:"fit-content", marginBottom: 10}} key={`skill-list-${index}`} >
             <Hover onHover={<div className={classes.relative}> <div className={classes.hoverState}>{skill.skillLevel}</div> </div>}>
              <SkillLine skill={skill}/>
            </Hover>
            </div>
          ))}
        </div>
        </>
        )}
        {missing_skills.length > 0 && (
          <>
          <Grid container className={classes.MatchSkillContainer}>
            <Grid item xs={12}>
              <Typography variant="body1"> Missing Skills </Typography>
            </Grid>
          </Grid>
          <div> 
            {missing_skills.map((skill, index) => (
              <div style={{display:"block", width:"fit-content", marginBottom: 10}} key={`skill-list-missing-${index}`} >
                <SkillLine skill={{name: skill, level: 0}} />
              </div>
            ))}
          </div>
          </>
          )}
        </>
      }
    
    let category = 0;
    if (props.candidate && props.candidate.qualifications ){
        category = props.candidate.qualifications.category;
        jobQualification = props.candidate.qualifications.name;
        if (jobQualification === "" || !jobQualification) {
          jobQualification = "no qualifications"
        }
        if (!category || category === ""){
          category = 0;
        }
    }

    let jobscore = 1; let isQual = false;
    if (props.job && props.job.ONET_required_level_education){
      jobscore = props.job.ONET_required_level_education;
    }

    if (props.job && props.job.isQual){
      isQual = true;
    }

    if (parseInt(category) > parseInt(jobscore)) { 
      jobDifference = <><b>lower</b> than </>
    } else if (parseInt(category) === parseInt(jobscore)){
      jobDifference = <><b>similar</b> to </>;
    } else if (parseInt(category) < parseInt(jobscore)) {
      jobDifference = <><b>higher</b> than </>
    }
    /*eslint-disable*/
    const [qualificationValue, setQualificationValue] = React.useState([parseInt(category), parseInt(jobscore)]);
    /*eslint-enable*/
    return <div className={classes.skillsContainer}>
      <Grid container alignItems="center" className={classes.relativeContainer}>
        <Grid item xs={11} className={classes.relative}>
            <LinearProgress style={{width: value+"%",backgroundColor: "#53D28E"}} className={`${classes.absoluteContainer} ${classes.zIndex}`}
            value={100}
            variant="determinate"
            color="#53D28E"
            aria-labelledby="discrete-slider-always"
            />
            <LinearProgress style={{width: "100%"}}  className={classes.absoluteContainer}
            value={100}
            variant="determinate"
            color="primary"
            aria-labelledby="discrete-slider-always"
            />
        </Grid>
      </Grid>
      <Grid container justify="center"  spacing={4}> 
        <Grid item> <Typography variant="caption"> Matching skills <div className={classes.matchingKey}></div></Typography></Grid>
        <Grid item> <Typography variant="caption"> Missing skills <div className={classes.missingKey}></div></Typography></Grid>
      </Grid>
      {skills()}
      {isQual === false && (
      <>
      <Grid item xs={11} className={classes.relative} style={{marginTop: 75, marginBottom: 0, marginLeft: 25}}>
          <Slider
          className={classes.absoluteContainerSlider}
          value={parseInt(category)}
          min={0}
          max={11}
          valueLabelDisplay="on"
          valueLabelFormat={(value, index) => {
            if (qualificationValue[0] === qualificationValue[1]){
              return "\u2713";
            } else {
              return "YOU"
            }
          }}
        />
         <Slider
          className={classes.absoluteContainerSlider}
          value={parseInt(jobscore)}
          min={0}
          max={11}
          valueLabelDisplay="on"
          valueLabelFormat={(value, index) => {
            if (qualificationValue[0] === qualificationValue[1]){
              return "\u2713";
            }  else {
                if (isQual){
                  return "QUAL";
                } else {
                  return "JOB";
                }
            }
          }}
        />
      </Grid>
      <Grid container>
      <Grid className={`${classes.center} ${classes.marginTop30}`} item xs={11}> <Typography  variant="caption"> On average the qualification required for this job is {jobDifference} <b>{jobQualification}</b> </Typography></Grid>
      </Grid>
      </>)}
      <Grid container justify="center"  spacing={4}>
        <Grid style={{height: 100}} item xs={12}></Grid>
      </Grid>
    </div>
  };
