import { combineReducers } from "redux";
import authReducer from "./authReducer";
import candidatesReducer from "./candidatesReducer";
import userDetailsReducer from "./portalDetailsReducer";
import portalUsersReducer from "./portalUsersReducer";
import vacanciesReducer from "./vacanciesReducer";
import adminReducer from "./adminReducer";
import qualificationReducer from "./qualficationReducer";
import { rewardReducer } from "./rewardReducer";
import { FeedbackReducer } from "./feedbackReducer";
import {jobReducer} from "./jobReducer";
import {caseloadReducer} from "./caseloadReducer";

import { RESET_APP } from "../actions/types";

const allReducers = combineReducers({
  auth: authReducer,
  candidates: candidatesReducer,
  portaldetails: userDetailsReducer,
  portalusers: portalUsersReducer,
  vacancies: vacanciesReducer,
  sa: adminReducer,
  qualifications: qualificationReducer,
  rewards: rewardReducer,
  feedback: FeedbackReducer,
  jobReducer: jobReducer,
  caseload: caseloadReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = {
      ...state,
      candidates: {},
      portaldetails: {},
      portalusers: {},
      vacancies: {},
      sa : {},
      qualifications: {},
      caseload: {}
    };
  }

  return allReducers(state, action);
};

export default rootReducer;
