import { Paper } from "@material-ui/core";
import MaterialTable from '@material-table/core';
import moment from "moment";
import React from "react";
//import { CultureMatch } from "./CultureMatch";
//import { JobZone } from "./JobZone";
import { OccupationalArea } from "./OccupationalArea";
import { OccupationalMatch } from "./OccupationalMatch";
import { SkillsIcon, InterestsIcon, StylesIcon } from "./icons";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash";
import config from "../../../config";

const columnDefinitions = ({ isSkillzBackpack }) => [
  /* {
    title: "Occupational Match",
    render:  render: (job) => <OccupationalMatch {...job} />,
    disableClick: isSkillzBackpack ? true : false,
  },*/
  /* {
    title: "Occupation Accessibility",
    render: (job) => <JobZone {...job} />,
    disableClick: true,
  },*/
  /* {
    title: "Personality / Culture Match",
    render: (props) => <CultureMatch {...props} />,
  },*/

  {
    title: "Job Title",
    field: "jobTitle",
    render: ({ jobTitle }) => (
      <span>
        <b>{jobTitle}</b>
      </span>
    ),
  },
  {
    title: <SkillsIcon />,
    field: "jobTitle",
    render: ({ score_bp }) => <OccupationalMatch score_bp={score_bp} />,
    customSort: (a, b) => {return a.score_bp - b.score_bp}
  },
  {
    title: <InterestsIcon />,
    field: "jobTitle",
    render: ({ score_interest }) => (
      <OccupationalMatch score_bp={score_interest} />
    ),
    customSort: (a, b) => {return a.score_interest - b.score_interest}
  },
  {
    title: <StylesIcon />,
    field: "jobTitle",
    render: ({ score_styles }) => <OccupationalMatch score_bp={score_styles} />,
    customSort: (a, b) => {return a.score_styles - b.score_styles}

  },
  {
    title: "Quals",
    field: "jobTitle",
    render: ({ score_quals }) => {
      return score_quals === true ? (
        <div style={{ textAlign: "center" }}>
          <DoneIcon />
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <ClearIcon style={{ margin: "auto" }} />
        </div>
      );
    },
  },
  /* {
    title: "Experience",
    field: "jobTitle",
    render: ({ jobTitle }) => <span>TBD</span>,
  },*/
  {
    title: "Occupational Area",
    field: "high_occupation",
    render: (fields) => <OccupationalArea {...fields} />,
  },
  { 
    title: "Location",
    field: "original_location",
    customSort: (a, b) => {return a.distance_in_miles - b.distance_in_miles}
  },
  { title: "Company", field: "employerName" },
  {
    title: "Posted",
    field: "postedDate",
    render: ({ postedDate }) => (
      <span>{moment.unix(postedDate).format("DD/MM/YYYY")}</span>
    ),
  },
  {
    title: "Minimum Salary",
    field: "annual_salary_min",
    render: ({ annual_salary_min, salary_type }) => {
      if (annual_salary_min === 0) {
        return <span>Salary Unknown</span>;
      }
      let salaryExtension = ""
      if(salary_type){
        if(salary_type === "annual"){
          salaryExtension = " per annum"
        }
        else if(salary_type === "hourly"){
          salaryExtension = " per hour"
        }
        else if(salary_type === "daily"){
          salaryExtension = " per day"
        }
      }
      return (
        <span>
          {new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: config.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(annual_salary_min) + salaryExtension}
        </span>
      );
    },
  },
  {
    title: "Contract Type",
    render: ({ job_contract }) => (
      <span>
        {job_contract
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      </span>
    ),
  },
];

export const JobRecommendationsTable = (props) =>{
  let {
    isLoading,
    onRowClick,
    jobRecommendations,
    optionalMessageWhenEmpty = "There are no suitable jobs based on the above criteria.  Please broaden the search",
    jobMatchType
  } = props;

  const [selectedRow, setSelectedRow] = React.useState(null);


  let data = jobRecommendations.filter((job) => job.jobTitle !== "unknown");
  if (jobMatchType === "styles"){
    data = _.orderBy(data, ["score_styles"], "desc");
  } else if (jobMatchType === "interest"){
    data = _.orderBy(data, ["score_interest"], "desc");
  } else {
    data = _.orderBy(data, ["score_bp"], "desc");
  }

  
  return <MaterialTable
    isLoading={isLoading}
    title="Manage Candidates"
    columns={columnDefinitions({
      isSkillzBackpack: jobMatchType === "skills_backpack",
    })}
    data={data}
    // Disable the pagination
    components={{
      Container: (props) => <Paper {...props} elevation={0} />,
      Toolbar: () => null,
    }}
    options={{
      pageSize: 20,
      headerStyle: { paddingRight: 0, textAlign: "center" },
      rowStyle: rowData => ({
        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
      })
    }}
    onRowClick={(evt, selectedRow) => {
      onRowClick(evt, selectedRow);
      setSelectedRow(selectedRow.tableData.id)
    }}
    localization={{
      body: {
        emptyDataSourceMessage: optionalMessageWhenEmpty,
      },
    }}
  />
};
