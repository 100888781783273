import React from "react";
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from "recharts";

const colorSchema = {
  creator: "#D89E22",
  doer: "#948BFF",
  driver: "#34CFFF",
  investigator: "#FFB61C",
  facilitator: "#FF5255",
  crafter: "#53D28E",
};

export const InterestPieChart = ({ interests }) => {
  return (
    <div style={{ height: "20em" }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={interests}
            dataKey="value"
            nameKey="name"
            cx="50%"
            paddingAngle={5}
            cy="50%"
            innerRadius={60}
            outerRadius={90}
            fill="#82ca9d"
            cornerRadius={3}
            animationDuration={600}
          >
            {interests
              ? interests.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colorSchema[entry.name]} />
                ))
              : null}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
