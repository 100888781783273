import { AppBar, Grid, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { InterestsPanel } from "./InterestsPanel";
import { SkillsPanel } from "./SkillsPanel";
import { StylesPanel } from "./StylesPanel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  gridcont: {
    height: "100%"
  },
  griditem: {
    height: "100%"
    //padding : theme.spacing(2)
  }
}));

const PanelGroup = ({
  isOnPrintMode,
  job,
  candidateInterestsScore,
  candidateStyles
}) => {
  const classes = useStyles();

  if (isOnPrintMode) {
    return (
      <Grid
        container
        direction="column"
        justify="space-between"
        spacing={3}
        className="section"
      >
        <Grid item>
          <Grid container>
            <Grid item xs>
              <SkillsPanel
                isOnPrintMode={isOnPrintMode}
                skills={job.skills}
                skillScore={job.skillScore}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="row" justify="space-between" spacing={3}>
            <Grid item xs={6}>
              <InterestsPanel
                isOnPrintMode={isOnPrintMode}
                interestScore={job.interestScore}
                data={Object.keys(job.interests).map(key => ({
                  label: key,
                  value: job.interests[key],
                  candidate: candidateInterestsScore[key.toLowerCase()]
                }))}
              />
            </Grid>
            <Grid item xs={6}>
              <StylesPanel
                isOnPrintMode={isOnPrintMode}
                stylesScore={job.stylesScore}
                data={Object.keys(job.styles).map(key => {
                  if (key === "Responsiveness to feedback") {
                    return {
                      label: "Responsiveness",
                      value: job.styles[key],
                      candidate: candidateStyles.find(
                        a => a.name === "Responsiveness"
                      ).latest
                    };
                  }
                  return {
                    label: key,
                    value: job.styles[key],
                    candidate: candidateStyles.find(a => a.name === key).latest
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2} className={classes.gridcont}>
      <Grid item xs={4} className={classes.griditem}>
        <SkillsPanel
          isOnPrintMode={isOnPrintMode}
          skills={job.skills}
          skillScore={job.skillScore}
        />
      </Grid>
      <Grid item xs={4} className={classes.griditem}>
        <InterestsPanel
          isOnPrintMode={isOnPrintMode}
          interestScore={job.interestScore}
          data={Object.keys(job.interests).map(key => ({
            label: key,
            value: job.interests[key],
            candidate: candidateInterestsScore[key.toLowerCase()]
          }))}
        />
      </Grid>
      <Grid item xs={4} className={classes.griditem}>
        <StylesPanel
          isOnPrintMode={isOnPrintMode}
          stylesScore={job.stylesScore}
          data={Object.keys(job.styles).map(key => {
            if (key === "Responsiveness to feedback") {
              return {
                label: "Responsiveness",
                value: job.styles[key],
                candidate: candidateStyles.find(
                  a => a.name === "Responsiveness"
                ).latest
              };
            }
            return {
              label: key,
              value: job.styles[key],
              candidate: candidateStyles.find(a => a.name === key).latest
            };
          })}
        />
      </Grid>
    </Grid>
  );
};

export const JobMatchesGrid = ({
  jobs,
  classes,
  candidateStyles,
  candidateInterestsScore,
  type,
  isOnPrintMode
}) => {
  if (jobs.length === 0) {
    return null;
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.sectionTitle}
        >
          <Toolbar variant="dense" className={classes.center}>
            <Typography variant="h6" color="inherit">
              Top 5 {type === "normal" ? "Normal" : "Tech"} Job Matches
            </Typography>
          </Toolbar>
        </AppBar>
        
      </Grid>
      <Grid item>
        {jobs.map((job, index) => {
          return (<>
            <Grid
              container
              direction="column"
              spacing={1}
              key={job.sector + index}
            >
              <Grid item className="pagebreak">
              
                <Typography
                  variant="subtitle2"
                  component="h1"
                  className={classes.subtitle3}
                >
                  Occupation {index + 1}:{" "}
                  {type === "normal" ? (
                    job.title
                  ) : (
                    <a href={job.url} rel="noopener noreferrer" target="_blank">
                      {job.title}
                    </a>
                  )}{" "}
                  {type === "normal" ? null : <>(Sector: {job.sector})</>}
                </Typography>
              </Grid>

              <Grid
                item
                className={
                  isOnPrintMode
                    ? classes.jobpanelcontainerprint
                    : classes.jobpanelcontainer
                }
              >
                {
                  <PanelGroup
                    isOnPrintMode={isOnPrintMode}
                    candidateInterestsScore={candidateInterestsScore}
                    candidateStyles={candidateStyles}
                    job={job}
                  />
                }
              </Grid>
              
            </Grid>
            {index %2 === 0 && (<div class="pagebreak" ></div>)}
            
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};
