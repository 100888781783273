import {
  Grid,
  LinearProgress,
  Typography,
  Button,
  withStyles
} from "@material-ui/core";
import classnames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const styles = (theme) => ({
  itemtitle: {
    fontSize: 12,
    cursor: "pointer"
  },
  inactiveTitle: {
    color: "#9f9f9f",
    opacity: 0.8
  },
  percentageGrid: {
    paddingLeft:10, 
    paddingRight:10, 
    marginBottom:5, 
    marginRight: 20, 
    backgroundColor:"#ff6505", 
    borderRadius:"5px" 
  }
});

const BackpackMatchesComponent = (props) => {
  const {
    classes,
    candidates: {
      selectedCandidateData: { occupationBlacklist },
      skills_backpack_occupations,
      styles_occupations,
      interest_occupations
    },
    onMatchClick,
    onJobClick,
  } = props;

  const [activeMatch, setActiveMatch] = useState("previous");

  const matchListMap = {
    previous: skills_backpack_occupations,
    interests: interest_occupations,
    styles: styles_occupations,
  };

  if (!skills_backpack_occupations) {
    return null;
  }

  var list = {};

  if (matchListMap && matchListMap[activeMatch] && matchListMap[activeMatch]) {
    matchListMap[activeMatch].forEach((item) => {
      let title = item.occupation;
      let similarity = 0
      if (activeMatch === "previous") {
        similarity = item.score_bp
      }
      else if (activeMatch === "styles"){
        similarity = item.score_styles
      }
      else{
        similarity = item.score_interest
      }

      list[title] = similarity;
    });
  }
  return (
    <Grid container>
      <Grid item xs={5}>
        <div>
          <Button
            variant="contained"
            className={classnames(
              classes.itemtitle,
              activeMatch !== "previous" ? classes.inactiveTitle : undefined
            )}
            onClick={() => setActiveMatch("previous")}
          >
            Best Skills Match:
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            className={classnames(
              classes.itemtitle,
              activeMatch !== "styles" ? classes.inactiveTitle : undefined
            )}
            style={{marginTop: 15}}
            onClick={() => setActiveMatch("styles")}
          >
            Best style matches:
          </Button>
        </div>        
        <div>
          <Button
            variant="contained"
            className={classnames(
              classes.itemtitle,
              activeMatch !== "interests" ? classes.inactiveTitle : undefined
            )}
            style={{marginTop: 15}}
            onClick={() => setActiveMatch("interests")}
          >
            Best interest matches:
          </Button>
        </div>
      </Grid>
      <Grid
        item
        xs
        style={{ height: "11em", overflow: "hidden", overflowY: "auto" }}
      >
        <Grid container direction="column" spacing={1}>
          {Object.keys(list).map((item) => {
            return (
              <Grid item key={item}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="baseline"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ maxWidth: "30em", cursor: "pointer" }}
                      onClick={() => {
                        onMatchClick();
                        onJobClick(item);
                      }}
                    >
                      <ArrowRightIcon style={{position: "relative", float: "left"}} /> {item}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      {occupationBlacklist.includes(item) && (
                      <Grid item className={classes.percentageGrid}>
                        <Typography variant="subtitle2" component="p">Candidate not interested</Typography>
                      </Grid>
                      )}
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          style={{ textAlign: "right", marginTop: -19 }}
                        >
                          {list[item]}%
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <LinearProgress
                  variant="buffer"
                  value={list[item]}
                  valueBuffer={100}
                  style={{ height: 2 }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {},
      dispatch
    ),
    dispatch,
  };
}

export const BackpackMatches = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BackpackMatchesComponent));
