import React from "react";
import "./App.css";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import * as ROUTES from "./constants/routes";
import { validateUserSession as validateUserAction } from "./actions/auth_actions";
import { Cache, Logger } from "aws-amplify";
import LinearProgress from "@material-ui/core/LinearProgress";

import config from "./config/";

import defineRulesFor from "./config/permissions";
//componenets
import Login from "./components/Auth/Login";
import Forget from "./components/Auth/Forget";
import Main from "./components/Main";
import Print from "./components/Print";
import AutoLogout from './components/AutoLogout';
import PricingTable from './components/Auth/PricingTable';
import { StylesProvider, createGenerateClassName } from "@material-ui/styles";

const generateClassName = createGenerateClassName({
  productionPrefix: "sm",
  seed: "sm"
});

Logger.LOG_LEVEL = process.env.REACT_APP_STAGE === "prod" ? "ERROR" : "INFO";

const logger = new Logger("MAIN", "INFO");

const NotFoundRedirect = () => <Redirect to="/" />;

const PublicRoute = ({ component: ReactComponent, authStatus, ...rest }) => {
  logger.info("App.PublicRoute() authStatus:", authStatus);

  return (

    <Route
      {...rest}
      render={props =>
        authStatus === true ? (
          <Redirect
            to={
              Cache.getItem("lastpath")
                ? Cache.getItem("lastpath")
                : ROUTES.MAIN
            }
            {...props}
          />
        ) : authStatus === false ? (
          <ReactComponent {...props} />
        ) : (
          <LinearProgress />
        )
      }
    />
  );
};

const PrivateRoute = ({ component: ReactComponent, init, authStatus, ...rest  }) => {
  var x = { ...rest };
  let path = x.location.pathname;

  logger.info(
    "App.PrivateRoute() authStatus:",
    authStatus,
    path,
    init,
    window.ability.can("visit", path)
  );
  var t = path.split("/");
  t.pop();
  let newpath = t.join("/");
  
  return (
    <div>
    <AutoLogout />
    <Route
      {...rest}
      render={props =>
        authStatus === true && init === true ? (
          //VERY IMPORTANT! IF USER CANT VISIT A PATH, IT WILL BE REDIRECTED TO PARENT PATH
          window.ability.can("visit", path) ? (
            <ReactComponent {...props} />
          ) : (
            <Redirect to={newpath} />
          )
        ) : authStatus === false ? (
          <Redirect to={ROUTES.LOGIN} />
        ) : (
          <LinearProgress />
        )
      }
    />
    </div>
  );
};

const DefaultRoute = ({ authStatus,init, ...rest }) => {
  logger.info("App.DefaultRoute() authStatus:", authStatus);

  Cache.removeItem("lastpath");
  return (
    <Route
      {...rest}
      render={props =>
        authStatus === true  && init === true ? (
          <Redirect to={ROUTES.MAIN} {...props} />
        ) : authStatus === false ? (
          <Redirect to={ROUTES.LOGIN} />
        ) : (
          <LinearProgress />
        )
      }
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleWindowClose = this.handleWindowClose.bind(this);
  }

  state = {
    role: "",
    init: false
  };
  componentDidMount() {
    logger.info("App.componentDidMount() props: ", this.props);
    // if session contains valid
    this.setState({
      role: this.props.role
    });
    this.validateUser();
    //window.addEventListener("beforeunload", this.handleWindowClose);

    this.interval = setInterval(() => {
      this.validateUser();
    }, config.DEFAULT_SESSIONCHECK_TIME);
    //session check in every 5 mins


  }

  componentWillUnmount() {
    clearInterval(this.interval);
    //window.removeEventListener("beforeunload", this.handleWindowClose);
  }

  componentDidUpdate(prevProps) {
    if (this.props.role !== prevProps.role) {
      this.setState({
        role: this.props.role
      });
    }
  }

  handleWindowClose = async e => {
    e.preventDefault();
  };

  validateUser() {
    if (this.props.authenticated !== false) {
      this.props.validateUserAction();
    }
  }

  render() {
    logger.info("App.render() props: ", this.props);
    if (this.state.role !== this.props.role && this.props.role !== "flexible") {
      const rules = defineRulesFor(this.props.role);
      this.props.ability.update(rules);
    }
    
    if (this.state.role === "flexible" && this.state.init === false) {

        if (this.props.portalDetails &&  this.props.portalDetails.user && this.props.portalDetails.user.viewPermissions) {
          let optional_rules = defineRulesFor(this.props.role, this.props.portalDetails.user.viewPermissions);
          this.props.ability.update(optional_rules);
              this.setState({
                init: true
              });
        }
    } else if (this.state.role !== "" && this.state.init === false && this.props.portalDetails && this.props.portalDetails.user ) {
      this.setState({init: true});
    };


    return (
      <div>
        <StylesProvider generateClassName={generateClassName}>
          <Router>
            <Switch>
              <DefaultRoute
                exact
                path="/"
                authStatus={this.props.authenticated}
                init={this.state.init}
              />
              <PrivateRoute
                path={ROUTES.PRINT}
                component={Print}
                authStatus={this.props.authenticated}
                init={this.state.init}

              />
              <PrivateRoute
                path={ROUTES.MAIN}
                component={Main}
                authStatus={this.props.authenticated}
                init={this.state.init}

              />
              
              <PublicRoute
                exact
                path={ROUTES.LOGIN}
                component={Login}
                authStatus={this.props.authenticated}
                init={this.state.init}

              />
              <PublicRoute
                exact
                path={ROUTES.FORGET}
                component={Forget}
                authStatus={this.props.authenticated}
                init={this.state.init}

              />
              <PublicRoute
                exact
                path={ROUTES.PRICING}
                component={PricingTable}
                authStatus={this.props.authenticated}
                init={this.state.init}

              />

              <Route component={NotFoundRedirect} />
            </Switch>
          </Router>
        </StylesProvider>
      </div>
    );
  }
}

// Runtime type checking for React props
App.propTypes = {
  validateUser: PropTypes.func,
  history: PropTypes.object,
  errorMessage: PropTypes.string
};

function mapStateToProps(state) {

  return {
    authenticated: state.auth.authenticated,
    role: state.auth.role,
    portalDetails: state.portaldetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        validateUserAction
      },
      dispatch
    ),
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
