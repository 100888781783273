import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import LinearProgress from "@material-ui/core/LinearProgress";

import { ReactComponent as Shield } from "./icons/rewardShield.svg";
import { ReactComponent as SmallCoin } from "./icons/smallCoin.svg";

const styles = (theme) => ({
  container: {
    height: 300,
  },
  orangepaper: {
    backgroundColor: "#FF6A14",
    //paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: 0,
    minHeight: 60,
  },
  shield: {
    marginLeft: 20,
    marginTop: 16,
    marginBottom: 16,
    marginRight: 16,
  },
  coinIcon: {
    marginRight: 4,
    marginBottom: 10,
  },
  coinText: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#FFFFFF",
    fontFamily: [
      "Spectral",
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    marginRight: theme.spacing(2),
  },
  coinsmalltitle: {
    fontSize: 10,
    color: "white",
    textAlign: "right",
    marginRight: theme.spacing(2),
    marginTop: -5,
    fontWeight: 600,
    marginLeft: 20,
  },
  rightcoins: {
    marginLeft: 30,
  },
  levelTitle: {
    color: "#ffffff",
    fontSize: 20,
    fontWeight: 600,
    marginTop: 0,
  },
  progress: {
    height: 12,
    borderRadius: 24,
    backgroundColor: "#DB5305",
    marginBottom: 4,
    marginTop: 3,
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      height: 8,
    },
  },
  leftprogress: {
    backgroundColor: "#FDEFE7",
    borderRadius: 24,
  },
  leftcoinsprogress: {
    textAlign: "left",
    fontSize: 10,
    color: "white",
    marginTop: 9
  },
});

class CoinLevels extends Component {
  componentDidMount() {}

  render() {
    const { data = {}, classes } = this.props;
    const { coins = 0, level = 0, toNextLevel = 0, levelRange = 0, totalEarnedCoins = 0 } = data;
    return (
      <Paper className={classes.orangepaper} elevation={0} style={{marginTop: 30}} >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Shield className={classes.shield} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography variant="h5" className={classes.levelTitle}>
              Level {level}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={((levelRange - toNextLevel) / levelRange) * 100}
              className={classes.progress}
              classes={{ barColorPrimary: classes.leftprogress }}
            />
            <Typography variant="body1" className={classes.leftcoinsprogress}>
              {toNextLevel} COINS FOR NEXT LEVEL
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5" className={classes.coinText}>
              <SmallCoin className={classes.coinIcon} />
              {totalEarnedCoins}
            </Typography>
            <Typography variant="body1" className={classes.coinsmalltitle}>
            TOTAL COINS EARNED
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item className={classes.rightcoins}>
              <Typography variant="h5" className={classes.coinText}>
                <SmallCoin className={classes.coinIcon} />
                {coins}
              </Typography>
              <Typography variant="body1" className={classes.coinsmalltitle}>
              CURRENT COINS
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CoinLevels);
