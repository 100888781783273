import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";

const styles = {
  card: {
    minWidth: 275
  },
  cardContent: {
    minWidth: 275,
    height: 400,
    width: "100%"
  },
  labelsContent: {},
  labelItem: {
    marginTop: "5rem",
    textAlign: "left"
  }
};

function SummaryRadarCard(props) {
  const { data, dataKey } = props;
  // console.log(data);

  let assoArr = {};
  _.each(data, (i) => {
    assoArr[i.name] = {
      name: i.name,
      first: i.first,
    }
    if (i.latest) assoArr[i.name].latest = i.latest;
  });

  let order = ["Complexity", "Speed", "Accuracy", "Adaptability", "Responsiveness", "Learning", "Confidence", "Control", "Commitment"];
  let arr = [];
  _.each(order, (i) => {
    if (assoArr[i]) {
      arr.push(assoArr[i]);
    }
  });

  return (
    <ResponsiveContainer height={400}>
      <RadarChart data={arr}>
        <PolarGrid />
        <PolarAngleAxis dataKey={dataKey} />
        <PolarRadiusAxis domain={[0, 100]} angle={70} />
        {
          <Radar
            name="Initial Assessment"
            dataKey="first"
            stroke="#696969"
            fill="#000000"
            strokeWidth={3}
            fillOpacity={0.1}
          />
        }
        <Radar
          name="Latest Assessment"
          dataKey="latest"
          stroke="#ff6505"
          fill="#ff6505"
          strokeWidth={3}
          fillOpacity={0.1}
        />
        <Legend /> <Tooltip />
      </RadarChart>
    </ResponsiveContainer>
  );
}

SummaryRadarCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired
};

export default withStyles(styles)(SummaryRadarCard);
