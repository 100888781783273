import {
  OPEN_KEYWORKER_DIALOG,
  CLOSE_KEYWORKER_DIALOG,
  ADD_KEYWORKER_CONTRACT,
  FETCH_PORTAL_USERS,
  CUSTOMER_CHECK
} from "../actions/types";

import { Logger } from "aws-amplify";
const logger = new Logger("PORTAL USERS.REDUCER", "DEBUG");

export default function(state = {}, action) {
  switch (action.type) {
    case OPEN_KEYWORKER_DIALOG: {
      const returnProps = {
        ...state,
        keyworkerData: action.keyworker,
        open: true,
        loading: false
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case CLOSE_KEYWORKER_DIALOG: {
      const returnProps = {
        ...state,
        keyworkerData: {},
        open: false,
        loading: false
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case ADD_KEYWORKER_CONTRACT: {
      const returnProps = {
        ...state,
        loading: true
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case FETCH_PORTAL_USERS: {
      const keyworkers = action.data.keyworkers.Users.sort((a, b) => {
        const firstKeyWorkerLastname = a.Attributes.find(
          ({ Name }) => Name === "family_name"
        ).Value;
        const secondKeyWorkerLastname = b.Attributes.find(
          ({ Name }) => Name === "family_name"
        ).Value;
        return firstKeyWorkerLastname.localeCompare(secondKeyWorkerLastname);
      }).map(item => {
        const itemName = item.Attributes.find(({ Name }) => Name === "name")
          .Value;
        return { ...item, name: itemName };
      });

      const orgadmins = action.data.orgadmins.Users.sort((a, b) => {
        const firstKeyWorkerLastname = a.Attributes.find(
          ({ Name }) => Name === "family_name"
        ).Value;
        const secondKeyWorkerLastname = b.Attributes.find(
          ({ Name }) => Name === "family_name"
        ).Value;
        return firstKeyWorkerLastname.localeCompare(secondKeyWorkerLastname);
      }).map(item => {
        const itemName = item.Attributes.find(({ Name }) => Name === "name")
          .Value;
        return { ...item, name: itemName };
      });

      const partners = action.data.partners.Users.sort((a, b) => {
        const firstKeyWorkerLastname = a.Attributes.find(
          ({ Name }) => Name === "family_name"
        ).Value;
        const secondKeyWorkerLastname = b.Attributes.find(
          ({ Name }) => Name === "family_name"
        ).Value;
        return firstKeyWorkerLastname.localeCompare(secondKeyWorkerLastname);
      }).map(item => {
        const itemName = item.Attributes.find(({ Name }) => Name === "name")
          .Value;
        return { ...item, name: itemName };
      });

      //do partners and orgadmins too

      return {
        ...state,
        keyworkers: keyworkers,
        orgadmins: orgadmins,
        partners: partners,
        allProviders: action.data.providers,
        allContracts: action.data.contracts
      };
    }
    case CUSTOMER_CHECK:{
      return {
        ...state,
        isCustomer: true
      }
    }
    default:
      return state;
  }
}
