import { Typography } from "@material-ui/core";
import React from "react";

export const DimensionDetail = ({ classes, title, description, summary }) => (
  <>
    <Typography className={classes.heading1}>
      {title}
      <Typography className={classes.subheading1} variant="body2" component="span">
        {description}
      </Typography>
    </Typography>
    <Typography className={classes.subheading1}>{summary}</Typography>
  </>
);
