var definitions = {
  analytical_capacity_TTT:
    "[Name] is likely to be able to solve complex problems quickly and accurately. They may be suited to roles where there are many decisions to be made and different factors to consider, particularly where there is incomplete or ambiguous information.",
  analytical_capacity_TUT:
    "[Name] is likely to have some skills in this area, in particular they tend to be drawn to complex problems.  They take a cautious, precise approach and risk delaying decisions.  [Name] may be suited to roles where there are complex problems to solve and accuracy is more important than speed.",
  analytical_capacity_TTU:
    "[Name] is likely to have some skills in this area, in particular dealing with complex problems and making decisions at speed.  They risk making decisions too quickly, and could make mistakes, however.  [Name] could be suited to roles where there are complex problems with ambiguous or incomplete information, where speed is more important than accuracy.",
  analytical_capacity_TUU:
    "[Name] is likely to have some skills in this area, in particular they tend to be drawn to complex problems, but they risk taking too long analysing the issues, and may not make decisions. They may be suited to roles where there are complex problems to consider and debate, where there is less pressure to make any decisions. ",
  analytical_capacity_UTT:
    "[Name] is likely to have some skills in this area, in particular they tend to enjoy solving problems where there are clear rules and solutions. [Name] makes decisions quickly and accurately, but tends to avoid dealing with complex problems.  They may be suited to roles where there are straightforward issues to deal with. ",
  analytical_capacity_UUT:
    "[Name] is likely to have some skills in this area, in particular they tend to enjoy solving problems where there are clear rules and solutions.  [Name] takes a cautious, precise approach that could risk delaying decisions.  They may be suited to roles where there are detailed problems to solve and accuracy is more important than speed.",
  analytical_capacity_UUU:
    "[Name] may struggle to make accurate and quick decisions, particularly where there is new, ambiguous or incomplete information.  [Name] may be suited to roles that require more routine tasks, or those where they can draw upon prior knowledge and experience.",
  analytical_capacity_UTU:
    "[Name] is likely to have some skills in this area, in particular they tend to enjoy solving problems where there are clear rules and solutions. [Name] risks making decisions too quickly however, and could make mistakes. They may be suited to roles where there are quick, straightforward problems to solve.",

  learning_agility_TTT:
    "[Name] is likely to enjoy learning, seek and adapt to feedback, and respond positively to new situations  They can be expected to seek advice, try new techniques to address issues, and recognise when one approach isn't working.  [Name] could be suited to roles where there is frequent change, uncertainty and lots of variety. They are also likely to do well in a role where they have little or no technical experience.",
  learning_agility_TUT:
    "[Name] is likely to have some skills in this area, in particular they tend to respond positively to feedback, and are likely to be self-aware. They learn quickly, but might find it hard to adapt to change.  [Name] could be suited to roles where there are plenty of opportunities for self-development, but also some level of stability.",
  learning_agility_TTU:
    "[Name] is likely to have some skills in this area, in particular they respond positively to feedback, and adapt quickly.  However, they risk relying on one learning style, for example learning by rote, or by trial and error, which could delay their learning. [Name] could be suited to roles where there is frequent change.",
  learning_agility_TUU:
    "[Name] is likely to have some skills in this area, in particular they tend to respond positively to feedback, and are likely to be self-aware. [Name] might find it difficult to adapt however, relying on default styles or approaches. They could be suited to roles where there are clear instructions and opportunities for feedback.",
  learning_agility_UTT:
    "[Name] is likely to have some skills in this area, in particular they learn quickly in new situations and are quite self-sufficient.  [Name] recognises when one approach isn't working, and is willing to adapt, but they may not respond favourably to feedback or advice.  They could be suited to roles where a flexible, independent approach is required.",
  learning_agility_UUT:
    "[Name] is likely to have some skills in this area, in particular they learn quickly in new situations and can be quite self-sufficient.  However, they may not adapt to external change, or respond favourably to feedback or advice.  [Name] could be suited to roles where there are new challenges that require an independent approach.",
  learning_agility_UUU:
    "[Name] tends to prefer predictable environments and they could struggle to adapt or learn new techniques quickly.  [Name] may be resistant to feedback, or have low-self-awareness.  They could be suited to roles where there is a clear process, within their current profession, or where they can apply their existing skills and style.",
  learning_agility_UTU: "",

  resilience_TTT:
    "[Name] is likely to be able manage their emotions, set and achieve challenging goals, and be self-confident.  They may be suited to roles where there are many challenges, potential setbacks, and rejection.  [Name] is likely to have the confidence to try different roles, and their resilience will help them keep trying despite obstacles.",
  resilience_TUT:
    "[Name] is likely to have some skills in this area, in particular they appear to be able to control their emotions and remain optimistic and confident.  They seem to prefer not to commit to challenging goals, and may be suited to roles where there are achievable targets.",
  resilience_TTU:
    "[Name] is likely to have some skills in this area, in particular they appear to have good emotional control, and the determination to persevere despite setbacks.  [Name] may currently be experiencing low self-confidence, which could be preventing them from taking risks.  In the short-term they may be suited to roles where they can build self-confidence and then take on roles where there are more challenges and stretched goals.",
  resilience_TUU:
    "[Name] is likely to have some skills in this area, in particular they appear to be able to control their emotions and remain optimistic.  They may currently be experiencing low self-confidence, and have possibly given up on their goals. [Name] may be suited to roles where there are plenty of opportunities for praise and reassurance to keep them motivated.",
  resilience_UTT:
    "[Name] is likely to have some skills in this area, in particular they enjoy setting and achieving challenging goals.  They are self-confident, but may find it difficult to manage their emotions when things go wrong. [Name] is likely to have the confidence to try different roles as long as there aren't too many potential setbacks.",
  resilience_UUT:
    "[Name] is likely to have some skills in this area, in particular they demonstrate self-confidence and high self-esteem.  They might find it difficult to remain calm under pressure, or stick to their goals when there are setbacks.  [Name] may be suited to roles where there are achievable targets and little pressure.",
  resilience_UUU:
    "[Name] may find it difficult to deal with pressure, and could be avoiding setting themselves goals, or addressing conflict.  They may currently be experiencing low self-confidence, and therefore could be feeling pessimistic about the future. Resilience is something that can be developed, but in the meantime, [Name] may be suited to roles where there are achievable targets and little pressure.",
  resilience_UTU:
    "[Name] is likely to have some skills in this area, in particular they enjoy setting and achieving challenging goals.  They may find it difficult to deal with pressure, and could be currently experiencing low self-confidence.  In the short-term [Name] may be suited to roles where they can build their self-confidence in a low pressure environment.",

  SPC_underskilled_definition:
    "[Name]'s results suggest that this is an area for development. They may struggle to solve complex problems, where there are a number of different variables.  [Name] may miss opportunities to scan across different issues and identify patterns, root causes and long-term consequences.  They can be expected to work better where there is certainty, complete information, and straightforward decisions to be made.",
  SPC_talented_definition:
    "[Name]'s results suggest that this is an area of strength for them.  They are likely to be able to solve complex problems, where there are a number of different variables.  [Name] may be good at scanning across different issues and identifying patterns, root causes, and long-term consequences.  They can be expected to work well with ambiguity, and may be effective at a strategic level.",
  SPC_overuse_definition:
    "Due to their high score in this area, [Name] should watch out for over-use of this skill.  They risk over-complicating issues that require just a pragmatic solution.  [Name] may also find it difficult to explain complex issues to other people, assuming that the issue is obvious.  ",

  DMA_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They are likely to be slower at analysing information, and take time to make decisions. [Name] can be expected to work well in an environment where there is little pressure or need to make decisions.",
  DMA_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They are likely to be able to quickly analyse information, and make fast decisions.  [Name] can be expected to work well in a fast-paced environment, where timely decisions are critical, where there is not enough time to craft the perfect solution. ",
  DMA_overuse_definition:
    "Due to their high score in this are, [Name] should watch out for over-use of this skill.  They risk making decisions too quickly, and missing the opportunity to explore alternatives.  [Name] also may neglect to involve others in decision-making, or seek their buy-in before moving forward.",

  PSA_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They may overlook details and make errors when analysing information.  [Name] may misjudge issues and make mistakes when solving problems. They can be expected to work better in roles where intuition and experience are more important than precision. ",
  PSA_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They are likely to be able to assimilate and analyse information carefully and solve problems accurately.  [Name] can be expected to work well in environments where precision is important, and where it is critical to take the time to find the best solution.",
  PSA_overuse_definition:
    "Due to their high score in this are, [Name] should watch out for over-use of this skill.  They risk focusing so much on the accuracy of the solution, that they could neglect other issues, such as speed or intuition.  [Name] may also find it difficult to change their mind once they have reached a conclusion on an issue.",

  RTF_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They may miss opportunities to seek out feedback and advice from others. [Name] might not fully reflect on their mistakes, and could lack insight about their own development needs.  As a result, they might take a while to develop themself.",
  RTF_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They are likely to seek out feedback and advice from others in order to improve their self-awareness and to help them learn.  [Name] can be expected to be open to others' input, and be interested in their own development.",
  RTF_overuse_definition:
    "Due to their high score in this area, [Name] should watch out for over-use of this skill.  They risk over-analysing feedback, and dwelling too long on mistakes.  [Name] could become reliant on others for advice, take feedback too literally and be inconstant in their approach as they try to respond to every suggestion.",

  ADY_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They may resist change, and tend to prefer predictability and stability.  [Name] could take a while to adapt to new situations, insisting on taking the same approach.  They are likely to work well in environments where there is little change, where a traditional approach is required.",
  ADY_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They are likely to enjoy variety and seek out opportunities for change.  [Name] can be expected to adapt effectively in new situations, and look for new ways to do things better. They are likely to work well in constantly changing environments",
  ADY_overuse_definition:
    "Due to [Name]'s high score in this area, they should watch out for over-use of this skill.  They risk getting bored in environments where there is insufficient variety, and they may avoid routine tasks.  [Name] may end up looking for improvements where none are needed, and focus too much on starting initiatives without completing them.",

  RLS_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They may take a while to learn new things, or rely on one style of learning  For example, they could rely on learning through trial and error, or prefer to learn by rote or following instructions.  [Name] is likely to work better in environments where they can use their existing skills as opposed to needing to learn new ones.",
  RLS_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They are likely to learn new things quickly, using a range of techniques.  [Name] probably has a good memory, but knows when a new approach is needed that requires them to think through the options, or experiment with different approaches.  They are likely to enjoy learning, and respond well to new challenges .",
  RLS_overuse_definition:
    "Due to their high score in this area, [Name] should watch out for over-use of this skill.  They risk constantly looking for learning opportunities without fully developing existing skills.  [Name] may also expect others to learn as quickly as they do, getting frustrated if they don't pick up new skills readily.",

  CONT_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They may find it difficult to remain calm and control their emotions when under pressure.  [Name] tends to take a pessimistic view, assuming that things will go wrong.  They could struggle to read others' emotions and is likely to avoid interpersonal conflict.  [Name] can be expected to work better in a relaxed environment where there are limited pressures.",
  CONT_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They are likely to be able to manage their own emotions when under pressure, remain optimistic, and have a high degree of emotional intelligence.  [Name] can be expected to work well in challenging situations, particularly where there are frequent setbacks or interpersonal conflict.",
  CONT_overuse_definition:
    "Due to their high score in this area, [Name] should watch out for over-use of this skill.  They risk being too optimistic, calm and controlled, missing opportunities to show their true emotions.  Due to their ability to cope well with stress, they may not recognise how pressure negatively affects others.",

  COMM_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They are unlikely to be motivated by challenging goals, and they tend to avoid taking personal risks.  [Name] may find that they give up when things get difficult and they risk letting others down by not keeping to their commitments when under pressure.  They can be expected to work better in environments where there are realistic goals and standards to meet.",
  COMM_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They enjoy setting challenging goals and are likely to work hard to achieve them.  [Name] tends to be determined, and strives to keep their promises.  They are willing to take some calculated risks in order to stretch themself and meet their commitments.  [Name] can be expected to work well in competitive environments where there are opportunities to set and achieve tangible goals.",
  COMM_overuse_definition:
    "Due to their high score in this area, [Name] should watch out for over-use of this skill.  They may take too many risks and over-stretch themself and others.  [Name] could put too much pressure on themself and others to deliver to unrealistic deadlines, and feel disappointed when this isn't achieved.",

  CONF_underskilled_definition:
    "[Name]'s results suggest that this is an area for development.  They tend to assume that they will fail, and they currently lack confidence in their skills.  [Name] may not think that they deserves success, and they probably have low self-esteem at the moment.  Their confidence could be improved by working in environments where they will be able to enjoy success and receive praise for their accomplishments ",
  CONF_talented_definition:
    "[Name]'s results suggest that this is an area of strength.  They have the belief that they can succeed, or acquire the skills necessary to succeed.  [Name] is likely to be self-confident and have high self-esteem, recognising that they deserve success.  They can be expected to work well in positions of responsibility or where it is important to influence, negotiate, or persuade. ",
  CONF_overuse_definition:
    "Due to their high score in this area, [Name] should watch out for over-use of this skill.  They risk being over-confident and could find it difficult to admit their faults.  They may have an inflated opinion of themself or their ideas, and they may spend more time trying to convince others than listening to their opinion."
};

export function getDefinition(definition, score, name) {
  var t = definitions[definition + "_" + score];
  if (t) {
    return t.split("[Name]").join(name);
  } else {
    return "";
  }
}

export function getSkill(skill, score, name) {
  var t = definitions[skill + "_" + score + "_definition"];

  if (t) {
    return t.split("[Name]").join(name);
  } else {
    return "";
  }
}
