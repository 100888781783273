import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
export const LoadingProgress = ({ text = "Loading..." }) => (
  <Grid>
    <Grid container justify="center" item xs>
      <CircularProgress size={25} thickness={4} />
      <span style={{ paddingLeft: "1em" }}>{text}</span>
    </Grid>
  </Grid>
);
