import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useEffect, useState } from "react";

const SkillRow = ({ skill, proficiency, description }) => {
  var str = "beginner";

  if (proficiency === 2) {
    str = "proficient";
  }
  if (proficiency === 3) {
    str = "experienced";
  }
  if (proficiency === 4) {
    str = "expert";
  }
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <ExpansionPanel
          style={{
            padding: "0",
            boxShadow: "none",
          }}
        >
          <ExpansionPanelSummary
            aria-controls="panel1a-content"
            id="panel2a-header"
            style={{ padding: "0" }}
          >
            <Typography style={{ borderBottom: "1px dashed #000" }}>
              {skill}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: "0" }}>
            {description}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </TableCell>
      <TableCell align="left">
        {str} <LinearProgress variant="determinate" value={proficiency*25} style={{marginTop:10}}/>{" "}
      </TableCell>
    </TableRow>
  );
};

const SkillProficiencyTable = ({ skills }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Skill</TableCell>
        <TableCell align="right">Expertise</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {skills.map((skill) => (
        <SkillRow key={"_" + skill.skill} {...skill} />
      ))}
    </TableBody>
  </Table>
);

const SkillsExpansionPanel = ({ job, defaultExpanded }) => {
  const [isExpanded, toggleExpansion] = useState(false);

  useEffect(() => {
    toggleExpansion(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <ExpansionPanel
      expanded={isExpanded}
      onChange={(_, isExpanded) => toggleExpansion(isExpanded)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {
          ((job.title.includes("Manually Added") || job.title.includes("Resource Added Skills")) ? (
            <Typography>{job.title}</Typography>
          ) : (
            <Typography>Skill Summary for {job.title}</Typography>
          )
          )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <SkillProficiencyTable skills={job.skills} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export const SkillsBackpackSummary = ({
  skillsInfo,
  isOnPrintMode,
  groupSkillsBackpack,
}) => {
  if (!skillsInfo) {
    return null;
  }
  if (groupSkillsBackpack) {
    return (
      <div style={{ paddingLeft: "1em" }}>
        {skillsInfo.map((backpack) => {
          const proficientLevels = Array.from(
            new Set(backpack.skills.map((skill) => skill.proficiency))
          );
          const groupedSkills = proficientLevels.reduce(
            (acl, curr) =>
              Object.assign({}, acl, {
                [curr]: backpack.skills
                  .filter((skill) => skill.proficiency === curr)
                  .map((a) => a.skill),
              }),
            {}
          );
          return (
            <>
              <p>
                Skills Backpack for <b>{backpack.title}</b>
              </p>
              {Object.keys(groupedSkills).map((proficiency) => (
                <p key={proficiency}>
                  {proficiency} in: {groupedSkills[proficiency].join(", ")}
                </p>
              ))}
            </>
          );
        })}
      </div>
    );
  }
  return (
    <>
      {skillsInfo.map((job, i) => (
        <SkillsExpansionPanel
          job={job}
          key={job.title + "_" + i}
          defaultExpanded={isOnPrintMode}
        />
      ))}
    </>
  );
};
