import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import {
  forgotPassword as forgotPasswordAction,
  confirmForgotPassword as confirmForgotPasswordAction
} from "../../actions/auth_actions";



const styles = theme => ({
  root: {},
  margin: {
    margin: 20
  },
  textField: {
    width: 360
  },
  main: {
    marginTop: 60,
    width: 400,
    margin: "auto",
    paddingTop: 20,
    paddingBottom: 20
  },
  button: {
    width: 360
  },
  progress: {
    //height: 2
  },
  progressCont: {
    height: 5
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10
  },
  snack: {
    marginTop: 10
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
});

class Forget extends Component {
  state = {
    email: "",
    code : "",
    password: "",
    passwordcon: "",
    open: false,
    vertical: "top",
    horizontal: "center",
    error: "",
    loading: false,
    forgotPasswordCode: ""
  };

  constructor(props) {
    super(props);
    this.reset = this.reset.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage !== prevProps.errorMessage &&
      this.props.errorMessage !== ""
    ) {
      this.setState({
        open: true,
        error: this.props.errorMessage,
        loading: false
      });
    }
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  reset(e) {
    e.preventDefault();
    const { forgotPasswordCode, history } = this.props;

    let username = this.state.email.trim();
    let code = this.state.code.trim();
    let newPassword = this.state.password;
    if (
      typeof forgotPasswordCode == "undefined" ||
      forgotPasswordCode === false
    ) {
      this.props.forgotPasswordAction({ username }, history);
    } else {
      if (this.state.password === this.state.passwordcon){
        this.props.confirmForgotPasswordAction({username, code, newPassword}, history);
      } else {
        alert("Passwords should match!")
      }
      
    }
  }

  render() {
    const { horizontal, vertical, open, error, loading } = this.state;
    const { classes, forgotPasswordCode } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.progressCont}>
          {loading && <LinearProgress className={classes.progress} />}
        </div>
        <Header />
        <Paper className={classes.main}>
          <Typography variant="h6" className={classes.marginLabel}>
            Reset your password
          </Typography>
          {!forgotPasswordCode && (
            <form className={classes.container} onSubmit={this.reset}>
              <TextField
                id="outlined-email-input"
                label="Email"
                className={classNames(classes.margin, classes.textField)}
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                autoFocus
                required
                onChange={this.handleChange("email")}
              />

              <Button
                variant="contained"
                className={classNames(classes.margin, classes.button)}
                size="large"
                color="primary"
                type="submit"
              >
                Reset
              </Button>
            </form>
          )}
          {forgotPasswordCode && (
            <form className={classes.container} onSubmit={this.reset}>
              <TextField
                id="outlined-code-input"
                label="Verification Code"
                className={classNames(classes.margin, classes.textField)}
                type="text"
                name="code"
                autoComplete="code"
                margin="normal"
                variant="outlined"
                autoFocus
                required
                onChange={this.handleChange("code")}
              />
              <TextField
                id="password"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                autocomplete="new-password"
                type= "password"
                label="New Password"
                value={this.state.password}
                onChange={this.handleChange("password")}
                
                required
              />
              <TextField
                id="passwordcon"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type="password"
                autocomplete="new-password"
                label="Confirm Password"
                value={this.state.passwordcon}
                onChange={this.handleChange("passwordcon")} 
                required
              />


              <Button
                variant="contained"
                className={classNames(classes.margin, classes.button)}
                size="large"
                color="primary"
                type="submit"
              >
                Send
              </Button>
            </form>
          )}
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!open}
          className={classes.snack}
          onClose={this.handleClose}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
        >
          <SnackbarContent
            className={classNames(classes.error)}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{error}</span>}
          />
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    forgotPasswordCode: state.auth.forgotPasswordCode,
    errorMessage: state.auth.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      { forgotPasswordAction, confirmForgotPasswordAction },
      dispatch
    ),
    dispatch
  };
}

Forget = connect(
  mapStateToProps,
  mapDispatchToProps
)(Forget);

export default withStyles(styles)(Forget);
