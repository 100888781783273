import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const styles = {
  card: {
    minWidth: 275
  },
  cardContent: {
    minWidth: 275,
    height: 200,
    width: "100%"
  },
  labelsContent: {},
  labelItem: {
    marginTop: "2rem",
    textAlign: "left",
    fontSize: 12,
    paddingLeft: 10,
    fontWeight: "bold"
  }
};
function AssessmentCard(props) {
  const { classes, data, dataKey, hasDoneMultipleAssessments } = props;
  const domain = [0, 100];

  return (
    <Grid container spacing={0}>
      <Grid item xs={2} className={classes.labelsContent}>
        {data.map(item => {
          return (
            <p className={classes.labelItem} key={item.name}>
              {item[dataKey]}
            </p>
          );
        })}
      </Grid>
      <Grid item xs={10} className={classes.cardContent}>
        <ResponsiveContainer width={"100%"}>
          <BarChart
            data={data}
            layout="vertical"
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5
            }}
            barSize={10}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" domain={domain} />
            <YAxis
              type="category"
              dataKey={dataKey}
              tickFormatter={() => {
                return "";
              }}
            />
            {/*<YAxis
                  yAxisId="right"
                  orientation="right"
                  reversed
                  domain={domain}
                  ticks={ticksY}
                  tickCount={100}
                  tickFormatter={toPercent}
                />*/}
            <Tooltip />
            <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: 15 }} />
            <Bar name="Latest Assessment" dataKey="latest" fill="#ff6505" />
            {hasDoneMultipleAssessments ? (
              <Bar name="Initial Assessment" dataKey="first" fill="#696969" />
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
}

AssessmentCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired
};

export default withStyles(styles)(AssessmentCard);
