import { withStyles , Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import { Radar, RadarChart,Tooltip, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import _ from "lodash";
import { withRouter } from "react-router-dom";


const styles = (theme) => ({  
  bodyParagraph: {
    marginTop: 15,
    // marginLeft: 10,
    marginBottom: 15
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer"
  },
  m : {
    marginRight: 30
  },
  alignCenter: {
    textAlign: "center"
  },
  marginTop15: {
    marginTop: 15
  },
  overflow: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: "100%"
  },
  radarContainer: {
    height: 220,
    position: "relative",
    minWidth: 280,
    maxWidth: 450,
    left: "20%",
    zIndex: 1
  }
});


export const StylesRadar = withRouter(withStyles(styles)(({ classes, data, history}) => { 
  
  let [difference, setDifference] = React.useState("Please click chart");
  let [yourValue, setYourValue] = React.useState("Please click chart");
  let [jobValue, setJobValue] = React.useState("Please click chart");
  // let [missionValue, setMissionValue] = React.useState("");
  // let [missionDescription, setMissionDescription] = React.useState("");
  let [textColor, setTextColor] = React.useState("#000000");

  const clickEvent = (e) => {
    let selected = false;
    if (e && e.activeLabel){
      _.each(data, (d) => {
        if (d.label === e.activeLabel){
          selected = d;
        }
      });
      if (selected){
        difference = (selected.You - selected.Vacancy);
        let differenceNumber = difference;
        difference = difference.toFixed(1) + "%";
        setDifference(difference);
        setYourValue(`${selected.label} : ${selected.You}%`)
        setJobValue(`${selected.label} : ${selected.Vacancy}%`)

        if (differenceNumber < 0){
          // setMissionDescription(`Click here for ${selected.label} Missions`)
          // setMissionValue(selected.label);
          setTextColor("#E16618")
        } else {
          // setMissionDescription(``)
          // setMissionValue("");
          setTextColor("#87D190")
        }

      }
    }
  }


  return (
    <Grid container className={classes.overflow}>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.radarContainer}>
        <RadarChart onClick={clickEvent} cx={150} cy={100} outerRadius={75} width={350} height={200} data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="label"   style={{
          fontSize: '10px',
          fontFamily: 'Roboto'
      }}/>
          <PolarRadiusAxis angle={90} domain={[0, 100]} />
          <Radar name="You" dataKey="You" stroke="#000000" fill="#DDE0E5" fillOpacity={1.0} />
          <Radar name="Vacancy" dataKey="Vacancy" stroke="#000000" fill="#FF9800" fillOpacity={0.4} />
          <Tooltip />
        </RadarChart>
      </div>
      </Grid>
        {/* <Grid item xs={12} sm={12} md={4}>
            <Box display={{ xs: 'none', sm:'none', md: 'block' }}>
          <Typography className=""> Your Profile </Typography>
          <Typography variant="body2" className={classes.bodyParagraph}> {yourValue} </Typography>
          <Typography> Job Requirement</Typography>
          <Typography variant="body2" className={classes.bodyParagraph}> {jobValue} </Typography>
          <Typography> Styles Gap </Typography>
          <Typography variant="body2" style={{"color": textColor}} className={classes.bodyParagraph}> {difference} </Typography>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <Box className={classes.alignCenter}>
            <Typography className={classes.marginTop15}> Your Profile </Typography>
            <Typography variant="body2" className={classes.bodyParagraph}> {yourValue} </Typography>
            <Typography> Job Requirement</Typography>
            <Typography variant="body2" className={classes.bodyParagraph}> {jobValue} </Typography>
            <Typography> Styles Gap </Typography>
            <Typography variant="body2" style={{"color": textColor}}  className={classes.bodyParagraph}> {difference} </Typography>
          </Box>
          <Grid container justify="center"  spacing={4}>
            <Grid style={{height: 100}} item xs={12}></Grid>
          </Grid>
        </Grid>
    </Grid>
  );
}));
