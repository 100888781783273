const prod = {
  env:"prod",
  AWS_REGION: "eu-west-2",
  AWS_COGNITO_IDENTITY_POOL_ID:
    "eu-west-2:dc4a58d7-fcd8-4642-99c2-9d483fe55e4a",
  AWS_COGNITO_USER_POOL_ID: "eu-west-2_hW0LZOp18",
  AWS_COGNITO_CLIENT_ID: "6n9qd4lp66f4qb6o2e1hcvtlc1",
  API_ENDPOINT: "https://c56bs2hhug.execute-api.eu-west-2.amazonaws.com/prod",
  API_NAME: "StaffportalAPIPROD",
  PORTAL_BUCKET: "staffportal-storage-prod",
  DEFAULT_CACHE_TIME: 300000, //ms = 5min
  DEFAULT_SESSIONCHECK_TIME: 300000, //ms = 5min
  currencySign: "£",
  currency: "GBP",
  distanceName: "miles",
  STORAGE_CLOUDFRONT_URL : "https://db444pytybfjs.cloudfront.net",
  signupURL: "https://jobs.skillsminer.ai/signup",
};

const prodIE = {
  env:"prod",
  AWS_REGION: "eu-west-1",
  AWS_COGNITO_IDENTITY_POOL_ID:
    "eu-west-1:dc3a1d77-0622-45a6-a84f-0416b6a19e2d",
  AWS_COGNITO_USER_POOL_ID: "eu-west-1_2VOQK9PvL", //
  AWS_COGNITO_CLIENT_ID: "2l4smddubq1jlpp79mca871l38",
  API_ENDPOINT: "https://fbu6ouj195.execute-api.eu-west-1.amazonaws.com/prod",
  API_NAME: "StaffportalAPIPROD",
  PORTAL_BUCKET: "staffportal-storage-prod-ireland",
  DEFAULT_CACHE_TIME: 300000, //ms = 5min
  DEFAULT_SESSIONCHECK_TIME: 300000, //ms = 5min
  currencySign: "€",
  currency: "EUR",
  distanceName: "km",
  STORAGE_CLOUDFRONT_URL : "https://d1kg6o071dnr8x.cloudfront.net",
};

var c = prod;

if (process.env.REACT_APP_REGION === "eu-west-1") {
  c = prodIE;
}

export default c;
