import { Logger } from "aws-amplify";
import _ from "lodash";
import {
  SA_FETCH_ORGS,
  SA_SELECT_ORG,
  SA_ERROR,
  SA_LOADING,
  SA_FETCH_ORGADMINS,
  SA_FETCH_PARTNERS,
  SA_FETCH_FLEXIBLE,
  SA_FETCH_FLEXIBLE_USER_DETAILS,
  SA_PROVIDER_FETCH_DETAILS,
  SA_FETCH_DUMMYKWS,
  SA_FETCH_CASELOAD,
  SA_FETCH_KEYWORKERS,
  SA_FETCH_EMPLOYERS,
  SA_FETCH_HRMANAGERS,
  SA_FETCH_LINEMANAGERS,
  SA_DELETE,
  SA_DISPLAY_TOAST,
  SA_UNFULFILLED_ORDERS,
  SA_MONSTER_KEYS
} from "../actions/types";

const logger = new Logger("ADMIN.REDUCER", "DEBUG");

export default function (state = {}, action) {
  let selected;
  switch (action.type) {
    case SA_FETCH_ORGS: {
      selected = null;

      if (state.selectedOrg) {
        selected = action.data.find(
          (element) => element.sort === state.selectedOrg.sort
        );
        if (state.selectedOrg.caseload) {
          selected.caseload = state.selectedOrg.caseload;
        }
      }

      var returnProps = {
        ...state,
        orgs: action.data,
        selectedOrg: selected,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SA_FETCH_ORGADMINS: {
      var selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.orgadmins = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case SA_FETCH_PARTNERS: {
      let selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.partners = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case SA_FETCH_FLEXIBLE_USER_DETAILS: {
      const returnProps = {
        ...state,
        userDetailsFlexible:  action.data
      }
      return returnProps;
    }

    case SA_PROVIDER_FETCH_DETAILS: {

      const returnProps = {
        ...state,
        SAProviderDetails:  action.data
      }
      return returnProps;
    }

    case SA_FETCH_FLEXIBLE: {
      let selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.flexible = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case SA_FETCH_KEYWORKERS: {
      let selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.keyworkers = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case SA_FETCH_EMPLOYERS: {
      let selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.employers = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case SA_FETCH_HRMANAGERS: {
      let selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.hrmanager = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    
    case SA_FETCH_LINEMANAGERS: {
      let selectedOrg = state.selectedOrg || {};

      action.data.forEach((item) => {
        item.name = findAttribute(item, "name");
        item.email = findAttribute(item, "email");
      });

      selectedOrg.linemanager = action.data;
      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case SA_SELECT_ORG: {
      selected = null;

      if (action.data) {
        selected = action.data;
      } else {
        if (action.saveddata) {
          selected = action.saveddata;
        }
      }

      const returnProps = {
        ...state,
        selectedOrg: selected,
        loading: false,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SA_ERROR: {
      var error = null;

      if (action.error) {
        error = action.error;
      }

      const returnProps = {
        ...state,
        error: error,
        loading: false,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SA_LOADING: {
      const { loading = false } = action;

      const returnProps = {
        ...state,
        loading,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SA_FETCH_DUMMYKWS: {
      const { data = {} } = action;

      selectedOrg = state.selectedOrg || {};

      selectedOrg.dummyKW = data;

      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SA_DELETE: {
      let data = action.data;
      return {
        ...state,
        displayToastDelete: data
      }
    }
    case SA_DISPLAY_TOAST: {
      return {
        ...state,
        displayToastDelete: false
      }
    }
    case SA_FETCH_CASELOAD: {
      const { data = {} } = action;

      selectedOrg = state.selectedOrg || {};

      selectedOrg.caseload = data;

      const returnProps = {
        ...state,
        selectedOrg,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case SA_UNFULFILLED_ORDERS: {
      return {
        ...state,
        unfulfilledOrders: action.data ? action.data : [],
      };
    }

    default:
      return state;
  }
}

function findAttribute(row, name) {
  if (row && row.Attributes) {
    var elem = _.find(row.Attributes, (item) => {
      if (item.Name === name) {
        return true;
      }
    });
  }

  if (elem) {
    return elem.Value;
  } else {
    return "";
  }
}
