import { FETCH_COINS, RESET_COINS } from "../actions/types";
import { Logger } from "aws-amplify";

/* eslint-disable */
const logger = new Logger("REWARD.REDUCER", "DEBUG");
/* eslint-enable */

export const rewardReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COINS: {
      var currentLevel = 1;
      var toNextLevel = 0;
      var levelRange = 50;
      const levels = [
        50,
        100,
        200,
        400,
        800,
        1600,
        3200,
        6400,
        12800,
        25600,
        51200,
        102400,
        204800,
        409600,
        819200,
        1638400,
        3276800,
        6553600,
        13107200,
        26214400,
      ];

      let coins = 0;
      let totalEarnedCoins = 0;
      if (action.data) {
        coins = action.data.coins;
        totalEarnedCoins = Object.entries(action.data).map(([k,v]) => ["coins", "userID", "sort"].includes(k) ? 0 : v).reduce((partialSum, a) => partialSum + a, 0);
      }

      var newCoins = 0;
      var setcoins = 1;

      if (parseInt(coins) >= 0) {
        setcoins = coins;
      }

      if (state.coins >= 0) {
        newCoins = setcoins - state.coins;
      }

      for (var [i, l] of levels.entries()) {
        if (totalEarnedCoins <= l) {
          currentLevel = i + 1;
          toNextLevel = l - totalEarnedCoins;
          if (i - 1 >= 0) {
            levelRange = l - levels[i - 1];
          }

          break;
        }
      }

      const returnProps = {
        ...state,
        coins: setcoins,
        newCoins,
        level: currentLevel,
        toNextLevel,
        levelRange,
        totalEarnedCoins
      };
      return returnProps;
    }

    case RESET_COINS: {
      const returnProps = {
        ...state,
        newCoins: 0,
      };
      return returnProps;
    }

    default:
      return state;
  }
};
