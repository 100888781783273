import { Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectContract } from "../../actions/portal_actions";
import { LoadingProgress } from "../Common/LoadingProgress";

export const ContractSwitcherMenuComponent = ({
  portaldetails,
  selectContract,
  onChange = () => {},
  textColor = "white"
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  if (!portaldetails || !portaldetails.orgcontracts) {
    return <LoadingProgress text="Loading Contracts..." />;
  }

  const {
    user: { role, contracts: userContracts = [] },
    contracts = [],
    orgcontracts: organizationContracts = []
  } = portaldetails;

  const availableUserContracts =
    role === "keyworker" ? userContracts : contracts;

  const contractOptions = availableUserContracts.map(keyworkerContractId => ({
    label: portaldetails.orgcontracts.find(
      ({ contractID }) => contractID === keyworkerContractId
    ).name,
    value: keyworkerContractId
  }));

  let currentContract = "Select Contract";
  if (portaldetails.selectedContract && portaldetails.selectedContract.contractID){
     currentContract = portaldetails.selectedContract
      ? contractOptions.find(
          ({ value }) => value === portaldetails.selectedContract.contractID
        ).label
      : "Select Contract";
  }

  return (
    <>
      <Button
        disabled={availableUserContracts.length <= 0}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        style={{ color: textColor, textTransform: "none" }}
      >
        {currentContract}
        <ArrowDropDownRoundedIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {contractOptions.map(({ label, value }) => (
          <MenuItem
            key={value}
            onClick={() => {
              selectContract(
                organizationContracts.find(
                  ({ contractID }) => contractID === value
                )
              );
              onChange(value);
              setAnchorEl(null);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

function mapStateToProps(state) {
  return {
    portaldetails: state.portaldetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        selectContract
      },
      dispatch
    ),
    dispatch
  };
}

export const ContractSwitcherMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractSwitcherMenuComponent);
