import {
  Button,
  IconButton,
  Divider,
  Grid,
  Icon,
  Typography,
  withStyles,
  Link
} from "@material-ui/core";
import React from "react";
import  Backdrop from "@material-ui/core/Backdrop";
import { useSnackbar } from 'notistack';
import { MatchBreakdown } from "./MatchBreakdown";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getIndeedDescription, getReedDescription } from "../../../actions/job_actions";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  contentGrid: {
    padding: "1.5em",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    background: "white",
    position:"absolute"
  },
  backdrop: {
    zIndex: 10001
  },
  promotedChip: {
    fontSize: 14,
    backgroundColor: "#999",
    color: "white",
    paddingBottom: 2,
  },
  rightContainer: {
    width: 520,
    height: "100%",
    position: "absolute",
    right: "-1000px",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
    transition: "all 0.5s"
  },
  closeButton: {
    right: 10,
    position:"absolute",
    zIndex: 10
  },
  closeIcon: { color: "black" },
});

const JobListView = (props) => {
  let { onDismiss, job, candidate, classes, onInviteToVacancy} = props;
  const { enqueueSnackbar } = useSnackbar();
  let [showSkillMatch, setShowSkillMatch] = React.useState(false);
  let [loading, setLoading] = React.useState(false);  
  let ref = React.createRef(null);
  let [descriptionFull, setDescriptionFull] = React.useState(false);
  let [previousJobId, setPreviousJob] = React.useState(false);
  let openSkillGapJob = React.useState(true);

  const isVacancy = job.provider === "skillzMiner"
  let { jobDescription, provider, jobId } = job;

  if (previousJobId !== job.jobId){
    setPreviousJob(job.jobId);
    setDescriptionFull(false);
  }

  let [animate, setAnimate] = React.useState(false); 
  let [right, setRight] = React.useState("-1000px"); 

  if (!animate){
    setTimeout(() => {
      setAnimate(true);
      setRight("0px");
    }, 300)
  }




  return (
    <>
    {showSkillMatch ? (
      <Backdrop
        open={showSkillMatch}
        style={{zIndex: 100000}}
        classes={{root: classes.backdrop}}
      >
        <MatchBreakdown
          defaultType={"skills"}
          onClose={() => {
              setShowSkillMatch(false)
            }}
          job={job}
          candidate={candidate}
        />
      </Backdrop>
    ) : null}

    <Backdrop
     open={openSkillGapJob}
     classes={{root: classes.backdrop}}
    >
    <div style={{right: right}} className={classes.rightContainer}>
    <div style={{backgroundColor:"#ffffff"}}>
    <IconButton className={`${classes.closeButton} close-button`} onClick={() => {
      setRight("-1000px");
      setTimeout(() => {
        onDismiss();
      }, 250);
    }}>
      <CloseIcon className={classes.closeIcon} />
    </IconButton>
    <Grid container direction="column" className={classes.contentGrid}>
      <Grid item xs>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item sm={12} md={12}>
            <Typography variant={"h4"} style={{marginTop: 10}}> {job.jobTitle}</Typography>
            <Typography variant={"h5"} style={{marginTop: 10}}>{job.employerName}</Typography>
            <Grid
              container
              alignContent="flex-start"
              spacing={1}
              direction="column"
              alignItems="baseline"
            >
              <Grid>
                <Grid container alignItems="center" style={{marginTop: 15}}>
                  <Grid item>
                    <Icon color="primary">location_on</Icon>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.3em" }}>
                    {job.original_location}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" style={{marginTop: 5}}>
                  <Grid itemstyle={{ paddingLeft: "0.3em" }}>
                    <Icon
                      color="primary"
                      className="fas fa-money-bill"
                      style={{ textAlign: "center" }}
                    />
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.3em" }}>
                    {job.salary_type ? (
                      job.salary_type === "hourly" ? job.annual_salary_max + " per hour" :
                      job.salary_type === "daily" ? job.annual_salary_max + " per day" :
                      job.annual_salary_max + " per annum"
                    ) : "Salary Unknown"}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" style={{marginTop: 5}}>
                  <Grid item>
                    <Icon color="primary">timer</Icon>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.3em" }}>
                    {job.job_contract
                      .split("_")
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div 
              style={{}} 
              dangerouslySetInnerHTML={{ __html: descriptionFull ? descriptionFull : jobDescription  === "no description" ? "Follow the Apply link to see job description.  " : jobDescription  }} 
            />
          </Grid>
          {provider && !provider.includes("jooble") && (
            <Grid item xs={12} sm={12} style={{display:"flex", flexDirection:"row-reverse", paddingBlock:10}}>
              <Button
                disabled={loading || descriptionFull}
                color="primary"
                className={classes.button}
                size="small"
                onClick={async (e) => {
                  setLoading(true)
                  if(provider && provider.includes("indeed")){
                    setDescriptionFull(await props.getIndeedDescription(jobId));
                  }
                  else if(provider && (provider.includes("reed") || provider.includes("Reed"))){
                    setDescriptionFull(await props.getReedDescription(jobId));
                  }
                  else{
                    setDescriptionFull(jobDescription);
                  }
                  setLoading(false)
                }}
              >
                Show more 
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid> 
    <Grid container style={{paddingRight: 10, bottom:0, paddingTop: 5, background:"#ffffff", height: 50, position:"absolute", marginTop: 15}}>
          {(isVacancy && (
            <Grid item xs style={{ textAlign: "left" }}>
              <Chip label="Promoted Vacancy" className={classes.promotedChip}/>
            </Grid>
          ))}
          <Grid item xs style={{ textAlign: "right" }}>
            <Button color="primary" style={{marginRight: 10}}variant="contained" onClick={() => {
              setShowSkillMatch(true);
            }}>
                View Skill Gap
            </Button>
            <Button color="primary" style={{marginRight: 10}}variant="contained" onClick={() => {
              onInviteToVacancy(candidate.userID, job)
              enqueueSnackbar('The candidate has been notified of the selected job listing', { variant: 'success', autoHideDuration: 2000})
            }}>
                Invite candidate to apply
            </Button>
            <Link ref={ref} href={job.job_url} target="_blank" 
              onMouseDown={() => {
                if (job.provider === "indeed" && job.onmousedown) {
                  let arr = job.onmousedown.split(",");
                  let id = arr[1];
                  window.indeed_clk(ref.current, id.replace(")", "").replace(";", ""));
              }}}>
              <Button color="primary" variant="contained">
                Apply
              </Button>
            </Link>
          </Grid>
      </Grid>
      </div>
    </div> 
    </Backdrop>
    </>
  );
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({getIndeedDescription, getReedDescription}, dispatch),
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(JobListView));