import {
  PORTAL_DETAILS,
  PORTAL_SELECT_CONTRACT,
  FETCH_MISSIONS,
  FETCH_PORTAL_USERS,
  PORTAL_SELECT_KEYWORKER,
  PORTAL_DESELECT_KEYWORKER,
  PORTAL_SELECT_PROVIDER,
  PORTAL_LIST_CONTRACTS,
  PORTAL_CASELOAD,
  PORTAL_CASELOAD_ENDDATE,
  PORTAL_ORGADMIN_CASELOAD,
  FETCH_INTERVENTIONS,
  EMPTY_CANDIDATES,
  EMPTY_CANDIDATES_SELECTION,
  PORTAL_DASHBOARD_CASELOAD,
  PORTAL_ORGADMIN_CASELOAD_GLOBAL,
  PORTAL_ORGADMIN_CASELOAD_ENDDATE,
  PORTAL_SUPPLY_DATA,
  PORTAL_SUPPLY_LOW,
  PORTAL_ADZUNA_CATEGORY,
  PORTAL_ADZUNA_HISTOGRAM_CATEGORY,
  PORTAL_ADZUNA_HISTORICAL_CATEGORY,
  PORTAL_ADZUNA_REGION_DATA,
  SET_RECENTLY_VIEWED,
  PORTAL_LOCATION_LIST,
  UPDATE_BULK_UPLOAD_PROGRESS,
  SET_BULK_EMAIL_TEMPLATE,
  SET_ROLES,
  SET_DEPARTMENTS,
  SET_DEMOGRAPHICS,
  SET_LINEMANAGERS,
  SET_UNVERIFIED,
  CUSTOMER_CHECK
} from "./types";
import { Cache, Logger, Storage, API } from "aws-amplify";
import config from "../config";
import async from 'async';
import _, { includes, lte } from "lodash";
import { fetchCandidates } from "./candidates_actions";
import moment from "moment";
const logger = new Logger("PORTAL.ACTIONS", "INFO");

function mergeObjectsWithSum(obj, obj1){
  return _.mergeWith(obj, obj1, (objValue, srcValue, key) => {

    if (key === 'investigator' || key === 'facilitator' || 
      key === 'driver'|| key === 'doer' || 
      key === 'crafter' || key === 'creator'|| key === 'ADY' ||
      key === 'COMM' || key === 'CONF' || key === 'CONT' ||
      key === 'DMA' || key === 'PSA' || key === 'RLS' ||
      key === 'RTF' || key === 'SPC') {
        let current = parseInt(objValue);
        let merge   = parseInt(srcValue);
        if (isNaN(current)|| current === 0) {current = 0; return merge};
        if (isNaN(merge) || merge === 0) {merge = 0; return current;}

        return (current + merge) / 2 + "";
      }

      if (_.isNumber(objValue) || _.isNumber(srcValue)) {
      
        let src = (_.isNumber(srcValue)) ? srcValue: 0;
        let obj = (_.isNumber(objValue)) ? objValue: 0;
  
        if (src === 0){
          return parseInt(src) + parseInt(obj);
        } else {
          return parseInt(obj) + parseInt(src);
        }
      };
    
  });
};

export function getRecentlyViewed() {
  return async function(dispatch) {
    let data = await API.get(config.API_NAME, "/keyworker/recently_viewed");
    dispatch({type: SET_RECENTLY_VIEWED, data: data});
  }
}

export function getPortalDetails() {
  return async function(dispatch, getState) {
    logger.info("getPortalDetails(): ");
    const state = getState();
    try {
      let data = await API.get(config.API_NAME, "/user");
      //console.log(data);
      dispatch({ type: PORTAL_DETAILS, portaldetails: data });

      if (state.auth.role === "keyworker") {
        dispatch(fetchCandidates());
        dispatch(fetchInterventions());
        dispatch(getMissions());
        dispatch(getKeyworkerCaseload(state.auth.currentAuthUser.username));
      } else {
        if (state.auth.role === "hr-manager" || state.auth.role === "line-manager") {
          dispatch(fetchUnverifiedCounts());
        }
      if (state.auth.role !== "super-admin"){
        dispatch(checkCustomer());
      } 
      /*  if (state.auth.role === "org-admin" || state.auth.role === "partner") {
          dispatch(getOrgAdminCaseload());
        }*/
      }
      //dispatch(fetchInterventions());
      //get candidates AND interventions AND missions
    } catch (e) {
      logger.error("getPortalDetails():", e);
    }
  };
}


export function checkCustomer() {
  return async function(dispatch, getState) {
    logger.info("checkCustomer(): ");
    try {
      let data = await API.post(config.API_NAME, "/user/checkcustomer", {});
      //console.log(data);
      if(data){
        dispatch({ type: CUSTOMER_CHECK, isCustomer: true });
      }
    } catch (e) {
      logger.error("checkCustomer():", e);
    }
  };
}

export function getBillingConsole() {
  return async function(dispatch, getState) {
    logger.info("getbillingconsole(): ");
    try {
      let data = await API.post(config.API_NAME, "/user/getbillingconsole", {});
      //console.log(data);
      if(data && data.success){
        return data.session
      }
    } catch (e) {
      logger.error("getbillingconsole():", e);
    }
  };
}

export async function getCreditTotals() {
    const data = await API.get(config.API_NAME, "/orgadmin/credits/balance");
    return data;
}


export function fetchInterventions() {
  return async function(dispatch, getState) {
    logger.info("fetchInterventions(): ");
    try {
      const state = getState();

      let data;

      if (state.portaldetails.user.role === "keyworker") {
        data = await API.get(
          config.API_NAME,
          `/keyworker/${state.portaldetails.user.userID}/interventions/${state.portaldetails.selectedContract.contractID}`
        );
      } else {
        data = await API.get(config.API_NAME, "/orgadmin/interventions");
      }

      dispatch({ type: FETCH_INTERVENTIONS, interventions: data.data });
    } catch (e) {
      console.log("ERROR: actions.fetchInterventions():", e);
    }
  };
}

export function defaultCandidateSelection() {
  return async function(dispatch, getState) {
    let state = getState();
    try {
      if (state.auth.role === "org-admin") {
        dispatch({ type: EMPTY_CANDIDATES_SELECTION });
      }
    } catch (e) {}
  };
}


export function selectContractNoRequest(contract) {
  return async function(dispatch) {
    logger.info("selectContract(): ", contract);
    try {
      dispatch({ type: PORTAL_SELECT_CONTRACT, contract: contract });
      dispatch(fetchCandidates());

    } catch (e) {
      logger.error("selectContract():", e);
    }
  };
}


export function selectContract(contract) {
  return async function(dispatch, getState) {
    logger.info("selectContract(): ", contract);
    let state = getState();
    try {
      dispatch({ type: PORTAL_SELECT_CONTRACT, contract: contract });
      dispatch({ type: EMPTY_CANDIDATES, candidates: null });

      if (state.auth.role === "keyworker") {
        dispatch(fetchCandidates());
        dispatch(fetchInterventions());
        dispatch(getMissions());
        dispatch(getKeyworkerCaseload(state.auth.currentAuthUser.username));
      }
    } catch (e) {
      logger.error("selectContract():", e);
    }
  };
}

export function selectProvider(provider) {
  return async function(dispatch, getState) {
    logger.info("selectProvider(): ", provider);
    try {
      dispatch({ type: PORTAL_SELECT_PROVIDER, provider: provider });
      dispatch({ type: EMPTY_CANDIDATES, candidates: null });

    } catch (e) {
      logger.error("selectProvider():", e);
    }
  };
}

export function getMissions() {
  return async function(dispatch, getState) {
    const state = getState();
    logger.info(
      "getMissions(): ",
      state.portaldetails.selectedContract.contractID
    );

    try {
      const currentKeyWorkerId = state.portaldetails.currentKeyWorker
        ? state.portaldetails.currentKeyWorker
        : state.portaldetails.user.userID;

      let data = await API.get(
        config.API_NAME,
        `/keyworker/${currentKeyWorkerId}/missions/${state.portaldetails.selectedContract.contractID}`
      );
      dispatch({ type: FETCH_MISSIONS, missions: data.data });
    } catch (e) {
      logger.error("getMissions():", e);
    }
  };
}



export const resetKeyworker = keyworkerId => async dispatch => {
  Cache.clear();
  dispatch({ type: PORTAL_DESELECT_KEYWORKER });
  dispatch({ type: EMPTY_CANDIDATES, candidates: null });
};


export const setKeyworkerNoRequest = keyworkerId => async dispatch => {
  if (keyworkerId) {
    Cache.clear();
    dispatch({ type: PORTAL_SELECT_KEYWORKER, keyworker: keyworkerId });
  }
};


export const setKeyworker = keyworkerId => async dispatch => {
  if (keyworkerId) {
    Cache.clear();
    dispatch({ type: PORTAL_SELECT_KEYWORKER, keyworker: keyworkerId });
    dispatch({ type: EMPTY_CANDIDATES, candidates: null });
    try {
      const {
        data: { contracts }
      } = await API.get(config.API_NAME, `/orgadmin/keyworker/${keyworkerId}`);

      dispatch({ type: PORTAL_LIST_CONTRACTS, contracts: contracts });
    } catch (e) {
      logger.error("selectKeyworker():", e);
    }
  }
};



export const setKeyworkers = keyworkerId => async dispatch => {

  
  if (keyworkerId && keyworkerId.length > 0) {
    Cache.clear();

    dispatch({ type: PORTAL_SELECT_KEYWORKER, keyworker: keyworkerId[0].value});

    try {
      let promises = [];
      let data = [];
      _.each(keyworkerId, (key) =>{
        let promise = new Promise((success, failure) => {
          API.get(config.API_NAME, `/orgadmin/keyworker/${key.value}`).then((d) => {
            data.push(d);
            return success();
          });
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        let combineObject = {};
        _.each(data, (index) => {
          _.mergeWith(combineObject, index);
        });
        
        dispatch({ type: PORTAL_LIST_CONTRACTS, contracts: combineObject.data.contracts });
      });
    } catch (e) {
      logger.error("selectKeyworker():", e);
    }
  }
};

export const getPortalUsers = () => async dispatch => {
  try {
    let data = await API.get(config.API_NAME, `/orgadmin/portalusers`);

    dispatch({
      type: FETCH_PORTAL_USERS,
      data: data
    });
  } catch (e) {
    logger.error("getPortalUsers():", e);
  }
};

export const getKeyworkerCaseloads = (keyworkerId, contractId) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();

    if (!contractId) {
      contractId = state.portaldetails.selectedContract.contractID;
    }
    logger.info("getKeyworkerCaseloads(): ", contractId);

    if (keyworkerId && contractId) {

      let data = [];
      let functions = [];
      _.each(keyworkerId, (key, index) =>{

        // let promise = new Promise((success, failure) => {
          // happening to fast getting same result
          functions.push((callback)=> {
            API.get(
              config.API_NAME,
              `/keyworker/${key.value}/caseload/${contractId}`
            ).then((d) => {
              data.push(d);
              callback();
            });
          });
        // });
        // promises.push(promise);
      });

      async.series(functions).then(() => {
        
        let combineObject = {};
        
        _.each(data, (index)=> {
          // combineObject  = mergeObjectsWithSum(combineObject)
          combineObject  = mergeObjectsWithSum( combineObject, index);
        });
        // get the last updated at not a combined updatedAt
        _.each(data, (d) => {
          if (d.data && d.data.updatedAt ){
            combineObject.data.updatedAt = d.data.updatedAt;
          }
        });
        
        dispatch({
          type: PORTAL_CASELOAD,
          payload: Object.assign({}, { ...combineObject.data, contractId, keyworkerId })
        });
  
        dispatch({
          type: PORTAL_DASHBOARD_CASELOAD,
          caseload: combineObject.data
        });
      })
      
    }
  } catch (e) {
    logger.error("getKeyworkerCaseload():", e);
  }
};


export const getKeyworkerCaseload = (keyworkerId, contractId) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();

    if (!contractId) {
      contractId = state.portaldetails.selectedContract.contractID;
    }
    logger.info("getKeyworkerCaseload(): ", contractId);

    if (keyworkerId && contractId) {
      const { data } = await API.get(
        config.API_NAME,
        `/keyworker/${keyworkerId}/caseload/${contractId}`
      );

      let obj =  Object.assign({}, { ...data, contractId, keyworkerId });
      dispatch({
        type: PORTAL_CASELOAD,
        payload: obj
      });

      dispatch({
        type: PORTAL_DASHBOARD_CASELOAD,
        caseload: data
      });
    }
  } catch (e) {
    logger.error("getKeyworkerCaseload():", e);
  }
};

export const getKeyworkerCaseloadWithDate = (keyworkerId, contractId, dateUnix, isEndDate) => async (dispatch) => {
  try {
    if (keyworkerId && contractId) {

      let data = [];
      let promises = [];
      _.each(keyworkerId,  (key, index) =>{
        let promise = new Promise((success, failure) => {
            API.post(config.API_NAME,`/keyworker/caseload`, {
              body: {
                keyworker: key.value,
                contract: contractId,
                targeDate: dateUnix        
              }
            }).then((d) => {
              data.push(d);
              return success();
            });
          });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        
        let combineObject = {};
        
        _.each(data, (index)=> {
          combineObject  = mergeObjectsWithSum( combineObject, index);
        });
        // get the last updated at not a combined updatedAt
        _.each(data, (d) => {
          if (d.data && d.data.updatedAt ){
            combineObject.data.updatedAt = d.data.updatedAt;
          }
        });

        if (isEndDate){
          dispatch({
            type: PORTAL_CASELOAD_ENDDATE,
            payload: Object.assign({}, { ...combineObject.data, contractId, keyworkerId })
          });
        }
        else{
          dispatch({
            type: PORTAL_CASELOAD,
            payload: Object.assign({}, { ...combineObject.data, contractId, keyworkerId })
          });
        }
      })
    }
  } catch (e) {
    logger.error("getKeyworkerCaseloadWithDate():", e);
  }
};

export const getOrgAdminCaseloadWithDate = (dateUnix) => async dispatch => {
  try {
    const { data: orgadminCaseload } = await API.post(
      config.API_NAME,
      `/orgadmin/caseload`
    , {
      body: {
        startDate: dateUnix
      }
    });
    if (orgadminCaseload){
      dispatch({
        type: PORTAL_ORGADMIN_CASELOAD,
        caseload: orgadminCaseload
      });
      dispatch({
        type: PORTAL_DASHBOARD_CASELOAD,
        caseload: orgadminCaseload
      });
    }
  } catch (e) {
    logger.error("getOrgAdminCaseload():", e);
  }
};

export const getGlobalCaseload = () => async dispatch => {
    logger.info("Get Global Details")
    try {
      let data = await API.get(config.API_NAME,"/orgadmin/global/caseload");
      dispatch({
        type: PORTAL_ORGADMIN_CASELOAD_GLOBAL,
        data: data.data
      })
    } catch (e) {
     
    }
}

export const getOrgAdminCaseloadWithEndDate = (dateUnix) => async dispatch => {
  try {
    const { data: orgadminCaseload } = await API.post(
      config.API_NAME,
      `/orgadmin/caseload`
    , {
      body: {
        startDate: dateUnix
      }
    });
    if (orgadminCaseload){
      dispatch({
        type: PORTAL_ORGADMIN_CASELOAD_ENDDATE,
        caseload: orgadminCaseload
      });
    }
  } catch (e) {
    logger.error("getOrgAdminCaseload():", e);
  }
};

export const getSupply = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const data = await API.post(config.API_NAME, '/jobs/query/dashboard_jobs_analytics', {
      body: {
        "target": "high level"
      }
    });
    dispatch({
      type: PORTAL_SUPPLY_DATA,
      data: data
    });

    if (!state.locationList){
      const townsUrl = await Storage.get("uk_locations_list.json", {
        level: "protected",
        identityId: "vacancy_files",
      });
      
      const tempObj = await (await fetch(townsUrl)).json()
      dispatch({
        type: PORTAL_LOCATION_LIST,
        data: tempObj
      });
    }

  } catch (e) {
  }
}

export const getSupplyWithLocation = (locationList) => async (dispatch) => {
  try {

    let dateList = []
    for (let i = 0; i <= 6; i++) {
      dateList.push(moment().subtract(i, 'months').format('M_YYYY'))
    }

    let promises = [];
    _.each(locationList , (location) => {
      _.each(dateList , (i) => {
        const promise = new Promise(async (resolve, reject) => { 
          let data = await API.post(config.API_NAME, "/jobs/query/location_analytics", {
              body: {
                location: location.charAt(0).toUpperCase() + location.slice(1).split(",")[0] + "_" + i              
              },
            });
            resolve(data);
        });
        promises.push(promise);
      });
    });

  Promise.all(promises).then((values) => {
    let monthObject = {}
    for (const { jobs } of values){
      if (jobs.length !== 1){
        continue
      }
      else{
        const monthData = jobs[0]
        const monthNumber = monthData.location.split("_")[1]
        if (!monthObject[monthNumber]){
          monthObject[monthNumber] = {"total": 0}
        }

        for (const [key, value] of Object.entries(monthData.location_data)){
          if(!key.includes("percentage") && key !== "total"){
            let clean_string = key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " ")
            if (!monthObject[monthNumber][clean_string]){
              monthObject[monthNumber][clean_string] = {name:clean_string, number_jobs:value}
            }
            else{
              monthObject[monthNumber][clean_string].number_jobs = value + monthObject[monthNumber][clean_string].number_jobs
            }
            monthObject[monthNumber]["total"] = value + monthObject[monthNumber]["total"]

          }
        }   
      }
    }
    const calculatedMonths = Object.keys(monthObject).map((monthNum) => {
      return {
        id : "aggregated_month_" + monthNum,
        month_digit: monthNum,
        occupation_details: Object.keys(monthObject[monthNum]).map((key) => {
          if (key !== "total"){
            return {
              ...monthObject[monthNum][key],
              percentage_in_total: Math.round((monthObject[monthNum][key].number_jobs / monthObject[monthNum]["total"] * 100) * 100 + Number.EPSILON ) / 100
            }
          }
        }).filter(Boolean)
      }
    })
    dispatch({
      type: PORTAL_SUPPLY_DATA,
      data: {jobs : calculatedMonths}
    });
  });


} catch (e) {
  console.log(e)
  }
}


export const getSupplyLowLevel = () => async dispatch => {
  try {
    const data = await API.post(config.API_NAME, '/jobs/query/dashboard_jobs_analytics', {
      body: {
        "target": "low level"
      }
    });
    dispatch({
      type: PORTAL_SUPPLY_LOW,
      data: data
    });
  } catch (e) {

  }
}

export const getAdzunaCategory = () => async (dispatch, getState) => {
  try {
    const state = getState();
    // const cityUrl = await Storage.get("uk_city_list.json", {
    //   level: "protected",
    //   identityId: "vacancy_files",
    // });
  
    // const cityList = await (await fetch(cityUrl)).json()

    // const data = await API.post(config.API_NAME, '/jobs/query/get_adzuna_categories', {
    //   body: {}
    // })

    dispatch({
      type: PORTAL_ADZUNA_CATEGORY,
      data: [{"label": "Accounting & Finance Jobs", "tag": "accounting-finance-jobs"}, {"label": "IT Jobs", "tag": "it-jobs"}, {"label": "Sales Jobs", "tag": "sales-jobs"}, {"label": "Customer Services Jobs", "tag": "customer-services-jobs"}, {"label": "Engineering Jobs", "tag": "engineering-jobs"}, {"label": "HR & Recruitment Jobs", "tag": "hr-jobs"}, {"label": "Healthcare & Nursing Jobs", "tag": "healthcare-nursing-jobs"}, {"label": "Hospitality & Catering Jobs", "tag": "hospitality-catering-jobs"}, {"label": "PR, Advertising & Marketing Jobs", "tag": "pr-advertising-marketing-jobs"}, {"label": "Logistics & Warehouse Jobs", "tag": "logistics-warehouse-jobs"}, {"label": "Teaching Jobs", "tag": "teaching-jobs"}, {"label": "Trade & Construction Jobs", "tag": "trade-construction-jobs"}, {"label": "Admin Jobs", "tag": "admin-jobs"}, {"label": "Legal Jobs", "tag": "legal-jobs"}, {"label": "Creative & Design Jobs", "tag": "creative-design-jobs"}, {"label": "Graduate Jobs", "tag": "graduate-jobs"}, {"label": "Retail Jobs", "tag": "retail-jobs"}, {"label": "Consultancy Jobs", "tag": "consultancy-jobs"}, {"label": "Manufacturing Jobs", "tag": "manufacturing-jobs"}, {"label": "Scientific & QA Jobs", "tag": "scientific-qa-jobs"}, {"label": "Social work Jobs", "tag": "social-work-jobs"}, {"label": "Travel Jobs", "tag": "travel-jobs"}, {"label": "Energy, Oil & Gas Jobs", "tag": "energy-oil-gas-jobs"}, {"label": "Property Jobs", "tag": "property-jobs"}, {"label": "Charity & Voluntary Jobs", "tag": "charity-voluntary-jobs"}, {"label": "Domestic help & Cleaning Jobs", "tag": "domestic-help-cleaning-jobs"}, {"label": "Maintenance Jobs", "tag": "maintenance-jobs"}, {"label": "Part time Jobs", "tag": "part-time-jobs"}, {"label": "Other/General Jobs", "tag": "other-general-jobs"}, {"label": "Unknown", "tag": "unknown"}]
    });

  } catch (e) {
  }
}

export const getAdzunaRegionData= (category, location) => async (dispatch, getState) => {
  try {
    const state = getState();
    const retries = 5;
    let data;

    for (var i = 1; i < retries; i++){
      data = await API.post(config.API_NAME, '/jobs/query/get_adzuna_regional_data', {
        body: {"category": category ? category : "", "location": location ? location : ""}
      });
      if (data && !Array.isArray(data.jobs)){
        break
      }
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1000)
    }
    dispatch({
      type: PORTAL_ADZUNA_REGION_DATA,
      data: data
    });

  } catch (e) {
  }
}

export const getAdzunaHistoricalCategory = (category, location) => async (dispatch, getState) => {
  try {
    const state = getState();
    const retries = 5;
    let data;

    for (var i = 1; i < retries; i++){
      data = await API.post(config.API_NAME, '/jobs/query/get_adzuna_historical_category', {
        body: {"category": category ? category : "", "location": location ? location : ""}
      });
      if (data && !Array.isArray(data.jobs)){
        break
      }
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1000)
    }

    dispatch({
      type: PORTAL_ADZUNA_HISTORICAL_CATEGORY,
      data: data
    });

  } catch (e) {
  }
}

export const getAdzunaHistogramCategory = (category, location) => async (dispatch, getState) => {
  try {
    const state = getState();
    const retries = 5;
    let data;

    for (var i = 1; i < retries; i++){
      data = await API.post(config.API_NAME, '/jobs/query/get_adzuna_histogram_category', {
        body: {"category": category ? category : "", "location": location ? location : ""}
      });
      if (data && !Array.isArray(data.jobs)){
        break
      }
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1000)
    }

    dispatch({
      type: PORTAL_ADZUNA_HISTOGRAM_CATEGORY,
      data: data
    });

  } catch (e) {
  }
}




export const getOrgAdminCaseload = () => async dispatch => {
  try {
    const { data: orgadminCaseload } = await API.get(
      config.API_NAME,
      `/orgadmin/caseload`
    );
    dispatch({
      type: PORTAL_ORGADMIN_CASELOAD,
      caseload: orgadminCaseload
    });
    dispatch({
      type: PORTAL_DASHBOARD_CASELOAD,
      caseload: orgadminCaseload
    });
  } catch (e) {
    logger.error("getOrgAdminCaseload():", e);
  }
};

export const setOrgContract = (contractID, selectedProvider) => async (dispatch, getState) => {
  let state = getState();

  let caseloadObj;
  if (selectedProvider === "all") {
    if (contractID === "all") {
      caseloadObj = state.portaldetails.caseload.orgadminCaseload;
    } else {
      caseloadObj = state.portaldetails.caseload.orgadminCaseload
        .contracts[contractID];
    }
  } else {
    if (contractID === "all" && selectedProvider.length === 1) {
      if (selectedProvider[0]){
        caseloadObj = state.portaldetails.caseload.orgadminCaseload
        .providers[selectedProvider[0].value];
      } else {
        caseloadObj = state.portaldetails.caseload.orgadminCaseload
      }
    } else {
      let lastObjectMerged = false;
      for (let i = 0; i < selectedProvider.length - 1; i++){
        // this check will see if contracts exists in both parent and child before trying to merge them.
        // all contracts doesn't exist in contracts but in it own object all so it needs seperated with an else

          let provider1 = state.portaldetails.caseload.orgadminCaseload
          .providers[selectedProvider[i].value].contracts[contractID];
          let provider2 = state.portaldetails.caseload.orgadminCaseload
          .providers[selectedProvider[i + 1].value].contracts[contractID];
          
          if (contractID === "all"){
            provider1 = state.portaldetails.caseload.orgadminCaseload
            .providers[selectedProvider[i].value];
            provider2 = state.portaldetails.caseload.orgadminCaseload
            .providers[selectedProvider[i + 1].value];  
          }

          let providerMerge1 = {};
          let providerMerge  = {};

          if (provider1){
            providerMerge  = JSON.stringify(provider1);
            providerMerge  = JSON.parse(providerMerge); 
          }
          if (provider2){
            providerMerge1 = JSON.stringify(provider2);
            providerMerge1 = JSON.parse(providerMerge1);
          }
          
          if (lastObjectMerged){
            lastObjectMerged = mergeObjectsWithSum(lastObjectMerged, providerMerge1);
          } else {
            lastObjectMerged = mergeObjectsWithSum(providerMerge, providerMerge1);
          }
      }
      // double check there isn't a single instance of data rather than a merge.

      if (contractID !== "all" && lastObjectMerged.contracts){
        caseloadObj = lastObjectMerged.contracts[contractID];
      } else if (contractID !== "all" && !lastObjectMerged){
        caseloadObj = state.portaldetails.caseload.orgadminCaseload
        .providers[selectedProvider[0].value].contracts[contractID];
      }  else {
        caseloadObj = lastObjectMerged
      }

      dispatch({
        type: PORTAL_DASHBOARD_CASELOAD,
        caseload: caseloadObj,
      });

    }
  }
}


export const setProviderChange = (providerID, setState ) => async (dispatch, getState) => {
  let state = getState();
  
  setState({
    selectedProvider: (providerID) ? providerID : "all",
    selectedContract: "all",
    contracts: false
  });
  let lastObjectMerged = false;
  let caseloadObj; let providerIds = []; let containsAll = true;
  // return false;

  if (providerID && Array.isArray(providerID) && providerID.length > 0){
    containsAll = false;
    providerID.forEach((provider) => {
      if (provider.value){
        providerIds.push(provider.value);
      }
    });

  }

  if (containsAll) {
    caseloadObj = state.portaldetails.caseload.orgadminCaseload;
  } else {

    let caseloadObjs = [];
    if (providerIds.length > 0){
      providerIds.forEach((provider) => {
        caseloadObj = state.portaldetails.caseload.orgadminCaseload
          .providers[provider];
        caseloadObjs.push(caseloadObj);
      });
    }

    if (caseloadObjs.length > 1){
      for (var i = 0; i < (caseloadObjs.length - 1); i++){
         // stop deep cloning by creating a string and converting it to an object.
         caseloadObjs = JSON.stringify(caseloadObjs); 
         caseloadObjs = JSON.parse(caseloadObjs);
        if (lastObjectMerged){
          lastObjectMerged = mergeObjectsWithSum(lastObjectMerged, caseloadObjs[i + 1]);
        } else {
          lastObjectMerged = mergeObjectsWithSum(caseloadObjs[i], caseloadObjs[i + 1]);
        }
      }
    }

    let providersContactsMerge;
    if (providerIds.length > 1){
      for (let x = 0; x < (providerIds.length - 1); x++){
         // stop deep cloning
        if (providersContactsMerge){

          let provider1 = state.portaldetails.caseload.orgadminCaseload.providers[
            providerIds[x + 1]
          ].contracts;
         
          provider1 = JSON.stringify(provider1);
          provider1 = JSON.parse(provider1);
          
          providersContactsMerge = mergeObjectsWithSum(providersContactsMerge, provider1);

        } else {
          let provider  = state.portaldetails.caseload.orgadminCaseload.providers[
            providerIds[x]
          ].contracts;
          let provider1 = state.portaldetails.caseload.orgadminCaseload.providers[
            providerIds[x + 1]
          ].contracts;

          provider = JSON.stringify(provider);
          provider = JSON.parse(provider);
          provider1 = JSON.stringify(provider1);
          provider1 = JSON.parse(provider1)

          providersContactsMerge = mergeObjectsWithSum(provider, provider1);
        }

        setState({
          contracts: providersContactsMerge
        });

      }
    } else {

      setState({
          contracts: state.portaldetails.caseload.orgadminCaseload.providers[
            providerIds[0]
          ].contracts,
      });
    }
  }

  dispatch({
    type: PORTAL_DASHBOARD_CASELOAD,
    caseload: (lastObjectMerged) ? lastObjectMerged : caseloadObj,
  });

}

export const trackBulkUpload = (bulkJobID, userID, emailContent, inputData) => async (dispatch, getState) => {
  dispatch({type: UPDATE_BULK_UPLOAD_PROGRESS, data: false});
  if(inputData){
    try {
      await Storage.put(userID + "/" + bulkJobID + "/bulkUpload.json", {
        data: inputData,
        emailContent: emailContent
      }, {
        level: "private",
      });
  
    } catch (e) {
      console.log(e);
      alert(e.message);
      return false
    }
  }

  try{
    let initialWait = true
    let jobRunning = 0;
    const maxRuns = 999;
    const waitTime = 3000;
    const delay = ms => new Promise(res => setTimeout(res, ms));
    let data = false;
    do {
      jobRunning += 1
      await delay(waitTime)
      data = await API.post(config.API_NAME, `/orgadmin/bulkupload/track`, {
        body: {
          bulkJobID: bulkJobID
        }
      });
      if (data){
        if(data.progress){
          dispatch({type: UPDATE_BULK_UPLOAD_PROGRESS, data: data.progress});
        }
        if(inputData){
          if (data.stage === 1 && !data.running){
            jobRunning = maxRuns
          }
        }
        else{
          if (!data.running){
            jobRunning = maxRuns
          }
        }
      }
      else{
        if (initialWait){
          initialWait = false
        }
        else{
          jobRunning = maxRuns
        }
      }
    } while (jobRunning < maxRuns);


    if(!data.error){
      const responseUrl = await Storage.get(userID +"/"+ bulkJobID + "/response.json", {
        level: "private"
      });
      data.data = await (await fetch(responseUrl)).json();
    }
    return data
    } catch (e) {
      logger.error("error():", e);
    }
}

export const getBulkEmailTemplate = () => async (dispatch, getState) => {
  const data = await API.post(config.API_NAME, `/orgadmin/bulkupload/template/get`, {});
  if (data && data.data){
    dispatch({
      type: SET_BULK_EMAIL_TEMPLATE,
      data: data.data,
    });
    return data.data
  }
}

export const setBulkEmailTemplate = (newtemplate) => async (dispatch, getState) => {
  const data = await API.post(config.API_NAME, `/orgadmin/bulkupload/template/set`, {
    body: {
      data: newtemplate
    }
  });
  dispatch({
    type: SET_BULK_EMAIL_TEMPLATE,
    data: newtemplate,
  });
}

export const fetchRoles = () => async (dispatch, getState) => {
  const data = await API.get(config.API_NAME, `/internal-mobility/roles`, {});
  if(data && data.success){
    dispatch({type: SET_ROLES, data: data.data, unverifiedRoles: data.unverifiedRoles});
  }
}

export const addRole = (newRole) => async (dispatch, getState) => {
  let {
    portaldetails:{
      internalRoles= [],
      unverifiedRoles= []
    }
  } = getState();
  const data = await API.post(config.API_NAME, `/internal-mobility/roles`, {
    body: newRole
  });
  if(data && data.success){
    dispatch({type: SET_ROLES, data: [...internalRoles, data.data], unverifiedRoles: unverifiedRoles});
  }
}


export const removeRole = (roleID, newValue) => async (dispatch) => {
  const remove = await API.post(config.API_NAME, `/internal-mobility/roles/remove`, {
    body: {
      roleID: roleID,
      newValue: newValue
    }
  });
  if(remove && remove.success){
    dispatch(fetchRoles())
  }
}

export const fetchDepartments = () => async (dispatch, getState) => {
  const data = await API.get(config.API_NAME, `/internal-mobility/departments`, {});
  if(data && data.success){
    dispatch({type: SET_DEPARTMENTS, data: data.data, unverifiedDepartments: data.unverifiedDepartments});
  }
}

export const addDepartment = (newDepartment) => async (dispatch, getState) => {
  let {
    portaldetails:{
      internalDepartments= [],
      unverifiedDepartments= []
    }
  } = getState();
  const data = await API.post(config.API_NAME, `/internal-mobility/departments`, {
    body: newDepartment
  });
  if(data && data.success){
    dispatch({type: SET_DEPARTMENTS, data: [...internalDepartments, data.data], unverifiedDepartments: unverifiedDepartments});
  }
}

export const removeDepartment = (departmentID, newValue) => async (dispatch) => {
  const remove = await API.post(config.API_NAME, `/internal-mobility/departments/remove`, {
    body: {
      departmentID: departmentID,
      newValue: newValue
    }
  });
  if(remove && remove.success){
    dispatch(fetchDepartments())
  }
}

export const removeLineManagers = (lineManagerID, newValue) => async (dispatch) => {
  const remove = await API.post(config.API_NAME, `/internal-mobility/linemanagers/remove`, {
    body: {
      lineManagerID: lineManagerID,
      newValue: newValue
    }
  });
  if(remove && remove.success){
    dispatch(fetchLineManagers())
  }
}

export const fetchLineManagers = () => async (dispatch, getState) => {
  const data = await API.get(config.API_NAME, `/internal-mobility/linemanagers`, {});
  if(data && data.success){
    dispatch({type: SET_LINEMANAGERS, data: data.data, unverifiedLineManagers: data.unverifiedLineManagers});
  }
}

export const fetchUnverifiedCounts = () => async (dispatch, getState) => {
  const data = await API.get(config.API_NAME, `/internal-mobility/unverified`, {});
  if(data && data.success){
    dispatch({type: SET_UNVERIFIED, data: data});
  }
}

export const fetchDemographics = () => async (dispatch, getState) => {
  const data = await API.get(config.API_NAME, `/internal-mobility/demographics`, {});
  if(data && data.success){
    dispatch({type: SET_DEMOGRAPHICS, data: data.data});
  }
}

export const updateDemographics = (newQuestions) => async (dispatch, getState) => {
  const data = await API.post(config.API_NAME, `/internal-mobility/demographics`, {
    body: {
      questions: newQuestions
    }
  });
  if(data && data.success){
    dispatch({type: SET_DEMOGRAPHICS, data: data.data});
  }
}

export const updateUserRoleInternalMobility = (userID, roleID) => async (dispatch, getState) => {
  const data = await API.post(config.API_NAME, `/internal-mobility/user/setRole`, {
    body: {
      userID: userID,
      roleID: roleID
    }
  });
  if(data && data.success){
    await dispatch(fetchRoles());
  }
}


export const updateUserDepartmentsInternalMobility = (userID, departmentID) => async (dispatch, getState) => {
  const data = await API.post(config.API_NAME, `/internal-mobility/user/setDepartment`, {
    body: {
      userID: userID,
      departmentID: departmentID
    }
  });
  if(data && data.success){
    await dispatch(fetchDepartments());
  }
}

export const updateUserLineManagerInternalMobility = (userID, lineManagerID) => async (dispatch, getState) => {
  const data = await API.post(config.API_NAME, `/internal-mobility/user/setLineManager`, {
    body: {
      userID: userID,
      lineManagerID: lineManagerID
    }
  });
  if(data && data.success){
    await dispatch(fetchLineManagers());
  }
}

export const getOccDetails = (text) => async (dispatch, getState) => {
  let data = false
  try {
    data = await API.post(config.API_NAME, "/nlu/getdetails", {
      body: {
        text: text
      }
    })
  } catch (e) {
    data = await API.post(config.API_NAME, "/nlu/getdetailsfallback", {
      body: {
        text: text
      }
    })
  }
  if(data && data.response){
    return data.response
  }
}
