import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { autoCompleteV2, autoCompleteV2Skill } from "../../actions/vacancies_actions";
import { Autocomplete } from "@material-ui/lab";
import {Typography, TextField, makeStyles} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiOutlinedInput-root': { color:"black" },
  }
}));

const jobMatchTypeOptions = [
  {
    label: "Previous",
    value: "previous",
    group: "Job Match Options"
  },
  {
    label: "Preferred",
    value: "preferred",
    group: "Job Match Options"
  },
  {
    label: "Skills",
    value: "skills",
    group: "Job Match Options"
  },
  {
    label: "Styles",
    value: "styles",
    group: "Job Match Options"
  },
  {
    label: "Interests",
    value: "interests",
    group: "Job Match Options"
  }
]

const blankOption = {
  label: "",
  value: "",
  group: "Begin typing to search other occupations"
}

const whiteStyles = {backgroundColor: "white", color: 'black'}

const OccupationAutoCompleteComponent = ({
  onSelect,
  autoCompleteV2,
  autoCompleteV2Skill,
  error,
  disabled,
  selectedOptions = [],
  size = "small",
  placeholder = "Begin typing to find occupations",
  type = "occupation",
  useJobMatchOption = false,
  blankInput,
  clearOnSelect=false
  }) => {
  const classes = useStyles();
  const fontSize = size === "small" ? "0.8125rem" : "1rem"
  const [filteredOptions, setFilteredOptions] = useState(useJobMatchOption ? [...jobMatchTypeOptions, blankOption] : []); 
  const [input, setInput] = useState("");
  const onInputChange = async (event={}, searchText) => {
    if((clearOnSelect && !event || (event && event.type !== "change")) || searchText === "" || searchText.length === 0){
      setFilteredOptions(useJobMatchOption ? [...jobMatchTypeOptions, blankOption] : [])
      setInput("")
    }
    else{
      setInput(searchText)
      const response =  type === "occupation" ? await autoCompleteV2(searchText) : await autoCompleteV2Skill(searchText)
      if(response && (response.directMatches || response.closeMatches)){
        setFilteredOptions([
          ...(useJobMatchOption ? jobMatchTypeOptions : []),
          ...response.directMatches.map((item) => {return {label: item.low, group: "Direct Matches"}}), 
          ...response.closeMatches.map((item) => {return {label: item.low, group: "Close Matches"}})])
      }
      else{
        setFilteredOptions([])        
      }
    }
  };
  
  useEffect(() => {
    async function setBlankInput() {
      setInput("")
    }
    setBlankInput();
  }, [blankInput])

  return (
      <Autocomplete
        style={useJobMatchOption ? whiteStyles : {}}
        fullWidth
        disabled={disabled}
        onInputChange={onInputChange}
        onChange={(e, v) => {
          if(v){
            onSelect(v.label);
          }
        }}
        inputValue={input}
        options={filteredOptions}
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option.group}
        renderInput={(params) => {
          return (
            <TextField 
              {...params}
              size="small"
              InputLabelProps={{...params.InputLabelProps, style: { fontSize:fontSize}}}
              InputProps={{...params.InputProps, style: { fontSize: fontSize}}}
              label={placeholder}
              placeholder={placeholder}
              variant="outlined" 
              fullWidth 
              error={error}
              className={classes.textField}
            />
          ) 
        }}
        renderOption={(option) => {
          if(option.group === "Direct Matches" && option.label.includes(input)){
            const nonBold = option.label.split(input);
            return (
              <Typography 
                style={{fontSize:fontSize, display:"flex", alignItems:"center", whiteSpace: "pre"}} 
                noWrap
              >
                <strong>{nonBold[0]}</strong>
                {input}
                <strong>{nonBold[1]}</strong>
                {selectedOptions.includes(option.label) && (<DoneIcon fontSize="small" style={{marginLeft:5}}/>)}
              </Typography>
            )
          }
          else{
            return (
              <Typography 
                style={{fontSize:fontSize, display:"flex", alignItems:"center"}} 
                noWrap
              >
                {option.label}
                {selectedOptions.includes(option.label) && (<DoneIcon fontSize="small" style={{marginLeft:5}}/>)}
              </Typography>
              )
          }
          }
        }
        filterOptions={(options) => options}
      />
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ autoCompleteV2, autoCompleteV2Skill }, dispatch),
    dispatch
  };
}

export const OccupationAutoComplete = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OccupationAutoCompleteComponent);
