import { Grid, Popover } from "@material-ui/core";
import React from "react";

export const OccupationalArea = ({
  high_occupation,
  occupation_descripton,
}) => {
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);
  return (
    <>
      <Grid container alignItems="center" justify="center">
        <Grid
          item
          xs
          style={{ textAlign: "center" }}
          onMouseEnter={(e) => {
            if ((occupation_descripton || "").length > 0) {
              setPopoverAnchor(e.currentTarget);
            }
          }}
        >
          {high_occupation}
        </Grid>
      </Grid>
      <Popover
        id="mouse-over-popover"
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        disableRestoreFocus
      >
        <Grid
          container
          direction="column"
          style={{ padding: "1em", maxWidth: "15em" }}
          onMouseLeave={() => setPopoverAnchor(null)}
        >
          <Grid item>{occupation_descripton}</Grid>
        </Grid>
      </Popover>
    </>
  );
};
