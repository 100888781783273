import {
  AppBar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { Can } from "../../../Abilities";
import AssessmentCard from "../../../Common/AssessmentCard";
import SummaryRadarCard from "../../../Common/SummaryRadarCard";
import { getDefinition, getSkill } from "../definitions";
import { ConstructExpansionPanel } from "./ContructExpansionPanel";
import { DimensionDetail } from "./DimensionDetail";
import { InterestPieChart } from "./InterestsPieChart";
import { interestTypes } from "./interestTypes";
import { JobMatches } from "./JobMatches";
import { SkillsBackpackSummary } from "./JobSkillSummary";
import CoinLevels from "./CoinLevels";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    marginLeft: 20,
    marginTop: 10,
    fontSize: 28,
  },
  subtitle: {
    //fontSize: 28
    marginLeft: 20,
    marginTop: 8,
  },
  subtitle2: {
    fontSize: 16,
    marginLeft: 0,
    marginTop: 30,
    marginBottom: 10,
  },
  subtitle3: {
    fontSize: 16,
    marginLeft: 0,
    marginTop: 10,
    marginBottom: 10,
  },
  sectionTitle: {
    marginTop: 40,
    marginBottom: 20,
  },
  center: {
    textAlign: "center",
    display: "block",
    paddingTop: 14,
    border: "1px solid #ddd",
  },
  graphBg: {
    backgroundColor: "#fbfbfb",
    border: "1px solid #e8e8e8",
    padding: 15
  },
  subheading: {
    marginLeft: 20,
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#444",
  },
  heading: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "10%",
    flexShrink: 0,
  },
  heading1: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: 20,
  },
  subheading1: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#666",
    marginLeft: 10,
    display: "inline-block",
  },

  resultText: {
    fontSize: theme.typography.pxToRem(14),
  },
  resultText1: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  divid: {
    marginBottom: 20,
    marginTop: 20,
  },
  reassess: {
    marginLeft: 15,
    marginTop: 15,
  },
  itemre: {
    marginTop: 10,
    marginBottom: 5,
  },
  header: {
    padding: theme.spacing(3),
  },
  avatarlarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1),
  },
  light: {
    color: "#666",
  },
  smallicons: {
    color: "#666",
  },
  itemtitle: {
    fontSize: 12,
  },
  prog: {
    marginTop: -12,
  },
  itemgrid: {
    minHeight: 22,
    marginBottom: theme.spacing(1),
  },
  name: {
    maxWidth: 400,
    fontSize: "2rem",
    fontWeight: 500,
  },
  jobpanelcontainer: {
    height: 360,
    //overflow : "hidden"
    //marginBottom: theme.spacing(3)
  },
  jobpanelcontainerprint: {
    height: "auto",
    //marginBottom: theme.spacing(3)
    pageBreakAfter: "always",
  },
});

export const CandidateSummaryView = withStyles(styles)(
  (props) => {
    const {
      candidate,
      classes,
      user,
      triggerReassessment,
      isOnPrintMode,
      groupSkillsBackpack = false,
      getSkillsInfo: gsb,
      rewards
    } = props;
    // const [skillsBackpack, setSkillsBackpack] = useState(null);
    useEffect(() => {
      console.log("effect runs");
      async function getSkillsInfo() {
        if (candidate) {
          gsb();
          console.log("getskills")
        } else {
          console.log("no candidate? ");
        }
      }
      getSkillsInfo();
    }, [candidate, gsb]);

    if (!candidate) {
      return (
        <Grid>
          <Grid container item xs justify="center">
            <CircularProgress size={25} thickness={4} />
          </Grid>
        </Grid>
      );
    }
    if (!candidate.assessmentCompleted && !candidate.reAssessmentDue) {
      return (
        <Grid>
          <Grid container item xs justify="center">
            <Typography variant="subtitle2">
              This candidate has not completed the assessment yet.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    const {
      latestAssessmentResult: {
        interests: { results = {}, interestsOriginal = {} },
      } = {},
      assessmentCreatedAt,
      latestAssessmentCreatedAt,
      reassessmentTriggeredAt,
      isInterventionOngoing,
    } = candidate;
    const hasDoneMultipleAssessments =
      assessmentCreatedAt !== latestAssessmentCreatedAt;
    const mostMatchedInterestProfile = results[0];
    const candidateInterestsScore = Object.keys(interestsOriginal).reduce(
      (acl, curr) =>
        Object.assign({}, acl, {
          [curr.toLowerCase()]: Math.round((interestsOriginal[curr] / 7) * 100),
        }),
      {}
    );
    const interestChartData = Object.keys(interestsOriginal).map(
      (interest) => ({
        name: interest,
        value: interestsOriginal[interest],
      })
    );
    return (
      <Grid>
        {latestAssessmentCreatedAt ? (
          <Can I="do" this="REASSESSMENT">
            <Grid item className={classes.itemre}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  triggerReassessment(user);
                }}
                disabled={
                  isInterventionOngoing || candidate.reAssessmentDue === true
                }
              >
                Reassessment
              </Button>
              {isInterventionOngoing ? (
                <span style={{ paddingLeft: "1em" }}>
                  Candidate has an intervention in progress
                </span>
              ) : null}
              {reassessmentTriggeredAt ? (
                <span style={{ paddingLeft: "1em" }}>
                  Requested {moment(reassessmentTriggeredAt).fromNow()}
                </span>
              ) : null}
            </Grid>
          </Can>
        ) : null}
        {user.organisation === "sds" ? (
          <>
            <Grid item xs>
              <JobMatches
                isOnPrintMode={isOnPrintMode}
                classes={classes}
                candidateDetails={candidate}
                candidateStyles={candidate.radarData}
                candidateInterestsScore={candidateInterestsScore}
              />
            </Grid>
          </>
        ) : null}
        {candidate.radarData ? (
          <>
            <Grid item>
            <CoinLevels data={rewards}/>
            </Grid>
            <Grid item xs>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.sectionTitle}
              >
                <Toolbar variant="dense" className={classes.center}>
                  <Typography variant="h6" color="inherit">
                    Work Styles
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs>
              <Grid container justify="center" alignItems="center">
                <Grid item xs>
                  <SummaryRadarCard data={candidate.radarData} dataKey="name" />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}

        {candidate.assessmentCompleted ||
        (candidate.reAssessmentDue && !candidate.assessmentCompleted) ? (
          <>
            <Grid item xs>
              <Typography
                variant="subtitle2"
                color="inherit"
                className={classes.subtitle2}
              >
                Brainpower
              </Typography>
              <Paper square elevation={0} className={classes.graphBg}>
                <AssessmentCard
                  data={candidate.ACdata}
                  dataKey="name"
                  hasDoneMultipleAssessments={hasDoneMultipleAssessments}
                />
              </Paper>
              <Typography
                variant="subtitle2"
                color="inherit"
                className={classes.subtitle2}
              >
                Agility
              </Typography>
              <Paper square elevation={0} className={classes.graphBg}>
                <AssessmentCard
                  data={candidate.LAdata}
                  dataKey="name"
                  hasDoneMultipleAssessments={hasDoneMultipleAssessments}
                />
              </Paper>
              <Typography
                variant="subtitle2"
                color="inherit"
                className={classes.subtitle2}
              >
                Resilience
              </Typography>
              <Paper square elevation={0} className={classes.graphBg}>
                <AssessmentCard
                  data={candidate.REdata}
                  dataKey="name"
                  hasDoneMultipleAssessments={hasDoneMultipleAssessments}
                />
              </Paper>
              <div style={{ marginTop: "2em" }}>
                <ConstructExpansionPanel
                  defaultExpanded={isOnPrintMode}
                  constructTitle="Brainpower"
                  constructDescription="The ability to solve complex problems quickly and accurately."
                  constructSummary={getDefinition(
                    "analytical_capacity",
                    candidate.latestAssessmentResult.results
                      .analytical_capacity,
                    user.firstName
                  )}
                  classes={classes}
                >
                  <DimensionDetail
                    title="Complexity"
                    description={`Spotting patterns, recognizing connections, dealing with
                  complex issues.`}
                    classes={classes}
                    summary={getSkill(
                      "SPC",
                      candidate.latestAssessmentResult.results.SPC.talent,
                      user.firstName
                    )}
                  />

                  <Divider className={classes.divid} />

                  <DimensionDetail
                    title="Speed"
                    description={`Analysing and making decisions at speed.`}
                    classes={classes}
                    summary={getSkill(
                      "DMA",
                      candidate.latestAssessmentResult.results.DMA.talent,
                      user.firstName
                    )}
                  />

                  <Divider className={classes.divid} />

                  <DimensionDetail
                    title="Accuracy"
                    description={`Solving problems accurately.`}
                    classes={classes}
                    summary={getSkill(
                      "PSA",
                      candidate.latestAssessmentResult.results.PSA.talent,
                      user.firstName
                    )}
                  />
                </ConstructExpansionPanel>

                <ConstructExpansionPanel
                  defaultExpanded={isOnPrintMode}
                  constructTitle="Agility"
                  constructDescription={`The ability to take onboard feedback, adapt to new and
            unfamiliar tasks, and leverage a range of learning styles.`}
                  constructSummary={getDefinition(
                    "learning_agility",
                    candidate.latestAssessmentResult.results.learning_agility,
                    user.firstName
                  )}
                  classes={classes}
                >
                  <DimensionDetail
                    title="Responsiveness"
                    description={`Responding positively to feedback and taking on-board
                  advice from others.`}
                    classes={classes}
                    summary={getSkill(
                      "RTF",
                      candidate.latestAssessmentResult.results.RTF.talent,
                      user.firstName
                    )}
                  />

                  <Divider className={classes.divid} />
                  <DimensionDetail
                    title="Adaptability"
                    description={`Adapting effectively to new situations and challenges.`}
                    classes={classes}
                    summary={getSkill(
                      "ADY",
                      candidate.latestAssessmentResult.results.ADY.talent,
                      user.firstName
                    )}
                  />
                  <Divider className={classes.divid} />
                  <DimensionDetail
                    title="Learning"
                    description={`Learning quickly and applying a range of learning styles.`}
                    classes={classes}
                    summary={getSkill(
                      "RLS",
                      candidate.latestAssessmentResult.results.RLS.talent,
                      user.firstName
                    )}
                  />
                </ConstructExpansionPanel>

                <ConstructExpansionPanel
                  defaultExpanded={isOnPrintMode}
                  constructTitle="Resilience"
                  constructDescription={`The ability to retain emotional control despite setbacks, set
            and achieve challenging goals, and demonstrate the confidence
            to succeed.`}
                  constructSummary={getDefinition(
                    "resilience",
                    candidate.latestAssessmentResult.results.resilience,
                    user.firstName
                  )}
                  classes={classes}
                >
                  <DimensionDetail
                    title="Control"
                    description={` Managing emotions and demonstrating optimism despite
                  setbacks.`}
                    classes={classes}
                    summary={getSkill(
                      "CONT",
                      candidate.latestAssessmentResult.results.CONT.talent,
                      user.firstName
                    )}
                  />
                  <Divider className={classes.divid} />
                  <DimensionDetail
                    title="Commitment"
                    description={`Setting difficult goals and showing determination in
                  achieving them.`}
                    classes={classes}
                    summary={getSkill(
                      "COMM",
                      candidate.latestAssessmentResult.results.COMM.talent,
                      user.firstName
                    )}
                  />
                  <Divider className={classes.divid} />
                  <DimensionDetail
                    title="Confidence"
                    description="Belief in own abilities to succeed."
                    classes={classes}
                    summary={getSkill(
                      "CONF",
                      candidate.latestAssessmentResult.results.CONF.talent,
                      user.firstName
                    )}
                  />
                </ConstructExpansionPanel>
                <AppBar
                  position="static"
                  color="default"
                  elevation={0}
                  className={classes.sectionTitle}
                >
                  <Toolbar variant="dense" className={classes.center}>
                    <Typography variant="h6" color="inherit">
                      Interests
                    </Typography>
                  </Toolbar>
                </AppBar>
                <InterestPieChart interests={interestChartData} />
                <div style={{ marginTop: 20 }}> </div>
                <ConstructExpansionPanel
                  defaultExpanded={isOnPrintMode}
                  constructTitle="Interests"
                  constructDescription={
                    interestTypes[mostMatchedInterestProfile].summary
                  }
                  constructSummary={() => (
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          interestTypes[mostMatchedInterestProfile].description,
                      }}
                    />
                  )}
                  classes={classes}
                ></ConstructExpansionPanel>
              </div>
            </Grid>
            <Grid item xs></Grid>
          </>
        ) : null}
        {(candidate.skillsInfo || []).length ? (
          <Grid item xs>
            <Grid container direction="column">
              <Grid item xs>
                <AppBar
                  position="static"
                  color="default"
                  elevation={0}
                  className={classes.sectionTitle}
                >
                  <Toolbar variant="dense" className={classes.center}>
                    <Typography variant="h6" color="inherit">
                      Skills
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid item xs>
                <SkillsBackpackSummary
                  isOnPrintMode={isOnPrintMode}
                  skillsInfo={candidate.skillsInfo}
                  groupSkillsBackpack={groupSkillsBackpack}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  }
);
