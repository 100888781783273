import { Auth, Cache, Logger, API } from "aws-amplify";
import config from "../config";
import {
  AUTH_USER,
  AUTH_MFA,
  AUTH_NEW_PASSWORD_REQUIRED,
  UNAUTH_USER,
  REGISTER_USER,
  REGISTER_USER_CONFIRM,
  REGISTER_MFA,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRM,
  AUTH_ERROR,
  RESET_APP
} from "./types";
import { getPortalDetails } from "./portal_actions";

const logger = new Logger("AUTH.ACTIONS", "INFO");

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error.message ? error.message : error
  };
}

// Cognito - Auth.signIn()
export function login({ username, password }, history) {
  return function(dispatch) {
    logger.info("actions.login(): username:", { username });
    Cache.clear();
    // signIn (cognito)
    Auth.signIn(username, password)
      .then(data => {
        Cache.removeItem("lastpath");
        // success -- dispatch AUTH_USER
        logger.info("login():Auth.signIn() data:", data);

        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch->AUTH_MFA
        // 2. New Password is required (change 'FORCE_CHANGE_PASSWORD'
        //    to 'CONFIRMED'), dispatch-> AUTH_NEW_PASSWORD_REQUIRED with payload
        // 3. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({ type: AUTH_NEW_PASSWORD_REQUIRED, payload: data });
        } else if (
          data.challengeName === "MFA_REQUIRED" ||
          data.challengeName === "SMS_MFA"
        ) {
          dispatch({ type: AUTH_MFA, payload: data });
        } else {
          // dispatch AUTH_USER
          dispatch({ type: AUTH_USER, currentAuthUser: data });
          dispatch(getPortalDetails());
          API.patch(config.API_NAME, `/user/updateLastAuth`, {}).catch(err => {
            logger.error("login():updateLastAuth err:", err);
          });
          // we have authenticated, lets navigate to /main route
          history.push("/portal");
        }
      })
      .catch(err => {
        logger.error("login():Auth.signIn() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.currentAuthenticatedUser()
// Cognito - Auth.userSession()
// This is a pass-through function to indicate that user has already authenticated
// and has a valid Amplify session.

export function validateUserSession(bypass = true) {
  return function(dispatch) {
    logger.info("validateUserSession()");

    Auth.currentAuthenticatedUser({
      bypassCache: bypass
    })
      .then(currentAuthUser => {
        logger.info(
          "validateUserSession():Auth.currentAuthenticatedUser() currentAuthUser:",
          currentAuthUser
        );
        // grab the user session
        Auth.userSession(currentAuthUser)
          .then(async session => {
            logger.info(
              "actions.validateUserSession():Auth.userSession() session:",
              session
            );
            // finally invoke isValid() method on session to check if auth tokens are valid
            // if tokens have expired, lets call "logout"
            // otherwise, dispatch AUTH_USER success action and by-pass login screen
            if (session.isValid()) {
              // fire user is authenticated
              dispatch({ type: AUTH_USER, currentAuthUser });
              dispatch(getPortalDetails());

              Auth.currentUserCredentials().then(credentials => {
                logger.warn("ACCESSKEYID:", credentials.accessKeyId);
                logger.warn("SESSIONTOKEN:", credentials.sessionToken);
                logger.warn("SECRET ACCESS KEY:", credentials.secretAccessKey);
              });
            } else {
              logger.error("user session is not valid anymore");
              // fire user is unauthenticated
              Cache.clear();
              dispatch({ type: UNAUTH_USER });
              dispatch({ type: RESET_APP });
            }
          })
          .catch(err => {
            logger.error("validateUserSession():Auth.userSession() err:", err);
            // error occured during session validation, fire user is unauthenticated
            Cache.clear();
            dispatch({ type: UNAUTH_USER });
            dispatch({ type: RESET_APP });
          });
      })
      .catch(err => {
        logger.error(
          "validateUserSession():Auth.currentAuthenticatedUser() err:",
          err
        );
        Cache.clear();
        // error occured while retrieving current auth user, fire user is unauthenticated
        dispatch({ type: UNAUTH_USER });
        dispatch({ type: RESET_APP });
      });
  };
}

// Cognito - Auth.completeNewPassword()
export function setNewPassword(cognitoUser, newPassword, history) {
  return function(dispatch) {
    logger.info("setNewPassword(): cognitoUSer, newPassword:", {
      cognitoUser,
      newPassword
    });

    // completeNewPassword (cognito)
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then(data => {
        logger.info("setNewPassword():Auth.completeNewPassword() data: ", data);

        // inspect response 'data' and check whether
        // 1. MFA confirmation is required, dispatch->AUTH_MFA
        // 2. otherwise, authenticate user, dispatch -> AUTH_USER
        if (data.challengeName === "SMS_MFA") {
          dispatch({ type: AUTH_MFA, payload: data });
        } else {
          // dispatch AUTH_USER
          Auth.currentAuthenticatedUser({
            bypassCache: true
          })
            .then(currentAuthUser => {
              Auth.userSession(currentAuthUser)
                .then(session => {
                  dispatch({ type: AUTH_USER, currentAuthUser });
                  dispatch(getPortalDetails());
                  history.push("/");
                })
                .catch(err => {
                  logger.error("usersession: ", err);
                });
            })
            .catch(err => {
              logger.error("usersession: ", err);
            });

          // we have authenticated, lets navigate to /main route
        }
      })
      .catch(err => {
        logger.error("setNewPassword():Auth.completeNewPassword() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.signOut()
export function logout(username = "", history, message) {
  return function(dispatch) {
    logger.info("logout(): username: ", username);
    Cache.removeItem("lastpath");
    // signOut (cognito)
    Auth.signOut()
      .then(data => {
        logger.info("logout():Auth.signOut() data:", data);

        dispatch({ type: UNAUTH_USER });
        dispatch({ type: RESET_APP });
        Cache.clear();
        // we have authenticated, lets navigate to /main route
        history.push("/");
        
        if (message === 'inactivity'){
          window.location.assign("/login?message=inactivity");
        } else {
          window.location.reload();
        }
      })
      .catch(err => {
        logger.error("logout():Auth.signOut() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.confirmSignIn()
export function confirmLogin({ cognitoUser, code }, history) {
  return function(dispatch) {
    logger.info("confirmLogin(): cognitoUSer, code:", {
      cognitoUser,
      code
    });

    // confirmSignIn (cognito)
    Auth.confirmSignIn(cognitoUser, code)
      .then(data => {
        logger.info("confirmLogin():Auth.confirmSignIn() data: ", data);

        // dispatch AUTH_USER
        dispatch({ type: AUTH_USER, cognitoUser });
        dispatch(getPortalDetails());

        // we have authenticated, lets navigate to /main route
        history.push("/");
      })
      .catch(err => {
        logger.error("confirmLogin():Auth.confirmSignIn() err:", err);
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.signUp()
export function register({ username, password, email, phone }, history) {
  return function(dispatch) {
    logger.info("register(): username, password, email, phone: ", {
      username,
      password,
      email,
      phone
    });

    // signUp (cognito)
    Auth.signUp(username, password, email, phone)
      .then(data => {
        logger.info("register():Auth.signUp() data:", data);

        // MFA is required for user registration
        if (
          typeof data.userConfirmed != "undefined" &&
          data.userConfirmed === false
        ) {
          dispatch({ type: REGISTER_MFA, payload: data });
        } else {
          dispatch({ type: REGISTER_USER });

          // user registration successful, lets navigate to / route
          history.push("/");
        }
      })
      .catch(err => {
        logger.error("register():Auth.signUp() err:", err);

        // error -- invoke authError which dispatches REGISTER_USER_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.confirmSignUp()
export function confirmRegistration({ cognitoUser, code }, history) {
  return function(dispatch) {
    logger.info("confirmRegistration(): cognitoUSer, code:", {
      cognitoUser,
      code
    });
    const { username } = cognitoUser.user;

    // confirmSignUp (cognito)
    Auth.confirmSignUp(username, code)
      .then(data => {
        logger.info("confirmRegistration():Auth.confirmSignUp() data: ", data);

        // A successful registration response doesnt contain idToken.
        // So we must redirect to login screen

        // dispatch REGISTER_USER_CONFIRM
        dispatch({ type: REGISTER_USER_CONFIRM });

        // we have authenticated, lets navigate to /main route
        history.push("/");
      })
      .catch(err => {
        logger.error("confirmRegistration():Auth.confirmSignUp() err:", err);

        // error -- invoke authError which dispatches AUTH_ERROR
        //dispatch(authError(err));
        dispatch({
          type: REGISTER_USER_ERROR,
          payload: err.message,
          cognitoUser
        });
      });
  };
}

// Cognito - Auth.resendSignUp()
export function resendConfirmationCode({ cognitoUser }, history) {
  return function(dispatch) {
    logger.info("resendConfirmationCode(): username: ", {
      cognitoUser
    });
    const { username } = cognitoUser.user;

    // resendSignUp (cognito)
    Auth.resendSignUp(username)
      .then(data => {
        logger.info("resendConfirmationCode():Auth.resendSignUp() data:", data);

        dispatch({ type: REGISTER_MFA, payload: cognitoUser });
      })
      .catch(err => {
        logger.error(
          "confirmForgotPassword():Auth.forgotPasswordSubmit() err:",
          err
        );

        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.forgotPassword()
export function forgotPassword({ username }, history) {
  return function(dispatch) {
    logger.info("forgotPassword(): username: ", { username });

    // forgotPassword (cognito)
    Auth.forgotPassword(username)
      .then(data => {
        logger.info("forgotPassword():Auth.forgotPassword() data:", data);

        dispatch({ type: FORGOT_PASSWORD });
      })
      .catch(err => {
        logger.error("forgotPassword():Auth.forgotPassword() err:", err);

        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

// Cognito - Auth.forgotPasswordSubmit()
export function confirmForgotPassword(
  { username, code, newPassword },
  history
) {
  return function(dispatch) {
    logger.info("confirmForgotPassword(): username, code, newPassword: ", {
      username,
      code,
      newPassword
    });

    // forgotPasswordSubmit (cognito)
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(data => {
        logger.info(
          "confirmForgotPassword():Auth.forgotPasswordSubmit() data:",
          data
        );

        // TODO - User password changed successfully, do we need to login again?
        dispatch({ type: FORGOT_PASSWORD_CONFIRM });

        history.push("/");
      })
      .catch(err => {
        logger.error(
          "confirmForgotPassword():Auth.forgotPasswordSubmit() err:",
          err
        );

        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}


export const createCheckout = (seatOption, creditOption, recaptchaValue) => {
  return async (dispatch) => {
    try {
      const checkoutResponse = await API.post(config.API_NAME, "/createcheckout", {
        body: {
          seat: seatOption,
          credits: creditOption,
          captcha: recaptchaValue
        }
      });
      if (checkoutResponse){
        if(checkoutResponse.success && checkoutResponse.session){
          return {url : checkoutResponse.session.url}
        }
        else if(checkoutResponse.error){
          return {error : checkoutResponse.session.url}
        }
      }
    } catch (e) {
      logger.error("createCheckout():", e);
    }
    return {error : "Unknown Error"}
  }
}