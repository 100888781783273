import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  missionTitle: {
    textTransform: "capitalize",
    cursor: "pointer",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  missionTooltip: {
    fontSize: "14px !important",
  },
});

export const MissionHoverView = withStyles(styles)(
  ({ classes, time, description, title, url }) => {
    return (
      <Tooltip
        classes={{ tooltip: classes.missionTooltip }}
        title={`${description} (${time} Mins)`}
        placement="bottom-start"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          className={classes.missionTitle}
        >
          {title}
        </a>
      </Tooltip>
    );
  }
);
