import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { JobMatchesGrid } from "./JobMatchesGrid";
import { bindActionCreators } from "redux";
import { getCandidateJobMatches } from "../../../../actions/candidates_actions";
import { connect } from "react-redux";

const JobMatchesComponent = ({
  classes,
  candidates: {
    selectedCandidateData: { normalJobMatches, techJobMatches },
  },
  candidateDetails,
  candidateStyles,
  candidateInterestsScore,
  isOnPrintMode,
  getCandidateJobMatches,
}) => {
  useEffect(() => {
    async function loadJobs() {
      try {
        getCandidateJobMatches();
      } catch (error) {
        console.log(error);
      }
    }
    if (!techJobMatches) {
      loadJobs();
    }
  }, [getCandidateJobMatches, techJobMatches]);

  if (!normalJobMatches || !techJobMatches) {
    return null;
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <JobMatchesGrid
          isOnPrintMode={isOnPrintMode}
          type="tech"
          jobs={techJobMatches}
          classes={classes}
          candidateStyles={candidateStyles}
          candidateInterestsScore={candidateInterestsScore}
        />
      </Grid>
      <Grid item xs>
        <JobMatchesGrid
          isOnPrintMode={isOnPrintMode}
          type="normal"
          jobs={normalJobMatches}
          classes={classes}
          candidateStyles={candidateStyles}
          candidateInterestsScore={candidateInterestsScore}
        />
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        getCandidateJobMatches,
      },
      dispatch
    ),
    dispatch,
  };
}

export const JobMatches = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobMatchesComponent);
