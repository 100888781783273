import {
  AUTH_USER,
  AUTH_MFA,
  AUTH_NEW_PASSWORD_REQUIRED,
  UNAUTH_USER,
  REGISTER_USER,
  REGISTER_USER_CONFIRM,
  REGISTER_MFA,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRM,
  AUTH_ERROR,
  AUTH_ERROR_CLEAR
} from "../actions/types";

import { Logger } from "aws-amplify";
const logger = new Logger("AUTH.REDUCER", "DEBUG");

export default function(state = {}, action) {
  switch (action.type) {
    case AUTH_USER: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: true,
        mfa: false,
        currentAuthUser: action.currentAuthUser,
        role:
          action.currentAuthUser &&
          action.currentAuthUser.signInUserSession.idToken.payload[
            "cognito:groups"
          ]
            ? action.currentAuthUser.signInUserSession.idToken.payload[
                "cognito:groups"
              ][0]
            : "default"
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_MFA: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        mfa: true,
        changePassword: false,
        currentAuthUser: action.payload
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_NEW_PASSWORD_REQUIRED: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        mfa: false,
        changePassword: true,
        currentAuthUser: action.payload
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case UNAUTH_USER: {
      const returnProps = {
        error: "",
        authenticated: false,
        currentAuthUser: {},
        role: ""
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_USER: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        register: true
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_USER_CONFIRM: {
      const returnProps = {
        ...state,
        error: "",
        authenticated: false,
        register: true,
        mfa: false,
        resendCode: false
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_MFA: {
      const returnProps = {
        ...state,
        error: "",
        mfa: true,
        resendCode: false,
        currentAuthUser: action.payload
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case REGISTER_USER_ERROR: {
      const returnProps = {
        ...state,
        error: action.payload,
        mfa: true,
        resendCode: true,
        currentAuthUser: action.currentAuthUser
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_ERROR: {
      const returnProps = {
        ...state,
        error: action.payload,
        authenticated: false
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case FORGOT_PASSWORD: {
      const returnProps = {
        ...state,
        error: "",
        forgotPasswordCode: true,
        authenticated: false
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case FORGOT_PASSWORD_CONFIRM: {
      const returnProps = {
        ...state,
        error: "",
        forgotPasswordCode: false,
        authenticated: false
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case AUTH_ERROR_CLEAR: {
      const returnProps = { ...state, error: "", authenticated: false };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    default:
      return state;
  }
}
