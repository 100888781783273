export const interestTypes = {
  doer: {
    summary: `Doer – These people prefer to follow set procedures and routines. They are likely to be drawn to occupations that include working with data and details more than with ideas. They are happy following a clear line of authority.`,
    description: `<p>Based on their interests, they appear to be a <b>DOER</b>.  The following is likely to describe them:</p>
    <p>It is important to them to <b>be accurate</b></p>
    <p>They learn best when they're <b>following rules</b></p>
    <p>They need to be careful about <b>being a perfectionist</b></p>
    <p>Others appreciate their <b>common sense</b></p>
    <p>Their ideal job involves <b>working with data and details</b></p>
    <p>They unlikely to enjoy jobs that <b>require too much creativity</b></p>`
  },

  driver: {
    summary: `Driver – These people enjoy starting up and carrying out projects. They are likely to be drawn to occupations that involve leading people and making many decisions.`,
    description: `<p>Based on their interests, they appear to be a <b>DRIVER</b>.  The following is likely to describe them:</p>
    <p>It is important to them to <b>take the lead</b></p>
    <p>They learn best when they're <b>involved in decision making</b></p>
    <p>They need to be careful about <b>taking too much control</b></p>
    <p>Others appreciate their <b>decisiveness</b></p>
    <p>Their ideal job involves <b>managing a team or department</b></p>
    <p>They unlikely to enjoy jobs that <b>are routine or predictable</b></p>`
  },
  investigator: {
    summary: `Investigator – These people enjoy working with ideas, and thinking deeply about issues. They are likely to be drawn to occupations that involve searching for facts and figuring out problems mentally.`,
    description: `<p>Based on their interests, they appear to be an <b>INVESTIGATOR</b>.  The following is likely to describe them:</p>
    <p>It is important to them to <b>use their brain</b></p>
    <p>They learn best when they're <b>exploring complicated issues</b></p>
    <p>They need to be careful about <b>over-thinking issues</b></p>
    <p>Others appreciate their <b>intelligence</b></p>
    <p>Their ideal job involves <b>solving difficult problems</b></p>
    <p>They're unlikely to enjoy jobs that <b>involve simple tasks</b></p>`
  },
  facilitator: {
    summary: `Facilitator - These people enjoy working with, communicating with, and teaching others. They are likely to be drawn to occupations that involve helping or providing service to others.`,
    description: `<p>Based on their interests, they appear to be a <b>FACILITATOR</b>.  The following is likely to describe them:</p>
    <p>It is important to them to <b>help people</b></p>
    <p>They learn best when they're <b>with other people</b></p>
    <p>They need to be careful about <b>getting too personally involved</b></p>
    <p>Others appreciate their <b>caring side</b></p>
    <p>Their ideal job involves <b>helping others</b></p>
    <p>They're unlikely to enjoy jobs that <b>require them to work in isolation</b></p>`
  },
  crafter: {
    summary: `Crafter - These people enjoy activities that include practical, hands-on problems and solutions. They may like to deal with plants, animals, and real-world materials like wood, tools, and machinery. They are likely to be drawn to occupations that require working outside, and do not involve a lot of paperwork or working closely with others`,
    description: `<p>Based on their interests, they appear to be a <b>CRAFTER</b>.  The following is likely to describe them:</p>
    <p>It is important to them to use their <b>practical skills</b></p>
    <p>They learn best when they're able to <b>experiment and try things out</b></p>
    <p>They need to be careful about their <b>need to fix things that aren't broken</b></p>
    <p>Others appreciate their ability to <b>fix or design things</b></p>
    <p>Their ideal job involves <b>practical, hands-on work</b></p>
    <p>They're unlikely to enjoy jobs that <b>require a lot of thinking</b></p>`
  },
  creator: {
    summary: `Creator -  These people enjoy creating things. They are likely to be drawn to occupations that require self-expression working without following a clear set of rules.`,
    description: `<p>Based on their interests, they appear to be a <b>CREATOR</b>.  The following is likely to describe them:</p>
    <p>It is important to them to be <b>creative</b></p>
    <p>They learn best when they're <b>given creative freedom</b></p>
    <p>They need to be careful about <b>chasing after impractical ideas</b></p>
    <p>Others appreciate their <b>creativity</b></p>
    <p>Their ideal job involves <b>expressing their creativity</b></p>
    <p>They're unlikely to enjoy <b>traditional jobs that restrict their creativity</b></p>`
  }
};
