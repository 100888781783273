import {ADD_QUALIFICATION, UPDATE_QUALIFICATION, FETCH_QUALIFICATIONS, LOADING_QUALIFICATION} from "../actions/types";
import _ from 'lodash';

export default function(state = {}, action) {

    switch (action.type) {
        case UPDATE_QUALIFICATION:
            let qualification = [];
            _.each(state.qualifications, (qual) => {
                if (action.data.search === qual.qual_id){
                    qual.display = action.data.display;
                }
                qualification.push(qual);
            })
            return {...state, qualifications: qualification};
        case LOADING_QUALIFICATION:
            return {...state, loading: action.data};
        case ADD_QUALIFICATION:
            return {...state};
        case FETCH_QUALIFICATIONS:
            return {...state, loading: true, qualifications: action.data.Items.slice(),LastEvaluatedKey: action.data.LastEvaluatedKey, loaded: true};
        default:
        return state;
      }
}