import moment from "moment";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
 // Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
  
} from "recharts";

export const CandidateActivityBarChart = ({
  data,
  dateKey = "date",
  valueKey = "duration"
}) => {
  const dateTickFormatter = tick => {
    // if (data.length < 15) {
      return moment(tick).format("DD");
    // }
    // return new Date(tick).get();
  };


  return (
    <div style={{ height: "10em" }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={0} dataKey={dateKey} tickFormatter={dateTickFormatter} />
          <Tooltip
            wrapperStyle={{
              background: "rgba(0,0,0,0.5)",
              padding: "0.5em",
              color: "white"
            }}
            content={props => {
              if (props.payload.length > 0) {
                const [{ dataKey, payload }] = props.payload;
                return <div>{payload[dataKey]} minutes</div>;
              }
              return null;
            }}
          />
          <YAxis />
          <Bar dataKey={valueKey} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
