import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { Ability } from "@casl/ability";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Amplify from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { AbilityContext } from "./components/Abilities/";
import { ErrorBoundary } from "./components/Common/ErrorBoundary";
import * as Sentry from "@sentry/browser";
import config from "./config/";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
const ability = new Ability();

//console.log(config)
//SENTRY INIT
Sentry.init({
  dsn: "https://b0a89123415f4055a5a70acbf60cf93e@sentry.io/1882439",
  release:
    process.env.REACT_APP_STAGE +
    "@" +
    process.env.REACT_APP_VERSION +
    (process.env.REACT_APP_BUILD
      ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
      : ""),
      environment: process.env.REACT_APP_STAGE
});

window.ability = ability;
Amplify.configure({
  Auth: {
    identityPoolId: config.AWS_COGNITO_IDENTITY_POOL_ID,
    region: config.AWS_REGION,
    userPoolId: config.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.AWS_COGNITO_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.API_NAME,
        endpoint: config.API_ENDPOINT,
        region: config.AWS_REGION
      }
    ]
  },
  Storage: {
    AWSS3: {
        bucket: config.PORTAL_BUCKET,
        region: config.AWS_REGION,
    }
}
});
//GLOBAL THEME
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff6505",
      contrastText: "#fff"
    },
    secondary : {
      main : "#1faa00"
    }
  },
  
});

theme.shadows[4] =
  "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.02),0px 1px 10px 0px rgba(0,0,0,0.12)";

ReactDOM.render(
  <AbilityContext.Provider value={ability}>
    <Provider store={configureStore()}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App ability={ability} />
          </MuiPickersUtilsProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </AbilityContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
