import React from "react";
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from "recharts";

const COLORS = [
  "#D89E22",
  "#948BFF",
  "#34CFFF",
  "#FFB61C",
  "#FF5255",
  "#53D28E",
];

export const AnalyticsPieChart = ({ chartData }) => {
  const chart = Object.keys(chartData).map((item) => ({
    name: item,
    value: chartData[item],
  }));

  return (
    <div style={{ height: "20em" }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={chart}
            dataKey="value"
            nameKey="name"
            cx="50%"
            paddingAngle={0}
            cy="50%"
            innerRadius={0}
            outerRadius={100}
            fill="#82ca9d"
            cornerRadius={0}
            animationDuration={600}
          >
            {chart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
