import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  skilllinecont: {
    height: 23,
    borderRadius: 4,
    backgroundColor: "#ffffff",
    border: "1px solid #EDEFF2",
    marginTop: 0,
    marginBottom: 4,
    paddingLeft: 0,
    paddingRight: 5,
    overflow: "hidden",
  },
  skilllinecontgrow: {
    minHeight: 23,
    borderRadius: 4,
    backgroundColor: "#ffffff",
    border: "1px solid #EDEFF2",
    marginTop: 0,
    marginBottom: 4,
    paddingLeft: 0,
    paddingRight: 5,
    overflow: "visible",
  },
  line: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 10,
    color: "#202A3E",
    lineHeight: "23px",

    "&::first-letter": {
      textTransform: "capitalize",
    },
    // maxWidth: "100%"
  },
  description: {
    color: "#B4B8BF",
    fontSize: 9,
    marginTop: 9,
    paddingRight: 10,
    paddingLeft: 10,
    lineHeight: "12px",
    whiteSpace: "normal",
    marginBottom: 10,
  },

  points: {
    width: 45,
    marginLeft: 7,
  },
  arrow: {
    width: 15,
    paddingRight: 0,
    cursor: "pointer",
    color: "#cccccc"
  },
  point: {
    backgroundColor: "#DDE0E5",
    width: 6,
    height: 6,
    borderRadius: 6,
    display: "inline-block",
    marginLeft: 3,
  },
  pointselected: {
    backgroundColor: "#53D28E",
    width: 6,
    height: 6,
    borderRadius: 6,
    display: "inline-block",
    marginLeft: 3,
  },
  descHide: {
    display: "none",
  },
  descShow: {
    minHeight: 20,
    width: "100%",
  },
  rotated: {
    transform: "rotate(180deg)",
  },
});

class SkillLine extends Component {
  state = {
    showMore: false,
  };

  handleOpen = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  render() {
    const {
      skill: { level = 0, name = "", description = "No description." },
      classes,
      onClick
    } = this.props;

    const { showMore } = this.state;

    return (
      <Grid
        style={{cursor: "pointer"}}
        onClick={onClick}
        container
        direction="column"
        className={showMore ? classes.skilllinecontgrow : classes.skilllinecont}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item className={classes.points}>
              <div
                className={level >= 1 ? classes.pointselected : classes.point}
              >
                {" "}
              </div>
              <div
                className={level >= 2 ? classes.pointselected : classes.point}
              >
                {" "}
              </div>
              <div
                className={level >= 3 ? classes.pointselected : classes.point}
              >
                {" "}
              </div>
              <div
                className={level >= 4 ? classes.pointselected : classes.point}
              >
                {" "}
              </div>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant="body2" className={classes.line} noWrap>
                {name}
              </Typography>
            </Grid>
            {/*
        
        */}
{/* 
            <Grid item className={classes.arrow}>
              {<PlayCircleFilledIcon style={{width: "0.5em"}} className={showMore ? classes.rotated : null} />}
            </Grid> */}
            
          </Grid>
        </Grid>
        <Grid item className={showMore ? classes.descShow : classes.descHide}>
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SkillLine);
