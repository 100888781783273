import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { ReactComponent as InterestsIcon } from "./icons/interests.svg";
import { ReactComponent as SkillsIcon } from "./icons/skill.svg";
import { ReactComponent as StylesIcon } from "./icons/styles.svg";
import { InterestsPie } from "./InterestsPie";
import { SkillsList } from "./SkillsList";
import { StylesRadar } from "./StylesRadar";
import CloseIcon from "@material-ui/icons/Close";

const matchTypes = [
  {
    type: "skills",
    title: "Matching Skills",
    buttontitle: "Skill Match",
    subTitle: "Scroll down to see all your skills that match the current job",
    view: (props) => <SkillsList {...props} />,
    icon: (props) => (
      <SkillsIcon {...props} className={props.classes.matchIcon} />
    ),
  },
  {
    type: "interests",
    title: "Matching Interests",
    buttontitle: "Interest Match",
    subTitle: "All your interests that match the current job",
    view: (props) => <InterestsPie {...props} />,
    icon: (props) => (
      <InterestsIcon {...props} className={props.classes.matchIcon} />
    ),
  },
  {
    type: "styles",
    title: "Matching Styles",
    buttontitle: "Styles Match",
    subTitle: "All your styles that match the current job",
    view: ({ job, candidate, ...props }) => {
      const radarMapping = {
        SPC: "Complexity",
        //DMA: "Speed",
        PSA: "Accuracy",
        RTF: "Responsiveness",
        ADY: "Adaptability",
        //RLS: "Learning",
        CONF: "Confidence",
        CONT: "Control",
        COMM: "Commitment",        
        
      };
      const radarData = Object.keys(radarMapping).map((key) => ({
        label: radarMapping[key],
        id: radarMapping[key],
        You: candidate.latestAssessmentResult.results[key].percentage,
        Vacancy: job[key],
      }));
      return <StylesRadar {...props} data={radarData} />;
    },
    icon: (props) => (
      <StylesIcon {...props} className={props.classes.matchIcon} />
    ),
  },
];

const styles = (theme) => ({
  matchIcon: {
    height: 40,
    width: 40,
    marginLeft: 18,
  },
  headerContainer: {
    marginLeft: "1em",
  },
  bodyContainer: {
    margin: 20,
    height: "80%"
  },
  footerContainer: {
    padding: 18,
    background: "#F8F9FA",
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  closeButton: {
    marginRight: 10
  },
  closeIcon: { color: "black" },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    color: "#202A3E",
  },
  subTitleText: {
    color: "#5A6375",
    fontSize: 10,
  },
  coloredText: {
    color: "#5B657D",
    height: 25,
    //paddingTop: 1,
    fontSize: 10,
  },
  divider: {
    backgroundColor: "#F5F6F7",
  },
  dialog: {
    margin: 20,
  },
  rightContainer: {
    width: 520,
    height: "100%",
    position: "absolute",
    right: "-1000px",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
    transition: "all 0.5s"
  }
});

export const MatchBreakdown = withStyles(styles)(
  ({ defaultType = "skills", classes, onClose, job, candidate }) => {
    const [currentViewIndex, setCurrentViewIndex] = useState(
      matchTypes.findIndex(({ type }) => type === defaultType)
    );
    const currentView = matchTypes[currentViewIndex];
    const getNextIndex = (index) => (index === 2 ? 0 : index + 1);
    const getPreviousIndex = (index) => (index === 0 ? 2 : index - 1);

    useEffect(() => {
      setCurrentViewIndex(
        matchTypes.findIndex(({ type }) => type === defaultType)
      );
    }, [defaultType]);

    let [animate, setAnimate] = React.useState(false); 
    let [right, setRight] = React.useState("-1000px"); 

    if (!animate){
      setTimeout(() => {
        setAnimate(true);
        setRight("0px");
      }, 300)
    }
    return (
      <div style={{right: right}} className={classes.rightContainer}>
        <Paper style={{ paddingTop: 20, borderRadius:0, height: "100%"}}>    
          <div>
            <Grid container justify="flex-end">
              <Grid item>
                <IconButton onClick={() => {
                  setRight("-1000px");
                  setTimeout(() => {
                    onClose();
                  }, 300);
                }} className={`${classes.closeButton} close-button`}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid item zeroMinWidth>
              {currentView.icon({classes,})}
            </Grid>
            <Grid item className={classes.headerContainer} xs zeroMinWidth>
              <Typography variant="h5" className={classes.headerText}>
                {currentView.title}
              </Typography>
              <Typography variant="body1" className={classes.subTitleText}>
                {currentView.subTitle}
              </Typography>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <div className={classes.bodyContainer}>
            {currentView.view({ job, candidate })}
          </div>

          <div className={classes.footerContainer}>
            <Grid container justify="space-between">
              <Grid item xs="auto">
                <Button
                  className={`${classes.coloredText} previous-button`}
                  variant="outlined"
                  color="default"
                  size="small"
                  onClick={() =>
                    setCurrentViewIndex(getPreviousIndex(currentViewIndex))
                  }
                  startIcon={<ChevronLeftIcon />}
                >
                  {matchTypes[getPreviousIndex(currentViewIndex)].buttontitle}
                </Button>
              </Grid>
              <Grid item xs="auto" style={{marginRight: 35}}>
                <Button
                  className={`${classes.coloredText} next-button`}
                  variant="outlined"
                  color="default"
                  size="small"
                  endIcon={<ChevronRightIcon />}
                  onClick={() =>
                    setCurrentViewIndex(getNextIndex(currentViewIndex))
                  }
                >
                  {matchTypes[getNextIndex(currentViewIndex)].buttontitle}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
  );
}
);