import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import React, { Component } from "react";
import { Storage } from "aws-amplify";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  confirmLogin as confirmLoginAction,
  login as loginAction,
  setNewPassword as setPasswordAction,
} from "../../actions/auth_actions";
import { AUTH_ERROR_CLEAR } from "../../actions/types";
import * as ROUTES from "../../constants/routes";
import { getBrowserDetail } from "../Common/utils";
import Header from "./Header";

const unSupportedMessage = `WARNING: We're very sorry, but the browser you are using is no longer supported. We recommend only using the latest versions of Chrome, Edge, Firefox and Safari before trying to use the Skillsminer Portal.`;
const versionsBehindMessage = `WARNING: The browser you are using is more than 2 versions behind the current version. We always recommend and can only support the latest browser version(s).`;

const styles = (theme) => ({
  root: {},
  margin: {
    margin: 20,
  },
  textField: {
    width: 360,
  },
  main: {
    marginTop: 30,
    width: 400,
    margin: "auto",
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: 360,
  },
  progress: {
    //height: 2
  },
  progressCont: {
    height: 5,
  },
  marginLabel: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  version: {
    fontSize: 9,
    color: "#bbb",
    textAlign: "right",
    marginRight: 10,
  },
});

class Login extends Component {
  state = {
    email: "",
    password: "",
    npassword: "",
    showPassword: false,
    open: false,
    vertical: "top",
    horizontal: "center",
    error: "",
    loading: false,
    version:
      (process.env.REACT_APP_REGION === "eu-west-2" ? "uk." : "ireland.") +
      process.env.REACT_APP_STAGE +
      "@" +
      process.env.REACT_APP_VERSION +
      (process.env.REACT_APP_BUILD
        ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
        : ""),
    manualURL: "",
  };

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.changePass = this.changePass.bind(this);
  }
  handleClose = () => {
    this.setState({ open: false });
    this.props.dispatch({ type: AUTH_ERROR_CLEAR });
  };
  handleForceClose = () => {
    this.setState({ open: false, forceClose: true});
  }
  async componentDidMount() {
    //Auth.currentCredentials();
    //global.AWS.config.credentials = await Auth.currentCredentials();

    Storage.get("manuals/portalmanual.pdf")
      .then((result) =>
        this.setState({
          manualURL: result,
        })
      )
      .catch((error) => {
        console.error(error);
      });

    const [browser, version] = getBrowserDetail().split(" ");
    switch (browser.toLowerCase()) {
      case "ie" || "opera":
        this.setState({ browserErrorText: unSupportedMessage });
        return;
      case "chrome":
        if (parseInt(version) < 76) {
          this.setState({ browserErrorText: versionsBehindMessage });
        }
        return;
      case "edge":
        if (parseInt(version) < 15) {
          this.setState({ browserErrorText: versionsBehindMessage });
        }
        return;
      case "safari":
        if (parseInt(version) < 11) {
          this.setState({ browserErrorText: versionsBehindMessage });
        }
        return;
      case "firefox":
        if (parseInt(version) < 69) {
          this.setState({ browserErrorText: versionsBehindMessage });
        }
        return;
      default:
        return;
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage !== prevProps.errorMessage &&
      this.props.errorMessage !== ""
    ) {
      //temporary fix bypassing Auth.completeNewPassword device key error
      if (this.props.errorMessage && this.props.errorMessage ==="Invalid device key given."){
        window.location.reload();
      }
      else{
        this.setState({
          open: true,
          error: this.props.errorMessage,
          loading: false,
        });
      }
    }
    if (this.props.changePassword !== prevProps.changePassword) {
      this.setState({
        loading: false,
      });
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  login(e) {
    e.preventDefault();

    let username = this.state.email.trim();
    let password = this.state.password.trim();

    this.setState({
      loading: true,
      error: "",
      open: false,
    });

    this.props.loginAction({ username, password }, this.props.history);
  }

  changePass(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      error: "",
      open: false,
    });

    this.props.setPasswordAction(
      this.props.currentAuthUser,
      this.state.npassword,
      this.props.history
    );
  }

  render() {
    let {open,error} = this.state;
    const {
      horizontal,
      vertical,
      loading,
      version,
      forceClose,
      browserErrorText,
    } = this.state;
    const { classes, changePassword } = this.props;

    let queryDict = {};
    window.location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]})
    let duration = 3000;

    if (queryDict.message === "inactivity" && !error && !forceClose){
      duration = false;
      open = true;
      error = "Sorry you have been logged out due to inactivity of 60 minutes, please sign in again.";
    }

    let {password,email} = this.state;

    if (queryDict.email && queryDict.pass && !this.state.queryParamsLoaded){
      password = decodeURIComponent(queryDict.pass);
      email = queryDict.email;
      this.setState({
        password: password,
        email: email,
        queryParamsLoaded: true
      });
    }

    return (
      <div className={classes.root}>
        <div className={classes.progressCont}>
          {loading && <LinearProgress className={classes.progress} />}
        </div>
        <Header />
        {!!browserErrorText ? (
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
            style={{ paddingTop: "1em" }}
          >
            <Grid item xs="4" style={{ color: "red", textAlign: "center" }}>
              <div>
                WARNING: We're very sorry, but the browser you are using is no
                longer supported. We recommend only using the latest versions of
                Chrome, Edge, Firefox and Safari before trying to use the
                Skillsminer portal.
              </div>
            </Grid>
          </Grid>
        ) : null}

        <Paper className={classes.main}>
          {!changePassword && (
            <form className={classes.container} onSubmit={this.login}>
              <TextField
                id="outlined-email-input"
                label="Email"
                className={classNames(classes.margin, classes.textField)}
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                autoFocus
                required
                defaultValue={email}
                onChange={this.handleChange("email")}
              />
              <TextField
                id="password"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type={this.state.showPassword ? "text" : "password"}
                label="Password"
                autocomplete="current-password"
                onChange={this.handleChange("password")}
                defaultValue={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              <Button
                variant="contained"
                className={classNames(classes.margin, classes.button)}
                size="large"
                color="primary"
                type="submit"
              >
                Login
              </Button>
              <Typography variant="body2" className={classes.marginLabel}>
                Forgot password?{" "}
                <Link component={RouterLink} to={ROUTES.FORGET}>
                  Reset here
                </Link>
              </Typography>
              {this.state.manualURL && (
                <Typography variant="body2" className={classes.marginLabel}>
                  <Link
                    href={this.state.manualURL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Download
                  </Link>{" "}
                  Portal User Manual
                </Typography>
              )}
              <Typography variant="body2" className={classes.marginLabel}>
                <Link id="youtube-link" href="https://www.youtube.com/watch?v=avXHHVsvvZQ&list=PLaqkddI5TjYUZdQTDb69OSKDadsnpvIx5" target="_blank" rel="noopener noreferrer">
                  Visit
                </Link>
                {" "} our YouTube Channel for Training Videos
              </Typography>
            </form>
          )}

          {changePassword && (
            <form className={classes.container} onSubmit={this.changePass}>
              <Typography variant="subtitle2" className={classes.marginLabel}>
                Please set a new password!
              </Typography>
              <TextField
                id="npassword"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type={this.state.showPassword ? "text" : "password"}
                label="New Password"
                autocomplete="new-password"
                value={this.state.npassword}
                onChange={this.handleChange("npassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              <Button
                variant="contained"
                className={classNames(classes.margin, classes.button)}
                size="large"
                color="primary"
                type="submit"
              >
                Change password
              </Button>
            </form>
          )}
          <div className={classes.version}>{getBrowserDetail()}</div>
          <div className={classes.version}>{version}</div>
        </Paper>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={!!open}
          className={classes.snack}
          onClose={this.handleClose}
          onClick={this.handleForceClose}
          autoHideDuration={duration}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classNames(classes.error)}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{error}</span>}
          />
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //console.log('Login.mapStateToProps() state:', state);
  //console.log('Login.mapStateToProps() ownProps:', ownProps);
  return {
    errorMessage: state.auth.error,
    mfaRequired: state.auth.mfa,
    changePassword: state.auth.changePassword,
    currentAuthUser: state.auth.currentAuthUser,
  };
}

function mapDispatchToProps(dispatch) {
  //console.log('Login.mapDispatchToProps() ');
  // return {
  //   ...bindActionCreators(actionCreators, dispatch),
  //   dispatch
  // };

  return {
    ...bindActionCreators(
      {
        loginAction,
        confirmLoginAction,
        setPasswordAction,
      },
      dispatch
    ),
    dispatch,
  };
}

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withStyles(styles)(Login);
