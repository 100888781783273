import { withStyles, Grid, Typography, Box } from "@material-ui/core";
import { ResponsivePie } from "@nivo/pie";
import React from "react";
import _ from "lodash";

const styles = (theme) => ({
  pieContainer: {
    height: 220,
    position: "relative",
    minWidth: 280,
    maxWidth: 450,
    zIndex: 1
  },
  secondPie: {
    position: "absolute",
    top: 0,
    zIndex: -1,
    height: "100%",
    width: "100%",
  },
  legend: {
    fontSize: 11,
    color: "#5A6375",
    textAlign: "center",
    marginTop: 20
  },
  bodyParagraph: {
    marginTop: 15,
    // marginLeft: 10,
    marginBottom: 15
  },
  m : {
    marginRight: 30
  },
  alignCenter: {
    textAlign: "center"
  },
  marginTop15: {
    marginTop: 15
  },
  overflow: {
    overflowY: 'auto',
    height: "100%"
  }
});

function mapToCandidate(graphData) {

  const interestsdata = graphData || [];

  var newdata = [];
  var sum = 0;

  interestsdata.forEach((item) => {
    sum += item.value;
  });

  interestsdata.forEach((item) => {
    newdata.push({
      id: item.id,
      label: item.label,
      value: parseFloat(((item.value / sum) * 100).toFixed(2)),
    });
  });

  return newdata;
}

// function mapDispatchToProps(dispatch) {
//   return {
//     ...bindActionCreators({}, dispatch),
//     dispatch,
//   };
// }

export const InterestsPie = (
  withStyles(styles)((props) => {

    let selectedJob = props.job;
    let classes = props.classes;
    let [difference, setDifference] = React.useState("Please click outer circle");
    let [yourValue, setYourValue] = React.useState("Please click outer circle");
    let [jobValue, setJobValue] = React.useState("Please click outer circle");
    let [textColor, setTextColor] = React.useState("#000000");
    let candidateInterestsData = [];
    const {
      interests: {
        interestsOriginal: {
          creator,
          investigator,
          driver,
          crafter,
          doer,
          facilitator,
        } = {},
      } = {},
    } = props.candidate.latestAssessmentResult || {};
    const profileInterestsGraphData = [
      {
        id: "Doer",
        label: "Doer",
        value: doer,
      },
      {
        id: "Driver",
        label: "Driver",
        value: driver,
      },
      {
        id: "Investigator",
        label: "Investigator",
        value: investigator,
      },
      {
        id: "Facilitator",
        label: "Facilitator",
        value: facilitator,
      },
      {
        id: "Crafter",
        label: "Crafter",
        value: crafter,
      },
      {
        id: "Creator",
        label: "Creator",
        value: creator,
      },
    ];

    candidateInterestsData =  mapToCandidate(profileInterestsGraphData);
    const jobInterests = candidateInterestsData.map((key) => {
      if (key.id && selectedJob && selectedJob[key.id.toLowerCase()] !== undefined){
        return {
          id: key.id,
          label: key.id,
          value: selectedJob[key.id.toLowerCase()],
        }
      } else {
        return {
          id: key.id,
          label: key.id,
          value: 0
        }
      }
    });
    var newdata = [];
    var sum = 0;

    const dataToPress = (e) => {
      let candidateData;
      _.each(candidateInterestsData, (d) => {
        if (d.id === e.id){
          candidateData = d;
        }
      });

      difference = (candidateData.value - e.value);
      let differenceNumber = difference;
      difference = difference.toFixed(1) + "%";
      setDifference(difference);
      setYourValue(`${candidateData.id} : ${candidateData.value}%`)
      setJobValue(`${e.id} : ${e.value}%`)

      if (differenceNumber < 0){
        setTextColor("#E16618")
      } else {
        setTextColor("#87D190")
      }

    };

    const loadpieChart = () => {
      return <Grid container className={classes.overflow} >
        <Grid item xs={12} sm={12} md={12}>
        <div className={classes.pieContainer}>
            <ResponsivePie
              margin={{ top: 20, right: 100, bottom: 10, left: 10 }}
              //pixelRatio={1.5}
              slicesLabelsTextColor="#ffffff"
              enableSlicesLabels={true}
              innerRadius={0.70}
              data={newdata}
              onClick={dataToPress}
              isInteractive={false}
              slicesLabelsSkipAngle={10}
              colors={[
                "#948BFF",
                "#34CFFF",
                "#FFB61C",
                "#FF5255",
                "#53D28E",
                "#D89E22",
              ]}
              enableRadialLabels={false}
              legends={[
                {
                  anchor: "right",
                  direction: "column",
                  translateX: 110,
                  itemWidth: 100,
                  itemHeight: 18,
                  // itemTextColor: "#999",
                  symbolSize: 10,
                  symbolShape: "square",
                  itemTextColor: "#5A6375",
                },
              ]}
              theme={{
                legends: {
                  text: {
                    fontFamily: "Roboto",
                  },
                },
              }}
            />
            <div className={classes.secondPie}>
              <ResponsivePie
                className={classes.secondPie}
                slicesLabelsTextColor="#ffffff"
                slicesLabelsSkipAngle={10}
                colors={[
                  "#948BFF",
                  "#34CFFF",
                  "#FFB61C",
                  "#FF5255",
                  "#53D28E",
                  "#D89E22",
                ]}
                enableSlicesLabels={true}
                margin={{ top: 60, right: 90, bottom: 50, left: 0 }}
                isInteractive={false}
                onClick={dataToPress}
                innerRadius={0.6}
                data={candidateInterestsData}
                enableRadialLabels={false}
              />
            </div>
          </div>
          <Typography variant="body2" className={classes.legend}>
          Outer circle: <strong className={classes.m}>Job</strong>
          Inner Circle: <strong>You</strong>
        </Typography>
        </Grid>
        <Grid item sm={1}> </Grid>
        {/* <Grid item xs={12} sm={12} md={3}>
            <Box display={{ xs: 'none', sm:'none', md: 'block' }}>
          <Typography className=""> Your Profile </Typography>
          <Typography variant="body2" className={classes.bodyParagraph}> {yourValue} </Typography>
          <Typography> Job Requirement</Typography>
          <Typography variant="body2" className={classes.bodyParagraph}> {jobValue} </Typography>
          <Typography> Interest Gap </Typography>
          <Typography variant="body2" style={{"color": textColor}} className={classes.bodyParagraph}> {difference} </Typography>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <Box className={classes.alignCenter}>
          <Typography className={classes.marginTop15}> Your Profile </Typography>
          <Typography variant="body2" className={classes.bodyParagraph}> {yourValue} </Typography>
          <Typography> Job Requirement</Typography>
          <Typography variant="body2" className={classes.bodyParagraph}> {jobValue} </Typography>
          <Typography> Interest Gap </Typography>
          <Typography variant="body2" style={{"color": textColor}}  className={classes.bodyParagraph}> {difference} </Typography>
          </Box>
        </Grid>
        <Grid container justify="center"  spacing={4}>
          <Grid style={{height: 100}} item xs={12}></Grid>
        </Grid>
      </Grid>
    }
    
    jobInterests.forEach((item) => {
      if (item.value) {
        sum += parseInt(item.value);
      }
    });

    jobInterests.forEach((item) => {
      if (item.value) {
        newdata.push({
          id: item.id,
          label: item.label,
          value: parseFloat(((parseInt(item.value) / sum) * 100).toFixed(1)),
        });
      } else {
        newdata.push({
          id: item.id,
          label: item.label,
          value: 0,
        });
      }
    });

    let dataToRender = <> </>;
    if (newdata.length > 0) {
      dataToRender = loadpieChart();
    }
    return dataToRender;
  
  })
);
