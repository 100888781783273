import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";

const styles = theme => ({
  logo: {
    height: 128,
    width: 128,
    marginTop: 60,
    marginLeft: "auto",
    marginRight: "auto"
  },
  main: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 300
  }
});

class Header extends Component {
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <CardMedia
          className={classes.logo}
          image="/images/logo_gray.png"
          title="Skillzminer"
        />
      </div>
    );
  }
}

export default withStyles(styles)(Header);
