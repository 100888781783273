import {
  AppBar,
  /*eslint-disable */
  Button,
  /*eslint-enable */
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";

/*eslint-disable */
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
/*eslint-enable */
import moment from "moment";
import React, { useEffect } from "react";
import { CandidateActivityBarChart } from "./CandidateActivityBarChart";
import { MissionHoverView } from "./CandidateSummaryView/MissionHoverView";
import { CandidateAnalytics } from "./CandidateAnalytics";
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    marginLeft: 20,
    marginTop: 10,
    fontSize: 28,
  },
  subtitle: {
    //fontSize: 28
    marginLeft: 20,
    marginTop: 8,
  },
  subtitle2: {
    fontSize: 16,
    marginLeft: 0,
    marginTop: 30,
    marginBottom: 10,
  },
  sectionTitle: {
    marginTop: 40,
    marginBottom: 20,
  },
  center: {
    textAlign: "center",
    display: "block",
    paddingTop: 14,
    border: "1px solid #ddd",
  },
  graphBg: {
    backgroundColor: "#fbfbfb",
    border: "1px solid #e8e8e8",
  },
  subheading: {
    marginLeft: 20,
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#444",
  },
  heading: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "10%",
    flexShrink: 0,
  },
  heading1: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: 20,
  },
  subheading1: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#666",
    marginLeft: 10,
    display: "inline-block",
  },

  resultText: {
    fontSize: theme.typography.pxToRem(14),
  },
  resultText1: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  divid: {
    marginBottom: 20,
    marginTop: 20,
  },
  reassess: {
    marginLeft: 15,
    marginTop: 15,
  },
  itemre: {
    marginTop: 10,
    marginBottom: 5,
  },
  header: {
    padding: theme.spacing(3),
  },
  avatarlarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1),
  },
  light: {
    color: "#666",
  },
  smallicons: {
    color: "#666",
  },
  itemtitle: {
    fontSize: 12,
  },
  prog: {
    marginTop: -12,
  },
  itemgrid: {
    minHeight: 22,
    marginBottom: theme.spacing(1),
  },
  name: {
    maxWidth: 400,
    fontSize: "2rem",
    fontWeight: 500,
  },
  tableCell: {
    width: "15em",
  },
  challengesTable: {
    width: "100%",
  },
  missionsTable: {
    width: "100%",
  },
  iconPosition: {
    position: "absolute",
    top: 18,
    right: 35
  }
});

export const CandidateEngagement = withStyles(styles)(
  ({
    candidate,
    missions,
    classes,
    getCandidateEngagements,
    portaldetails,
    
  }) => {
    let [showResources, setShowResources] = React.useState(false);
    let [showCourses, setShowCourses] = React.useState(false);
    let [showMissions, setShowMissions] = React.useState(false);
    let [showChallenges, setShowChallenges] = React.useState(false);
    let [showEngagement, setShowEngagement] = React.useState(true);
    let [showJobs, setShowJobs] = React.useState(true);


    useEffect(() => {
      async function fetchEngagements() {
        try {
          await getCandidateEngagements();
        } catch (error) {}
      }
      fetchEngagements();
    }, [getCandidateEngagements]);

    if (!candidate.assessmentCompleted) {
      return (
        <Grid>
          <Grid container item xs justify="center">
            <Typography variant="subtitle2">
              This candidate has not completed the assessment yet.
            </Typography>
          </Grid>
        </Grid>
      );
    }
    const appEngagements = candidate.engagements;


    const { jobsApplied, jobsSaved, jobsViewed, qualsViewed, qualsSaved, qualsApplied, resourceViewed = [], coursesViewed = [] } = candidate;

    const type3Interventions = portaldetails.interventions.filter(
      ({ type }) => type === 3
    );

    let orgcontracts = portaldetails.orgcontracts;
    let hideQualifications = true;

    _.each(orgcontracts,(contract) => {
      if (contract.jobsType > 0) {
        hideQualifications = false;
      }
    });

    
    let d = new Date();
    let [chartYear, setChartYear]   = React.useState(d.getFullYear());
    let [chartMonth, setChartMonth] = React.useState(d.getMonth());
    let dates = [];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const getDaysInMonth = function(month,year) {
      return new Date(year, month, 0).getDate();
    };

    
    if (appEngagements && appEngagements.chartData)  {
      let days = getDaysInMonth(chartYear, chartMonth); 

      for (var i = 1; i !== days + 1; i++) {
        let d = new Date(chartYear, chartMonth , i ).toISOString();
        let m = new Date(chartYear, chartMonth , i ).getMonth();
        if (m !== chartMonth) continue;
        let findD = _.find(appEngagements.chartData, (data) => {
          return data.date === d;
        });
        let duration = (findD && findD.duration) ? findD.duration : 0;
        dates.push({date: d, duration: duration})
      }
      console.log(dates)
    }
    

    const goBackMonth = () => {
      chartMonth = chartMonth - 1;
      if (chartMonth === -1) {
        chartMonth = 11;
        chartYear = chartYear - 1; 
        setChartMonth(chartMonth);
        setChartYear(chartYear);
      } else {
        setChartMonth(chartMonth); 
      }
    }

    const goForwardMonth = () => {
      chartMonth = chartMonth + 1;
      if (chartMonth === 12) {
        chartMonth = 1;
        chartYear = chartYear + 1; 
        setChartMonth(chartMonth);
        setChartYear(chartYear);
      } else {
        setChartMonth(chartMonth); 
      }
    }
    
    const getMonthYear = () => {
      return monthNames[chartMonth] + " " + chartYear;
    }

    const showQualifications = () => {

      if (!hideQualifications){
      return <><Grid item>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.sectionTitle}
        >
          <Toolbar variant="dense" className={classes.center}>
            <Typography variant="h6" color="inherit">
              Qualifications and Training
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        {qualsViewed && (
          <CandidateAnalytics
            jobsViewed={qualsViewed}
            jobsApplied={qualsApplied}
            jobsSaved={qualsSaved}
            isQual={true}
          />
        )}
      </Grid></>
      } else {
        return <></>
      }
    }


    return (
      <>
        {/*

 <Grid item>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.sectionTitle}
          >
            <Toolbar variant="dense" className={classes.center}>
              <Typography variant="h6" color="inherit">
                Rewards
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
        */}

        <Grid item>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.sectionTitle}
          >
            <Toolbar variant="dense" className={classes.center}>
              <Typography variant="h6" color="inherit" onClick={() => {
                if (showJobs){
                  setShowJobs(false);
                } else {
                  setShowJobs(true);
                }
              }}>{"Jobs & Occupations"}
              {showJobs ? 
                (
                  <ExpandMoreIcon className={classes.iconPosition} />
                ):
                ( 
                  <ExpandLessIcon className={classes.iconPosition}  />
                )}
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
        <div style={{display: (showJobs) ? "block" : "none"}}>
        <Grid item style={{ marginTop: 10 }}>
          {jobsViewed && (
            <CandidateAnalytics
              jobsViewed={jobsViewed}
              jobsApplied={jobsApplied}
              jobsSaved={jobsSaved}
            />
          )}
        </Grid>
        {showQualifications()}
        </div>

        {appEngagements ? (
          <>
            <Grid item>
              {appEngagements.chartData.length > 0 ? (
                <>
                  <Grid item>
                    <AppBar
                      position="static"
                      color="default"
                      elevation={0}
                      className={classes.sectionTitle}
                    >
                      <Toolbar variant="dense" className={classes.center}>
                        <Typography variant="h6" color="inherit" onClick={() => {
                          if (showEngagement){
                            setShowEngagement(false);
                          } else {
                            setShowEngagement(true);
                          }
                        }}>
                          Engagement
                          {showEngagement ? 
                          (
                            <ExpandMoreIcon className={classes.iconPosition} />
                          ):
                          ( 
                            <ExpandLessIcon className={classes.iconPosition}  />
                          )}
                        </Typography>
                      </Toolbar>
                    </AppBar>
                  </Grid>
                  <div style={{display: (showEngagement) ? "block" : "none"}}>
                  <Grid item >
                    <AppBar position="static" color="default" elevation={0}>
                      <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="baseline"
                        style={{ padding: "1em" }}
                      >
                        {/* <Grid item xs>
                    <p>First Session</p>
                    <p>First Session</p>
                  </Grid> */}
                        <Grid item>
                          <Typography>Last Login Activity</Typography>
                          <Typography>
                            <strong>
                              {" "}
                              {moment(candidate.lastAuthenticatedAt).format(
                                "DD/MM/YYYY hh:mm:ss a"
                              )}
                            </strong>
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography>No. of Sessions</Typography>
                          <Typography>
                            <strong>{appEngagements.numberOfSessions}</strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>Overall Duration</Typography>
                          <Typography>
                            <strong>
                              {appEngagements.totalDurationFormatted}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          {/*
                     <Typography>Av. Usage Duration (Day)</Typography>
                    <Typography>
                      <strong>{appEngagements.averageDurationDay}</strong>
                    </Typography>
                    */}
                        </Grid>
                      </Grid>
                    </AppBar>
                  </Grid>
                  <Grid container justify="center" style={{marginTop:10}}>
                  <Grid item>
                    <Button variant="contained" onClick={goBackMonth}>Previous Month</Button>
                  </Grid>
                  <Grid item style={{marginTop: 6,paddingLeft: 20,paddingRight: 20}}>
                    <Typography> {getMonthYear()} </Typography>
                  </Grid>
                  <Grid item  > 
                    <Button variant="contained" onClick={goForwardMonth}>Next Month</Button>
                  </Grid>
                  </Grid>
                  <Grid item style={{ marginTop: 10 }}>
                    <CandidateActivityBarChart
                      data={dates}
                      valueKey="duration"
                    />
                  </Grid>
                  </div>
                </>
              ) : null}
            </Grid>
          </>
        ) : null}
        {missions ? (
          <>
            <Grid item>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.sectionTitle}
              >
                <Toolbar variant="dense" className={classes.center}>
                  <Typography variant="h6" color="inherit" onClick={() => {
                    if (showChallenges){
                      setShowChallenges(false);
                    } else {
                      setShowChallenges(true)
                    }
                  }}>
                    Challenges
                    {showChallenges ? 
                    (
                      <ExpandMoreIcon className={classes.iconPosition} />
                    ):
                    ( 
                      <ExpandLessIcon className={classes.iconPosition}  />
                    )}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item style={{display: (showChallenges) ? "block" : "none"}}>
              <Table className={classes.challengesTable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    {/*<TableCell>Push</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {type3Interventions.map((row) => {
                    let status = "Not Started";

                    const candidateIntervention = candidate.interventions
                      ? candidate.interventions[row.sort]
                      : null;

                    if (candidateIntervention) {
                      status = "Started";
                    }
                    if (
                      candidateIntervention &&
                      candidateIntervention.status === "Complete"
                    ) {
                      status = "Complete";
                    }

                    return (
                      <TableRow key={row.sort}>
                        <TableCell className={classes.tableCell}>
                          {row.title}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {status}
                        </TableCell>

                      { /* <TableCell className={classes.tableCell}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            endIcon={<ArrowForwardIcon />}
                          >
                            Send
                          </Button>
                        </TableCell>*/
                  }
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.sectionTitle}
              >
                <Toolbar variant="dense" className={classes.center}>
                  <Typography variant="h6" color="inherit" onClick={() => {
                    if (showMissions){
                      setShowMissions(false);
                    } else {
                      setShowMissions(true)
                    }
                  }}>
                    Missions
                    {showMissions ? 
                    (
                      <ExpandMoreIcon className={classes.iconPosition} />
                    ):
                    ( 
                      <ExpandLessIcon className={classes.iconPosition}  />
                    )}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item style={{ marginTop: "2em", display: (showMissions) ? "block" : "none"}}>
              <Table className={classes.missionsTable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Dimension</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {missions.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell className={classes.tableCell}>
                        <MissionHoverView {...row} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.status}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.dimensionText}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.score}%
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.sectionTitle}
              >
                <Toolbar variant="dense" className={classes.center}>
                  <Typography variant="h6" color="inherit" onClick={() => {
                    if (showResources){
                      setShowResources(false);
                    } else {
                      setShowResources(true)
                    }
                  }}>
                    Resources
                    {showResources ? 
                    (
                      <ExpandMoreIcon className={classes.iconPosition} />
                    ):
                    ( 
                      <ExpandLessIcon className={classes.iconPosition}  />
                    )}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item style={{ marginTop: "2em", display: (showResources) ? "block" : "none"}}>
              {resourceViewed.length === 0 &&
                <h4 style={{textAlign:"center"}}> No resources has been viewed </h4>
              }
              {resourceViewed.length > 0 &&
              <Table className={classes.missionsTable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resourceViewed.map((row) => (
                    <TableRow key={row.sort}>
                      <TableCell className={classes.tableCell}>
                        {row.title}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <a href={row.link} target="_blank" rel="noopener noreferrer">View here</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              }
            </Grid>
            <Grid item>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.sectionTitle}
              >
                <Toolbar variant="dense" className={classes.center}>
                  <Typography variant="h6" color="inherit" onClick={() => {
                    if (showCourses){
                      setShowCourses(false);
                    } else {
                      setShowCourses(true)
                    }
                  }}>
                    Courses
                    {showCourses ? 
                    (
                      <ExpandMoreIcon className={classes.iconPosition} />
                    ):
                    ( 
                      <ExpandLessIcon className={classes.iconPosition}  />
                    )}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item style={{ marginTop: "2em", display: (showCourses) ? "block" : "none", paddingBottom:"50px"}}>
              {coursesViewed.length === 0 &&
                <h4 style={{textAlign:"center"}}> No courses has been viewed </h4>
              }
              {coursesViewed.length > 0 &&
              <Table className={classes.missionsTable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Occupation</TableCell>
                    <TableCell>Viewed On</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coursesViewed.map((row) => (
                    <TableRow key={row.sort}>
                      <TableCell className={classes.tableCell}>
                        {row.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.occupation}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.provider}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <a href={row.url} target="_blank" rel="noopener noreferrer">View here</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              }
            </Grid>
            



          </>
        ) : null}
      </>
    );
  }
);
