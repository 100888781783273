import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import React, { useState } from "react";
import CustomCircle from "./CustomCircle";

export const OccupationalMatch = ({ score_bp, similar_skills }) => {
  const [showSkillsDialog, toggleSkillsDialog] = useState(false);
  return (
    <>
      <Grid onClick={similar_skills ? () => toggleSkillsDialog(true) : null}>
        <Grid container item xs justify="center">
          <CustomCircle
            variant="static"
            value={score_bp}
            size={50}
            innerText={score_bp ? score_bp.toString() : "0"}
          />
        </Grid>
      </Grid>
      {similar_skills ? (
        <Dialog
          onClose={() => toggleSkillsDialog(false)}
          aria-labelledby="simple-dialog-title"
          open={showSkillsDialog}
        >
          <DialogTitle id="simple-dialog-title" disableTypography>
            <Grid container justify="center">
              <Grid item xs>
                Skillz Backpack Match
              </Grid>
              <Grid item xs style={{ textAlign: "right" }}>
                <CustomCircle
                  variant="static"
                  value={score_bp}
                  size={35}
                  innerText={score_bp ? score_bp.toString() : "0"}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <List>
                {similar_skills.map(skill => (
                  <ListItem key={skill}>
                    <ListItemText primary={skill} />
                  </ListItem>
                ))}
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleSkillsDialog(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
