import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cache } from "aws-amplify";
import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../actions/auth_actions";
import * as ROUTES from "../../constants/routes";
import config from "../../config";
// import Skillzminer from "../Skillzminer";

import Navigation from "./Navigation";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Avatar from "@material-ui/core/Avatar";


const Default = lazy(() => import("./Default"));
const MainDashboard = lazy(() => import("./MainDashboard"));

const Dashboards = lazy(() => import("../Dashboards"));
const Insights = lazy(() => import("../Insights"));
const Caseloads = lazy(() => import("../Caseload"));
const Admin = lazy(() => import("../Admin"));
const Candidates = lazy(() => import("../Candidates"));
const Profile = lazy(() => import("../Main/Profile"));
const Faq = lazy(() => import("../Main/Faq"));
const BlankPage = lazy(() => import("../Main/Blank"));
const Vacancies = lazy(() => import("../Vacancies"));

const SuperAdmin = lazy(() => import("../SuperAdmin"));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  loader: {
    backgroundColor: "#efefef",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 9999,
  },
  loadernobg: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 9999,
  },
  progressCont: {
    backgroundColor: "#efefef",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 9999,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1200 + theme.spacing(3 * 2))]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  logok: {
    width: 150,
    height: 150,
    marginLeft: 75,
  },
  logoholder: {
    width: 300,
    height: 200,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -150,
    marginTop: -200,
  },
  ltext: {
    marginTop: 15,
    textAlign: "center",
  },
  progress: {
    marginLeft: 137,
    marginTop: 15,
  },
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  state = {
    topLoading: false,
    loading: false,
    bg: false,
  };

  componentDidUpdate(prevProps) {
    //TODO: refactor this into a separate component
    if (this.props.globalloading !== prevProps.globalloading) {
      if (this.props.globalloading) {
        this.setState({
          topLoading: true,
          bg: true,
          loading: true,
        });
      } else {
        setTimeout(() => {
          this.setState({
            topLoading: false,
            bg: false,
          });
        }, 1000);

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 1500);
      }
    }
  }

  componentDidMount() {
    if (this.props.role === "keyworker" && !this.props.missions) {
      this.setState({
        topLoading: true,
        bg: true,
        loading: true,
      });
    }
  }

  logout() {
    this.props.logout(null, this.props.history);
  }
  render = () => {
    this.props.history.listen((location) => {
      // location is an object like window.location
      Cache.setItem("lastpath", location.pathname);
    });

    let defaultRoute;
    if (this.props.role === "super-admin") {
      defaultRoute = SuperAdmin;
    }
    else if (this.props.role === "hr-manager" || this.props.role === "line-manager" || this.props.role === "employer-engagement") {
      defaultRoute = Vacancies;
    } else {
      defaultRoute = MainDashboard;
    }
    
    if (this.props.role === "flexible" && this.props.portaldetails && this.props.portaldetails.user && this.props.portaldetails.user.viewPermissions) {
      if (this.props.portaldetails.user.viewPermissions.dashboardCheckboxGlobal || this.props.portaldetails.user.viewPermissions.dashboardCheckbox){
        defaultRoute = MainDashboard;
      } else if (this.props.portaldetails.user.viewPermissions.vacancyCheckbox){
        defaultRoute = Vacancies;
      } else if (this.props.portaldetails.user.viewPermissions.candidateCheckbox){
        defaultRoute = Candidates;
      } else if (this.props.portaldetails.user.viewPermissions.caseloadCheckbox){
        defaultRoute = Caseloads;
      } else {
        defaultRoute = BlankPage;
      }
    } else if (this.props.role === "flexible") {
      defaultRoute = BlankPage;
    }

    const { classes, orgdetails } = this.props;
    const componentKey = this.props.location.key
    return (
      <>
        <div className={classes.root}>
          {this.state.loading && (
            <div
              className={this.state.bg ? classes.loader : classes.loadernobg}
            >
              <Fade in={this.state.topLoading}>
                <div className={classes.progressCont}>
                  <LinearProgress />

                  <Zoom in={true}>
                    <div className={classes.logoholder}>
                      <Avatar
                        variant="square"
                        src={
                          config.STORAGE_CLOUDFRONT_URL +
                          (orgdetails.orgLogoURL
                            ? orgdetails.orgLogoURL + "?"
                            : "/logos/default_portal_org_logo.png?") +
                          Date.now()
                        }
                        className={classes.logok}
                      />
                      {
                        <CircularProgress
                          className={classes.progress}
                          size={26}
                        />
                      }
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        justify="center"
                        className={classes.ltext}
                      >
                        Loading... {this.props.contractName}
                      </Typography>
                    </div>
                  </Zoom>
                </div>
              </Fade>
            </div>
          )}
          <Grid>
            <Grid item xs>
              <Navigation {...this.props} />
            </Grid>
          </Grid>

          {this.props.contract && (
            <Suspense fallback={<LinearProgress />}>
              <Switch>
                {/* <Route path={ROUTES.SKILLZMINER} component={Skillzminer} /> */}
                <Route path={ROUTES.INSIGHTS} component={Insights} key={componentKey}/>
                <Route path={ROUTES.VACANCIES} component={Vacancies} key={componentKey}/>
                <Route path={ROUTES.DASHBOARDS} component={Dashboards} key={componentKey}/>
                <Route path={ROUTES.ADMIN} component={Admin} key={componentKey}/>
                <Route path={ROUTES.CASELOADS} component={Caseloads} key={componentKey}/>

                <Route path={ROUTES.SUPER_ADMIN} component={SuperAdmin} key={componentKey}/>

                <Route exact path={ROUTES.PROFILE} component={Profile} key={componentKey}/>
                <Route exact path={ROUTES.FAQ} component={Faq} key={componentKey}/>
                <Route exact path={ROUTES.MAIN} component={defaultRoute} key={componentKey}/>
                <Route exact path={ROUTES.CANDIDATES} component={Candidates} key={componentKey}/>
              </Switch>
            </Suspense>
          )}
        </div>
      </>
    );
  };
}

function mapStateToProps(state) {
  const {
    candidates,
    portaldetails: { selectedContract = {}, missions, orgdetails = {} },
  } = state;

  var contr = false;
  var globalloading = false;
  var contractName = "";

  if (state.auth.role === "keyworker") {
    if (selectedContract && selectedContract.contractID) {
      contr = selectedContract.contractID;
    }
    if (candidates && !candidates.candidates && !missions) {
      globalloading = true;
    } else {
      globalloading = false;
    }
    if (selectedContract && selectedContract.name){
      contractName = selectedContract.name;
    }
  } else {
    contr = true;
  }

  return {
    contract: contr,
    authenticated: state.auth.authenticated,
    role: state.auth.role,
    globalloading: globalloading,
    contractName: contractName,
    missions: missions,
    orgdetails,
    portaldetails: state.portaldetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        logout,
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Main)));
