import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";

export const ConstructExpansionPanel = ({
  classes,
  children,
  constructTitle,
  constructDescription,
  constructSummary,
  titleColor,
  defaultExpanded
}) => {
  const [isExpanded, toggleExpansion] = useState(false);

  useEffect(() => {
    toggleExpansion(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <ExpansionPanel
      onChange={(_, isExpanded) => toggleExpansion(isExpanded)}
      elevation={1}
      expanded={defaultExpanded || isExpanded}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography
          className={classes.heading}
          style={{ color: titleColor ? titleColor : "" }}
        >
          {constructTitle}
        </Typography>
        <Typography className={classes.subheading}>
          {constructDescription}
        </Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails style={{display:"block"}}>
        <div>
          {typeof constructSummary === "function" ? (
            <Typography className={classes.subheading1}>
              {constructSummary()}
            </Typography>
          ) : (
            <Typography className={classes.subheading1}>
              {constructSummary}
            </Typography>
          )}

          <Divider className={classes.divid} />
          {children}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
