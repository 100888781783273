import { INDEED_DESCRIPTION } from "../actions/types";
import { Logger } from "aws-amplify";

/* eslint-disable */
const logger = new Logger("REWARD.REDUCER", "DEBUG");
/* eslint-enable */

export const jobReducer = (state = {}, action) => {
  switch (action.type) {
    
    case INDEED_DESCRIPTION: {
        let indeed_description = state.indeed_description || {};
        let desc = action.description;
        let id = action.id;
  
        indeed_description[id] = desc;
  
        return {
          ...state,
          indeed_description: indeed_description
        }
    }

    default:
      return state;
  }
};
