import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { API } from "aws-amplify";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCandidateDetails } from "../../../actions/candidates_actions";
import config from "../../../config";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    marginLeft: 20,
    marginTop: 10,
    fontSize: 28,
  },
  subtitle: {
    //fontSize: 28
    marginLeft: 20,
    marginTop: 8,
  },
  subtitle2: {
    fontSize: 16,
    marginLeft: 0,
    marginTop: 30,
    marginBottom: 10,
  },
  sectionTitle: {
    marginTop: 40,
    marginBottom: 20,
  },
  center: {
    textAlign: "center",
    display: "block",
    paddingTop: 14,
    border: "1px solid #ddd",
  },
  graphBg: {
    backgroundColor: "#fbfbfb",
    border: "1px solid #e8e8e8",
  },
  subheading: {
    marginLeft: 20,
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#444",
  },
  heading: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "10%",
    flexShrink: 0,
  },
  heading1: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: 20,
  },
  subheading1: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#666",
    marginLeft: 10,
    display: "inline-block",
  },

  resultText: {
    fontSize: theme.typography.pxToRem(14),
  },
  resultText1: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  divid: {
    marginBottom: 20,
    marginTop: 20,
  },
  reassess: {
    marginLeft: 15,
    marginTop: 15,
  },
  itemre: {
    marginTop: 10,
    marginBottom: 5,
  },
  header: {
    padding: theme.spacing(3),
  },
  avatarlarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1),
  },
  light: {
    color: "#666",
  },
  smallicons: {
    color: "#666",
  },
  itemtitle: {
    fontSize: 12,
  },
  prog: {
    marginTop: -12,
  },
  itemgrid: {
    minHeight: 22,
    marginBottom: theme.spacing(1),
  },
  name: {
    maxWidth: 400,
    fontSize: "2rem",
    fontWeight: 500,
  },
});

const AlertDialog = ({ onClose, title, content }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export const OtherAssessmentsView = (props) => {
  const {
    classes,
    portaldetails,
    candidates: {
      selectedCandidateData: {
        activeIntervention,
        interventions: candidateInterventions = {},
        isInterventionOngoing,
        reAssessmentDue,
        userID,
        assessmentCompleted,
        fullName,
      },
    },
    fetchCandidateDetails,
    isOnPrintMode,
  } = props;
  const { interventions: organizationInterventions } = portaldetails;
  const [isPending, setPendingStatus] = useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [error, setError] = useState(false);

  const pushIntervention = async (candidateId, interventionId) => {
    setPendingStatus(true);
    try {
      const response = await API.post(
        config.API_NAME,
        `/keyworker/candidate/${candidateId}/intervention`,
        {
          body: {
            interventionID: interventionId,
          },
        }
      );
      if (response.message) {
        setError(response.message);
        setPendingStatus(false);
        return;
      }
      setIsSucceeded(true);
      setPendingStatus(false);
    } catch (error) {
      console.log(error);
      setPendingStatus(false);
    }
  };

  const totalInterventionIds = new Set(
    (
      organizationInterventions.filter((intervention) => intervention.active) ||
      []
    ).map((i) => i.sort)
  );
  if (activeIntervention) {
    totalInterventionIds.add(activeIntervention.sort);
  }
  if (Object.keys(candidateInterventions).length > 0) {
    totalInterventionIds.add(...Object.keys(candidateInterventions));
  }

  const interventions = Array.from(totalInterventionIds).map(
    (interventionId) => {
      let status = "Not Started";
      let distance_travelled = "";
      if (activeIntervention && interventionId === activeIntervention.sort) {
        status = "Awaiting Start";
      }
      if (candidateInterventions[interventionId]) {
        distance_travelled = "0%";
        if (Number(candidateInterventions[interventionId].initialScore) >= 0) {
          status = "IA Completed";
        }
        if (Number(candidateInterventions[interventionId].finalScore) >= 0) {
          status = "Reassessment Completed";
        }
        if (
          Number(candidateInterventions[interventionId].finalScore) >
          Number(candidateInterventions[interventionId].initialScore)
        ) {
          distance_travelled =
            ((candidateInterventions[interventionId].finalScore -
              candidateInterventions[interventionId].initialScore) /
              10) *
              100 +
            "%";
        }
        if ((candidateInterventions[interventionId].type || 0) === 3) {
          status = "Not Started";
          distance_travelled = "";
          const org = organizationInterventions.find(
            ({ sort }) => sort === interventionId
          );
          if (org) {
            status = "Started";
          }
          if (
            (
              candidateInterventions[interventionId].status || ""
            ).toLowerCase() === "complete"
          ) {
            status = "Completed";
          }
        }
      }

      return {
        ...organizationInterventions.find(
          ({ sort }) => sort === interventionId
        ),
        ...candidateInterventions[interventionId],
        distance_travelled,
        status,
      };
    }
  );

  if (!assessmentCompleted) {
    return (
      <Grid>
        <Grid container item xs justify="center">
          <Typography variant="subtitle2">
            This candidate has not completed the assessment yet.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="column">
      <Grid item xs>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.sectionTitle}
        >
          <Toolbar variant="dense" className={classes.center}>
            <Typography variant="h6" color="inherit">
              Interventions
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {!isOnPrintMode && <TableCell>Push</TableCell>}
              <TableCell>Status</TableCell>
              <TableCell>Initial Score</TableCell>
              <TableCell>Final Score</TableCell>
              <TableCell>Distance Travelled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interventions
              .filter((i) => i.type === 2)
              .map(
                ({
                  title,
                  sort,
                  status,
                  initialScore,
                  finalScore,
                  distance_travelled,
                  type,
                }) => {
                  const showSendCTA = !isOnPrintMode && type !== 3;
                  return (
                    <TableRow key={sort}>
                      <TableCell>{title}</TableCell>

                      <TableCell>
                        {showSendCTA ? (
                          <Button
                            disabled={
                              isPending ||
                              reAssessmentDue ||
                              isInterventionOngoing
                            }
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={() => pushIntervention(userID, sort)}
                            endIcon={<ArrowForwardIcon />}
                          >
                            Send
                          </Button>
                        ) : null}
                      </TableCell>

                      <TableCell>{status}</TableCell>
                      <TableCell>{initialScore}</TableCell>
                      <TableCell>{finalScore}</TableCell>
                      <TableCell>{distance_travelled}</TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </Grid>
      {!!error ? (
        <AlertDialog
          title={`Couldn't send push`}
          content={error}
          onClose={() => setError(null)}
        />
      ) : null}
      {!!isSucceeded ? (
        <AlertDialog
          title={`Push sent succesfully`}
          content={`Intervention has been pushed to ${fullName} successfully`}
          onClose={async () => {
            setPendingStatus(true);
            try {
              await fetchCandidateDetails(
                props.candidates.selectedCandidateData
              );
            } catch (error) {
              //console.log("asd");
            } finally {
              setIsSucceeded(false);
              setPendingStatus(false);
            }
          }}
        />
      ) : null}
    </Grid>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchCandidateDetails }, dispatch),
    dispatch,
  };
}

export const OtherAssessments = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OtherAssessmentsView));
