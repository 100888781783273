import { Grid, Typography } from "@material-ui/core";
import { API, Cache } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactSelect from "react-select";
import { SnackbarProvider } from 'notistack';
import { bindActionCreators } from "redux";
import {
  fetchCandidateDetails,
  fetchCandidates,
  inviteToVacancy
} from "../../../actions/candidates_actions";
import config from "../../../config";
import JobListingView  from "./JobListingView";
import { JobRecommendationsTable } from "./JobRecommendationsTable";
import {OccupationAutoComplete} from "../../Common/OccupationAutoComplete"
import _ from "lodash";

const formatToSterling = (amount) =>
  new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency: config.currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount);

const createSalaryOptions = (salaryInterests) => {
  const availableSalaryOptions = [0, 15000, 20000, 25000, 30000, 50000]
  return availableSalaryOptions
    .map(formatToSterling)
    .map((salary) => ({ label: (salary === "£0" || salary === "€0" ? "No preference" : salary), value: salary }))

};

const contractTypeOptions = ["Full Time", "Part Time", "Either"].map((contract_type) => ({
  label: contract_type,
  value: contract_type,
}));

class JobResults extends Component {
  constructor(props) {
    super(props);
    const { candidate: cachedCandidate, job_match_type: cachedJobMatchType } =
      Cache.getItem("currentSkillzminer") || {};
    this.state = {
      candidate: props.candidate || cachedCandidate || null,
      jobRecommendations: null,
      isPending: false,
      selectedJob: null,
      job_match_type: cachedJobMatchType || props.jobMatchType || props.job_match_type || "previous",
      salary_range: config.distanceName === "miles" ? "£0" : "€0",
      contract_type: "Either",
      travel_distance: config.distanceName === "miles" ? "50" : "75",
      scroll: false
    };
  }

  onJobMatchTypeChange = (value) => {
    const lowerValue = value.toLowerCase()
    console.log(lowerValue)
    console.log(this.state.job_match_type)
    if (lowerValue !== this.state.job_match_type) {
      this.setState({ job_match_type: lowerValue }, async () => {
        await this.fetchJobRecommendations(lowerValue);
      });
    }
  };

  selectOnChangeHandler = (field) => ({ value }) => {
    if (value !== this.state[field]) {
      this.setState(
        (previousState) => ({
          ...previousState,
          [field]: value,
        }),
        async () => {
          await this.fetchJobRecommendations(value);
        }
      );
    }
  };

  componentDidMount = async () => {
    if (this.state.job_match_type) {
      await this.fetchJobRecommendations();
    }
  };

  componentDidUpdate= async () => {
  
    if(this.props.job_match_type && this.state.job_match_type !== this.props.job_match_type){
      this.setState({ job_match_type: this.props.job_match_type }, () => this.fetchJobRecommendations()
    );
    this.props.set_job_match_type(null)
    };
  };


  fetchJobRecommendations = async () => {
    const { job_match_type, salary_range, contract_type, travel_distance } = this.state;
    // return;
    // TODO Move this to actions? (endpoint is different)
    this.setState({ isPending: true });
    
    const {
      candidates: { 
        selectedCandidateData: selectedCandidate = {},
        desired_occupations,
        interest_occupations,
        past_occupations,
        skills_backpack_occupations,
        styles_occupations
      },
    } = this.props;
    console.log(selectedCandidate)

    

    const returnMappedResults = (occ1, occ2, candidate) => {
      let mappedResults = _.map(occ1, (index) => {
        let f = _.filter(occ2, (occ) => {
          if (occ.occupation === index.occupation){
            return occ;
          }
        });

        if (f && f.length > 0){
          f = f[0];
        }

        if (f && f.ONET_styles){ 
          _.each(f.ONET_styles, (style, key) => {
            switch(key){
              case "Commitment":
                f.COMM = style;
                break;
              case "Adaptability":
                f.ADY = style;
                break;
              case "Confidence":
                f.CONF = style;
                break;
              case "Control":
                f.CONT = style;
                break;
              case "Accuracy":
                f.PSA = style;
                break;
              case "Responsiveness to feedback":
                f.RTF = style;
                break;
              case "Complexity":
                f.SPC = style;
                break;
              default:
                break;
            }
            });
          }
        if (f && f.ONET_interests){
          _.each(f.ONET_interests, (interest, key) => {
            f[key.toLowerCase()] = interest;
          });
        }

        if (candidate.qualifications && candidate.qualifications.category){
          if (f.ONET_required_level_education > candidate.qualifications.category){
            f.score_quals = false;
          } else {
            f.score_quals = true;
          }
        } else {
          f.score_quals = false;
        }
        let results = {
          ...f,
          ...index
        };
        return results;
      });
      return _.orderBy(mappedResults, [({ provider }) => provider === 'skillzMiner'], ["desc"]);
    }

    const {
      provider,
      latestAssessmentResult,
      occupations,
      desiredOccupations,
      desiredOccupationsExtended,
      qualifications,
      desiredExp,
      occupationsExtended,
      skills_names,
      occupationsOriginal,
      desiredOccupationsOriginal,
      userID
    } = _.cloneDeep(selectedCandidate);

    if (!latestAssessmentResult) {
      this.setState({ jobRecommendations: [], isPending: false });
      return;
    }
    if (salary_range) {
      latestAssessmentResult.interests.salary = [].concat(salary_range);
    }
    if (contract_type) {
      latestAssessmentResult.interests.contract = [].concat(contract_type);
    }
    if (travel_distance) {
      latestAssessmentResult.interests.locationRadius = [].concat(travel_distance);
    }


    try {
      switch (job_match_type) {
        case "previous": {
          const payload = {
            provider,
            latestAssessmentResult,
            occupationsExtended,
            occupations,
            desiredExp,
            "desired_occupations": [],
            "styles_occupations" : [],
            "interest_occupations" : [],
            "skills_backpack_occupations" : [],
            qualifications,
            skills_names,
            past_occupations
          };
          if (desiredExp) {
            Object.assign(payload, { desiredExp, desiredOccupations, desiredOccupationsExtended });
          }

          const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
            body: {
              data: payload,
            },
          });

          jobs.past_occupations = returnMappedResults(jobs.past_occupations, past_occupations, selectedCandidate);
          this.setState({ jobRecommendations: jobs.past_occupations, isPending: false });
          break;
        }
        case "preferred": {
          const payload = {
            provider,
            latestAssessmentResult,
            occupationsExtended,
            occupations,
            desiredExp,
            desired_occupations,
            "styles_occupations" : [],
            "interest_occupations" : [],
            "skills_backpack_occupations" : [],
            qualifications,
            skills_names,
            "past_occupations": []
          };
          if (desiredExp) {
            Object.assign(payload, { desiredExp, desiredOccupations, desiredOccupationsExtended });
          }

          const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
            body: {
              data: payload,
            },
          });

          jobs.desired_occupations = returnMappedResults(jobs.desired_occupations, desired_occupations,selectedCandidate);
          this.setState({ "jobRecommendations": jobs.desired_occupations, isPending: false });
          break;
        }
        case "skills": {
          const payload = {
            provider,
            latestAssessmentResult,
            occupationsExtended,
            occupations,
            desiredExp,
            "desired_occupations": [],
            "styles_occupations" : [],
            "interest_occupations" : [],
            skills_backpack_occupations,
            qualifications,
            skills_names,
            "past_occupations": []
          };
          if (desiredExp) {
            Object.assign(payload, { desiredExp, desiredOccupations, desiredOccupationsExtended });
          }

          const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
            body: {
              data: payload,
            },
          });

          jobs.skills_backpack_occupations = returnMappedResults(jobs.skills_backpack_occupations, skills_backpack_occupations, selectedCandidate);
          this.setState({ "jobRecommendations": jobs.skills_backpack_occupations, isPending: false });
          break;
        }
        case "styles": {
          const payload = {
            provider,
            latestAssessmentResult,
            occupationsExtended,
            occupations,
            desiredExp,
            "desired_occupations": [],
            styles_occupations,
            "interest_occupations" : [],
            "skills_backpack_occupations": [],
            qualifications,
            skills_names,
            "past_occupations": []
          };
          if (desiredExp) {
            Object.assign(payload, { desiredExp, desiredOccupations, desiredOccupationsExtended });
          }

          const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
            body: {
              data: payload,
            },
          });

          jobs.styles_occupations = returnMappedResults(jobs.styles_occupations, styles_occupations, selectedCandidate);
          this.setState({ "jobRecommendations": jobs.styles_occupations, isPending: false });

          break;
        }
        case "interests": {
          const payload = {
            provider,
            latestAssessmentResult,
            occupationsExtended,
            occupations,
            "desired_occupations": [],
            "styles_occupations": [],
            interest_occupations,
            "skills_backpack_occupations": [],
            qualifications,
            skills_names,
            "past_occupations": []
          };


          const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
            body: {
              data: payload,
            },
          });

          jobs.interest_occupations = returnMappedResults(jobs.interest_occupations, interest_occupations, selectedCandidate);
          this.setState({ "jobRecommendations": jobs.interest_occupations, isPending: false });
          break;
        }
        case "jobsnearme": {
          this.setState({ isPending: false });
          break;
          const payload = {
            provider,
            latestAssessmentResult,
            occupations,
            occupationsExtended,
            desiredExp,
            qualifications,
            desiredOccupations,
            calculateQualifications: false,
            skills_names
          };
      
          if (payload.latestAssessmentResult.interests){
            payload.latestAssessmentResult.interests.salary = ["0"];
          }
      
          if (payload.latestAssessmentResult.interests){
            payload.latestAssessmentResult.interests.contract = ["Either"];
          }


          const { jobs } = await API.post(config.API_NAME, "/jobs/query/jobs_near_me", {
            body: {
              data: payload,
            },
          });
          this.setState({ "jobRecommendations": _.orderBy(jobs, [({ provider }) => provider === 'skillzMiner'], ["desc"]), isPending: false });
          break;
        }
        default: {
          let matchedOcc = false
          for (const value of [desired_occupations, interest_occupations, past_occupations, skills_backpack_occupations, styles_occupations]){
            for (const occ of value){
              if (occ.occupation === job_match_type){
                matchedOcc = occ
                break
              } 
            } 
            if (matchedOcc){
              break
            }
          }

          if (!matchedOcc){
            let occPayload = {
              latestAssessmentResult,
              desiredOccupations,
              occupationsExtended,
              occupations,
              desiredExp,
              qualifications,
              calculateQualifications: false,
              skills_names,
              userID,
              occupationsOriginal,
              desiredOccupationsOriginal,
            };
            for (const idx in occPayload.desiredExp) {
              if (occPayload.desiredExp[idx] === true){
                occPayload.desiredExp[idx] = false
                occPayload.occupations.push(occPayload.desiredOccupations[idx])
                occPayload.occupationsExtended.push(desiredOccupationsExtended[idx])
                occPayload.occupationsOriginal.push(desiredOccupationsOriginal[idx])
              }
            }
            occPayload.desiredOccupations = [job_match_type]
            occPayload.desiredOccupationsOriginal = [job_match_type]
            const new_jobs_cats = await API.post(
              config.API_NAME,
              "/jobs/query/all_occupations",
              {
                body: {
                  data: occPayload,
                },
              }
            );
            if(new_jobs_cats && new_jobs_cats.jobs){
              for (const occ of new_jobs_cats.jobs.desired_occupations){
                if (occ.occupation === job_match_type){
                  matchedOcc = occ
                  break
                }
              }
            }
          }

          if (matchedOcc){
            const payload = {
              provider,
              latestAssessmentResult,
              occupationsExtended,
              occupations,
              "desiredExp": [false],
             "desired_occupations": [matchedOcc],
              "styles_occupations" : [],
              "interest_occupations" : [],
              "skills_backpack_occupations" : [],
              qualifications,
              skills_names,
              "past_occupations": []
            };
            const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
              body: {
                data: payload,
              },
            });            
            jobs.desired_occupations = returnMappedResults(jobs.desired_occupations, [matchedOcc], selectedCandidate);
            this.setState({ "jobRecommendations": _.orderBy(jobs.desired_occupations, [({ provider }) => provider === 'skillzMiner'], ["desc"]), isPending: false });
            break;
          }          
        }
      }
    } catch (error) {
      console.log(error)
      this.setState({ isPending: false, jobRecommendations: [] });
    }
    return;
  };

  onCandidateChange = async ({ value: candidate }) => {
    this.setState({
      job_match_type: "inga",
      candidate,
    });
    try {
      const {
        candidates: { candidates = [] },
      } = this.props;
      const selectedCandidate = candidates.find(
        ({ userID }) => userID === candidate
      );
      if (selectedCandidate) {
        await this.props.fetchCandidateDetails(selectedCandidate);
      }
    } catch (error) {
      console.log("something went wrong!", error);
    }
  };

  clearCurrentSelections = () => {
    this.setState({
      jobRecommendations: [],
      candidate: null,
    });
  };

  render() {
    const {
      candidates: { selectedCandidateData = {} },
    } = this.props;

    const {
      job_match_type,
      isPending,
      candidate,
      salary_range,
      contract_type,
      travel_distance,
    } = this.state;

    let ref = React.createRef(null);


    // setTimeout(() => {
      // if (ref && ref.current && this.state.scroll) {
        // ref.current.scrollIntoView();
        // this.setState({scroll: false});
      // }   
    // }, 10);


    const candidateHasLatestAssessment =
      selectedCandidateData && !!selectedCandidateData.latestAssessmentResult;
    if (!candidateHasLatestAssessment) {
      return (
        <Grid>
          <Grid container item xs justify="center">
            <Typography variant="subtitle2">
              This candidate has not completed the assessment yet.
            </Typography>
          </Grid>
        </Grid>
      );
    }
    const {
      latestAssessmentResult: {
        interests: { salary = [], contract = [], locationRadius = [] } = {},
      } = {},
    } = selectedCandidateData;

    const salaryRangeOptions = createSalaryOptions(
      salary.map((salary) => Number(salary.replace(/[^0-9.]+/g, "")))
    );

    const locationRadiusOptions = Array.from(
      new Set(config.distanceName === "miles" ? [10, 20, 30, 50, 500] : [15, 20, 30, 50, 75, 500]))
      .map((radius) => ({
        value: radius.toString(),
        label: (radius === 500 ? "No limit" : `up to ${radius} ${config.distanceName}`),
      }))


    const makeToolBar = () => {
      return <div style={{marginTop:15}}>
        <Grid item xs>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={5}
            alignContent="center"
            style={{ paddingBottom: "1em" }}
          >
            <Grid item xs={4} style={{color:"black"}}>
              <OccupationAutoComplete
                size={"large"}
                onSelect={this.onJobMatchTypeChange}
                placeholder={"Previous"}
                useJobMatchOption={true}
                disabled={
                  isPending ||
                  !candidateHasLatestAssessment ||
                  !candidate
                }
              />
              {/* <WindowedSelect
                styles={{ container: (base) => ({ ...base, zIndex: 999 }) }} // material-table header has higher z-index
                value={
                  job_match_type
                  ? jobMatchTypeOptions[1].options.find(
                      ({ value }) => value === job_match_type
                    )
                  : jobMatchTypeOptions[0].options.find(
                      ({ value }) => value === job_match_type
                    )
                }
                onChange={this.onJobMatchTypeChange}
                placeholder="Previous"
                isSearchable
                options={jobMatchTypeOptions}
                
              /> */}
            </Grid>
            <Grid item xs={2}>
              <ReactSelect
                styles={{ container: (base) => ({ ...base, zIndex: 999 }) }}
                value={
                  salary_range
                    ? salaryRangeOptions.find(
                        ({ value }) => value === salary_range
                      )
                    : salaryRangeOptions.find(
                        ({ value }) => value === salary[0]
                      ) || salaryRangeOptions[0]
                }
                onChange={this.selectOnChangeHandler("salary_range")}
                placeholder="Salary Range"
                isSearchable
                options={salaryRangeOptions}
                isDisabled={
                  isPending ||
                  !candidateHasLatestAssessment ||
                  !candidate ||
                  job_match_type === "jobsnearme"
                }
              />
            </Grid>
            <Grid item xs={2}>
              <ReactSelect
                styles={{ container: (base) => ({ ...base, zIndex: 999 }) }}
                value={
                  contract_type
                    ? contractTypeOptions.find(
                        ({ value }) => value === contract_type
                      )
                    : contractTypeOptions.find(
                        ({ value }) => value === contract[0]
                      ) || contractTypeOptions[0]
                }
                onChange={this.selectOnChangeHandler("contract_type")}
                placeholder="Contract Type"
                isSearchable
                options={contractTypeOptions}
                isDisabled={
                  isPending ||
                  !candidateHasLatestAssessment ||
                  !candidate ||
                  job_match_type === "jobsnearme"
                }
              />
            </Grid>
            <Grid item xs={2}>
              <ReactSelect
                styles={{ container: (base) => ({ ...base, zIndex: 999 }) }}
                value={
                  travel_distance
                    ? locationRadiusOptions.find(
                        ({ value }) => value === travel_distance
                      )
                    : locationRadiusOptions.find(
                        ({ value }) => value === locationRadius[0]
                      ) || locationRadiusOptions[0]
                }
                onChange={this.selectOnChangeHandler("travel_distance")}
                placeholder="Maximum Travel Distance"
                isSearchable
                options={locationRadiusOptions}
                isDisabled={
                  isPending ||
                  !candidateHasLatestAssessment ||
                  !candidate ||
                  job_match_type === "jobsnearme"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        </div>
    }

    return (
      <Grid container direction="column">
        <Grid item xs>
        <div ref={ref}>
          {this.state.selectedJob && (
            <SnackbarProvider>
              <JobListingView
                onDismiss={() => this.setState({ selectedJob: null })}
                job={this.state.selectedJob}
                candidate={selectedCandidateData}
                onInviteToVacancy={(userID, vacancy) => {
                  this.props.inviteToVacancy(userID, vacancy)
                }}
              />
            </SnackbarProvider>
          )}
          </div>
            {makeToolBar()}
            <JobRecommendationsTable
              jobMatchType={job_match_type}
              jobRecommendations={(this.state.jobRecommendations || []).filter(
                (job) => {
                  if (this.filterJobs) {
                    let title = this.filterJobs;
                    this.filterJobs = null;
                    return (
                      job.low_occupation.toLowerCase() === title.toLowerCase()
                    );
                  }

                  return true;
                }
              )}
              isLoading={this.state.isPending}
              onRowClick={(_, data_) => {
                this.setState({ selectedJob: data_, scroll: true })
              }}
              optionalMessageWhenEmpty={
                selectedCandidateData &&
                !selectedCandidateData.latestAssessmentResult
                  ? "This candidate has not completed the assessment yet."
                  : undefined
              }
            />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchCandidates, fetchCandidateDetails, inviteToVacancy }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobResults));
